import {
  default as React,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { RouteComponentProps } from 'react-router'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import qs from 'qs'
import useTitle from 'react-use/lib/useTitle'
import { find, includes } from 'lodash/fp'
import {
  compareProductSelector,
  isPlanSegmentSelector,
  productFillinSelector,
  productForecastsSelector,
  productMonthsSelector,
  productProductSelector,
  productReplenishSelector,
  productRequestedBranchSelector,
  productShipmentsCompareSelector,
  productShipmentsSelector,
  productYearSelector,
  productParamsSelector,
} from './productSelectors'
import { IProduct } from '../types/products'
import PlanProduct from '../plan/PlanProduct'
import { IProductMonthGrouped, planYearSelector } from '../plan/planSelectors'
import useThunkDispatch from '../common/useThunkDispatch'
import {
  clearProduct,
  requestProduct,
  requestProductCompareYearPlan,
  requestProductYearPlan,
  setCompare,
  setProductYear,
} from './productActions'
import Splash from '../components/Splash'
import Toggle from '../components/Toggle'
import { accountsDictSelector } from '../accounts/accountsSelectors'
import name from '../common/name'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
  isEditableOrder,
} from '../types/orders'
import { SHORT_MONTHS } from '../common/months'
import ArrowLeftIcon from '../icons/arrow_left.svg'
import ArrowRightIcon from '../icons/arrow_right.svg'
import { IPlannedBranch, IBranch, IAccount, IUser } from '../types/user'
import PlanTableCell from '../plan/planTable/PlanTableCell'
import ProductChart from './productChart/ProductChart'
import {
  requestCreateOrderItem,
  requestUpdateOrderItem,
} from './productActions'
import { PlanCellActionsContext } from '../plan/Plan'
import CrossIcon from '../icons/cross.svg'
import withAccountAndBranch from '../common/withAccountAndBranch'

export interface IProductRouteParams {
  branchId: string
  accountId: string
  productId: string
}

interface IProductParams {
  year: number
  compare: string
}

export interface ISelectedProps {
  planYear: number
  year: number
  params: IProductParams
  product?: IProduct
  requestedBranch: IPlannedBranch
  months: IPlannedOrder[]
  forecasts: IProductMonthGrouped<IBranchForecast>
  shipments: IProductMonthGrouped<IBranchShipment[]>
  compareShipments: IProductMonthGrouped<IBranchShipment[]>
  replenish: IProductMonthGrouped<IPlannedOrderItem & { order: IPlannedOrder }>
  fillins: IProductMonthGrouped<Array<IOrderItem & { order: IFillinOrder }>>
  compare: boolean
  branch: IBranch
  account?: IAccount
  user: IUser
}

const Product: FunctionComponent<
  RouteComponentProps<IProductRouteParams> & ISelectedProps
> = ({
  match,
  history,
  product,
  requestedBranch,
  planYear,
  year,
  params,
  months,
  forecasts,
  shipments,
  compareShipments,
  replenish,
  fillins,
  compare,
  branch,
  account: currentAccount,
  user,
}) => {
  const account = currentAccount || user.account

  if (!branch || !account) return <Splash isLoading />

  const accountId = account.id
  const branchId = branch.id
  const productId = Number(match.params.productId)

  const dispatch = useThunkDispatch()
  const isPlanSegment = useSelector(isPlanSegmentSelector)

  const [loading, setLoading] = useState(true)
  const [loadingCompare, setLoadingCompare] = useState(false)
  const popupTarget = useRef<HTMLDivElement>(null)

  useTitle(`${name(product)} - ${name(branch)} - ${name(account)} - Forshaw`)

  // RESTORE DATA
  useEffect(() => {
    if (params.year) {
      dispatch(setProductYear(params.year))
    }
    if (params.compare) {
      dispatch(setCompare(params.compare === 'true'))
    }
  }, [])

  // CLEANUP PRODUCT DATA AFTER CLOSING
  useEffect(() => {
    return () => {
      dispatch(clearProduct())
      dispatch(setProductYear(planYear))
    }
  }, [])

  // ENSURE PRODUCT
  useEffect(() => {
    if (!product) dispatch(requestProduct(accountId, productId, branchId))
  }, [product, productId, branchId])

  // ENSURE DATA
  useEffect(() => {
    if (isPlanSegment) {
      setLoading(false)
    } else {
      setLoading(true)
      dispatch(
        requestProductYearPlan(accountId, branchId, params.year, productId)
      ).then(() => setLoading(false))
    }
  }, [params.year, isPlanSegment])

  // ENSURE COMPARE DATA
  useEffect(() => {
    if (!compare) {
      setLoadingCompare(false)
    } else {
      setLoadingCompare(true)
      dispatch(
        requestProductCompareYearPlan(accountId, branchId, year - 1, productId)
      ).then(() => setLoadingCompare(false))
    }
  }, [year, compare])

  // handle router change
  const handleRouterChange = useCallback(params => {
    const search = qs.stringify(
      {
        ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
        ...params,
      },
      { addQueryPrefix: true }
    )
    history.push({ search })
  }, [])

  // handle back click
  const handleBack = useCallback(() => {
    const searchParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })

    const { fromReplenishmentPlan } = searchParams

    // This page can be opened from new replenishment plan page
    // In this case we need to close the window
    if (fromReplenishmentPlan) {
      window.close()
    } else {
      // TODO: find the better way
      const link = match.params.accountId
        ? `/clients/${accountId}/branches/${branchId}`
        : '/plan'
      history.push(link)
    }
  }, [accountId, branchId])

  // handle compare year change
  const handleCompare = useCallback((value: boolean | undefined) => {
    dispatch(setCompare(!!value))
    handleRouterChange({ compare: !!value })
  }, [])

  // handle year change
  const handleNextYear = useCallback(() => {
    const value = year + 1
    dispatch(setProductYear(value))
    handleRouterChange({ year: value })
  }, [year])

  const handlePreviousYear = useCallback(() => {
    const value = year - 1
    dispatch(setProductYear(value))
    handleRouterChange({ year: value })
  }, [year])

  return (
    <div className="product" ref={popupTarget}>
      <div className="product_header">
        <div className="product_container flexible-container">
          <div className="product_header-title">Product Info</div>
          <button className="product_close clear-button" onClick={handleBack}>
            <CrossIcon />
          </button>
        </div>
      </div>

      <div className="product_header">
        <div className="product_container container">
          <div className="product_header-row">
            <Splash
              className="product_header-col -big"
              isLoading={!product}
              bgColor="transparent"
            >
              {product && (
                <PlanProduct className="-hero" productOrOrderItem={product} />
              )}
            </Splash>
            <div className="product_header-col -v-sep" />
            <div className="product_header-col -small product_toggle-compare">
              <Toggle<boolean>
                value={compare}
                onChange={handleCompare}
                option={{
                  value: true,
                  label: `Compare to ${year - 1}`,
                }}
              />
              <div className="product_header-col -h-sep" />
              <div className="pager">
                <button
                  disabled={
                    loading ||
                    !requestedBranch ||
                    !includes(year - 1, requestedBranch.plannedYears)
                  }
                  onClick={handlePreviousYear}
                  className="pager_button clear-button link"
                >
                  <ArrowLeftIcon />
                </button>
                <div className="pager_title">{year}</div>
                <button
                  disabled={
                    loading ||
                    !requestedBranch ||
                    !includes(year + 1, requestedBranch.plannedYears)
                  }
                  onClick={handleNextYear}
                  className="pager_button clear-button link"
                >
                  <ArrowRightIcon />
                </button>
              </div>
            </div>
            <div className="product_header-col -v-sep" />
            <div className="product_header-col -big" />
          </div>
        </div>
      </div>

      <Splash
        isLoading={loading || loadingCompare}
        withWrapper
        style={{ minHeight: 493 }}
      >
        {months.length === 12 && product && !loading && !loadingCompare && (
          <div className="container">
            <div className="graph">
              {SHORT_MONTHS.map(month => (
                <div className="graph_month" key={month} />
              ))}
              <div className="graph_frame">
                <ProductChart
                  months={months}
                  shipments={shipments[product.id]}
                  compareShipments={
                    compare ? compareShipments[product.id] : undefined
                  }
                  forecast={forecasts[product.id]}
                  replenish={replenish[product.id]}
                  fillins={fillins[product.id]}
                />
              </div>
            </div>
            <div className="product_row">
              {SHORT_MONTHS.map(month => (
                <div className="product_col" key={month}>
                  <div className="product_month-title">{month}</div>
                </div>
              ))}
            </div>
            <div className="product_row">
              <PlanCellActionsContext.Provider
                value={{ requestCreateOrderItem, requestUpdateOrderItem }}
              >
                {months.map(month => (
                  <div key={month.id} className="product_col">
                    <PlanTableCell
                      inPlan
                      product={product}
                      month={month}
                      editable={isEditableOrder(month)}
                      forecast={
                        forecasts[product.id] &&
                        forecasts[product.id][month.month]
                      }
                      shipments={
                        shipments[product.id] &&
                        shipments[product.id][month.month]
                      }
                      replenish={
                        replenish[product.id] &&
                        replenish[product.id][month.month]
                      }
                      fillins={
                        fillins[product.id] && fillins[product.id][month.month]
                      }
                    />
                  </div>
                ))}
              </PlanCellActionsContext.Provider>
            </div>
          </div>
        )}
      </Splash>
    </div>
  )
}

export default connect(
  createStructuredSelector({
    planYear: planYearSelector,
    year: productYearSelector,
    params: productParamsSelector,
    product: productProductSelector,
    requestedBranch: productRequestedBranchSelector,
    months: productMonthsSelector,
    forecasts: productForecastsSelector,
    shipments: productShipmentsSelector,
    compareShipments: productShipmentsCompareSelector,
    replenish: productReplenishSelector,
    fillins: productFillinSelector,
    compare: compareProductSelector,
  })
)(withAccountAndBranch(Product))
