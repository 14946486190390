import React, { FunctionComponent, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'

interface IProps {
  id: string
  className?: string
}

const Tooltip: FunctionComponent<IProps> = ({
  id,
  className,
  children,
  ...rest
}) => (
  <Fragment>
    {children}
    <ReactTooltip id={id} className={className} html {...rest} />
  </Fragment>
)

export default Tooltip
