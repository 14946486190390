import { FunctionComponent, default as React } from 'react'
import { Link, NavLink } from 'react-router-dom'
import cn from 'classnames'

const HeaderRootMenu: FunctionComponent = () => (
  <nav className="menu">
    <NavLink
      activeClassName="-active"
      className="menu_root-link link"
      to="/clients"
    >
      Clients
    </NavLink>
    <NavLink
      activeClassName="-active"
      className="menu_root-link link"
      to="/orders"
    >
      Orders
    </NavLink>
    <NavLink
      activeClassName="-active"
      className="menu_root-link link"
      to="/users"
    >
      Internal Users
    </NavLink>
  </nav>
)

export default HeaderRootMenu
