import useTitle from 'react-use/lib/useTitle'
import { IBranch, IAccount } from '../types/user'
import name from './name'

interface IHookParams {
  branch?: IBranch
  account?: IAccount
}

export default ({ branch, account }: IHookParams) => {
  useTitle(
    [name(branch), name(account), 'Forshaw'].filter(i => !!i).join(' - ')
  )
}
