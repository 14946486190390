import { handleActions } from 'redux-actions'
import { __, assign, flow, indexBy, set, update } from 'lodash/fp'

import { CLEAR_PLAN, SET_PLAN_ORDERS, SET_PLAN_YEAR, UPDATE_PLAN_ORDER } from './planActions'
import { IOrder } from '../types/orders'
import { IHash } from '../types/common'
import { IPlannedBranch } from '../types/user'

const DEFAULT_STATE = {
  year: new Date().getFullYear(),
  orders: {} as IHash<IOrder>,
  requestedBranch: undefined as undefined | IPlannedBranch,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [SET_PLAN_YEAR]: (state, { payload }: SET_PLAN_YEAR) => set('year', payload, state),
    [CLEAR_PLAN]: (state) => set('orders', [], state),
    [SET_PLAN_ORDERS]: (state, { payload: { orders, branch } }: SET_PLAN_ORDERS) =>
      flow<any, any, any>(
        set('orders', indexBy('id', orders)),
        set('requestedBranch', branch)
      )(state),

    [UPDATE_PLAN_ORDER]: (state, { payload }: UPDATE_PLAN_ORDER) =>
      update(`orders[${payload.id}]`, assign(__, payload), state),
  },
  DEFAULT_STATE
)
