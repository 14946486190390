export const CURRENT_USER_QUERY = `
{
  me {
    id
    name
    email
    avatar
    role
    generalManager
    account {
      id
      name
      orderApproveFlow
      ordersEnabled
      erpCatalogs
      replenishmentPlanVersion
    }
  }
}
`
