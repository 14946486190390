import * as React from 'react'
import { isEqual } from 'lodash'
import cn from 'classnames'

interface IOption<T> {
  label: string
  value: T
}
interface IRadioProps<T = any> {
  value: T
  options: [IOption<T>, IOption<T>]
  onChange: (value: T) => void
}
interface ICheckProps<T = any> {
  value?: T
  option: IOption<T>
  onChange: (value?: T) => void
  className?: string
}
type IOwnProps<T = any> = IRadioProps<T> | ICheckProps<T>

const isRadioProps = (props: IOwnProps): props is IRadioProps =>
  !!(props as IRadioProps).options

const Toggle: <T>(
  p: IOwnProps<T>,
) => React.ReactElement<IOwnProps<T>> = props => {
  if (isRadioProps(props)) {
    const {
      options: [left, right],
      value,
      onChange,
    } = props
    return (
      <div className="toggle">
        <button
          type="button"
          className={cn('clear-button', 'toggle_button', {
            '-active': isEqual(value, left.value),
          })}
          onClick={() => onChange(left.value)}
        >
          {left.label}
        </button>
        <button
          type="button"
          className={cn(
            'clear-button',
            'toggle_runner',
            isEqual(value, right.value) ? '-right' : '-left',
          )}
          onClick={() =>
            onChange(isEqual(value, left.value) ? right.value : left.value)
          }
        />
        <button
          type="button"
          className={cn('clear-button', 'toggle_button', {
            '-active': isEqual(value, right.value),
          })}
          onClick={() => onChange(right.value)}
        >
          {right.label}
        </button>
      </div>
    )
  } else {
    const { option, value, onChange, className } = props

    return (
      <div className={cn('toggle', className)}>
        <button
          type="button"
          className={cn(
            'clear-button',
            'toggle_runner',
            '-single',
            isEqual(value, option.value) ? '-right' : '-left',
          )}
          onClick={() =>
            onChange(isEqual(value, option.value) ? undefined : option.value)
          }
        />
        <button
          type="button"
          className={cn('clear-button', 'toggle_button', {
            '-active': isEqual(value, option.value),
          })}
          onClick={() =>
            onChange(isEqual(value, option.value) ? undefined : option.value)
          }
        >
          {option.label}
        </button>
      </div>
    )
  }
}

export default Toggle
