import { getOr, isNumber, sumBy } from 'lodash/fp'
import {
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
  isReplenishSubmittedOrder,
} from '../types/orders'
import useMonthInfo from './useMonthInfo'
import usePlanProductAmount from './usePlanProductAmount'

interface IFunctionProps {
  month: IPlannedOrder
  shipments?: IBranchShipment[]
  replenish?: IPlannedOrderItem & { order: IPlannedOrder }
  fillins?: Array<IOrderItem & { order: IFillinOrder }>
}

export default ({
  month,
  shipments,
  replenish,
  fillins,
}: IFunctionProps): number => {
  const amount: number = usePlanProductAmount({
    month,
    shipments,
    replenish,
    fillins,
  })
  const { isCurrentMonth } = useMonthInfo(month)
  return isCurrentMonth && !isReplenishSubmittedOrder(month)
    ? getOr(0, 'amountRequested', replenish)
    : amount
}
