import { default as React, FC } from 'react'
import { format } from 'date-fns'

import ModalWrapper from '../../../components/Modal/ModalWrapper'
import Button from '../../../components/Button'
import { IDetailsOrder } from '../../../types/orders'
import CrossIcon from '../../../icons/cross.svg'

interface ISendApproveProps {
  order: IDetailsOrder
  onApprove: () => void
  onClose: () => void
}

interface ISendResultProps {
  onClose: () => void
}

export const SendApprove: FC<ISendApproveProps> = ({
  order,
  onApprove,
  onClose,
}) => (
  <ModalWrapper className="import">
    <button className="import_backdrop clear-button" onClick={onClose} />
    <div className="import_dialog action-modal">
      <div className="action-modal_title">Request Tracking Details</div>
      <CrossIcon className="product-modal_close" onClick={onClose} />
      <div className="action-modal_info">
        Do you want to request shipment tracking details for the order{' '}
        <b>{order.navOrderId || '-'}</b>?
      </div>
      <div className="action-modal_actions" style={{ marginTop: 30 }}>
        <Button block color="primary" onClick={onApprove}>
          Yes, Please
        </Button>
        <Button
          block
          color="primary-link"
          className="action-modal_cancel"
          onClick={onClose}
        >
          Nevermind
        </Button>
      </div>
    </div>
  </ModalWrapper>
)

export const SendResult: FC<ISendResultProps> = ({ onClose }) => (
  <ModalWrapper className="import">
    <button className="import_backdrop clear-button" onClick={onClose} />
    <div className="import_dialog action-modal">
      <div className="action-modal_title">Request Accepted</div>
      <CrossIcon className="product-modal_close" onClick={onClose} />
      <div className="action-modal_info">
        A support ticket has been created on your behalf and ForeSight support
        will respond with the requested tracking information. Thanks!
      </div>
      <div className="action-modal_actions" style={{ marginTop: 30 }}>
        <Button block color="primary" onClick={onClose}>
          Ok
        </Button>
      </div>
    </div>
  </ModalWrapper>
)
