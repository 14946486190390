import { IChartPoint } from '../../types/component'
import scale from '../../common/scale'

const Y_RANGE: [number, number] = [90, 10]
const X_DOMAIN: [number, number] = [1, 12]
const X_RANGE: [number, number] = [100 / 24, 100 - 100 / 24]

export const buildPoint = (domain: [number, number], x: any, y: any, tooltip?: string): IChartPoint => {
  const yScale = scale({ domain, range: Y_RANGE })
  const xScale = scale({ domain: X_DOMAIN, range: X_RANGE })

  return ({
    x: xScale(x),
    y: yScale(y),
    tooltip: tooltip && `<strong>${y}</strong><br />${tooltip}`
  })
}
