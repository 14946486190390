import { default as React, FunctionComponent, useState } from 'react'
import { requestMeta } from './authActions'
import useThunkDispatch from '../common/useThunkDispatch'

const Meta: FunctionComponent = ({ children }) => {
  const dispatch = useThunkDispatch()
  const [requestingMeta, setRequestingMeta] = useState(true)

  const meta = async () => {
    await dispatch(requestMeta())
    setRequestingMeta(false)
  }

  React.useEffect(() => {
    meta()
  }, [])

  return requestingMeta ? null : <>{children}</>
}

export default Meta
