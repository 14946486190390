import React, { memo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { map, get } from 'lodash/fp'
import Dropdown from '../../components/Dropdown'
import useThunkDispatch from '../../common/useThunkDispatch'
import { IBranch } from '../../types/user'
import {
  requestManagerBranches,
  requestAccountBranches,
  requestSetManagerBranch,
} from '../managerActions'
import {
  managerBranchesSelector,
  managerBranchSelector,
  managerBranchesOptionsSelector,
} from '../managerSelectors'

interface IPickerOption {
  label: string
  value: number
}

interface IOwnProps {
  className?: string
}

const ManagerBranchesPicker: React.FC<IOwnProps> = ({ className }) => {
  const dispatch = useThunkDispatch()
  const branches: IBranch[] = useSelector(managerBranchesOptionsSelector)
  const branch = useSelector(managerBranchSelector)

  const handleBranchSelect = useCallback(
    option => {
      const selected = branches.find(b => b.id === option.value)
      if (selected) dispatch(requestSetManagerBranch(selected))
    },
    [branches]
  )

  useEffect(() => {
    dispatch(requestManagerBranches())
    dispatch(requestAccountBranches())
  }, [])

  if (!branches.length) return null

  const branchOptions = map(
    (b: IBranch): IPickerOption => ({ value: b.id, label: b.name }),
    branches
  )
  const value = branchOptions.find(
    (o: IPickerOption) => branch && o.value === branch.id
  )

  return (
    <div className={className}>
      {branchOptions.length > 1 ? (
        <Dropdown
          onChange={handleBranchSelect}
          className="-top"
          value={value}
          searcheable={false}
          itemToString={value => (value ? value.label : 'Pick one...')}
          options={branchOptions}
          width="auto"
        />
      ) : (
        <div className="dropdown">
          <button className="clear-button link dropdown_button">
            {value && value.label}
          </button>
        </div>
      )}
    </div>
  )
}

export default memo(ManagerBranchesPicker)
