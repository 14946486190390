import {
  default as React,
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  IDetailsOrder,
  isPlannedOrder,
  isSubmittedOrder,
} from '../types/orders'
import Modal from '../components/Modal'
import OrderIcon from './OrderIcon'
import { format } from 'date-fns'
import name from '../common/name'
import PlanProduct from '../plan/PlanProduct'
import Splash from '../components/Splash'
import useThunkDispatch from '../common/useThunkDispatch'
import { requestDetailsOrder } from './ordersActions'
import OrderDownloadCsv from './OrderDownloadCsv'
import useTitle from 'react-use/lib/useTitle'

interface IOwnProps {
  orderId: number
  onClose: () => void
}

const OrderDetails: FunctionComponent<IOwnProps> = ({ orderId, onClose }) => {
  const dispatch = useThunkDispatch()
  const [order, setOrder] = useState<IDetailsOrder | undefined>(undefined)

  useTitle(
    `Order ${order &&
      format(
        order.submittedAt || order.dueDateAt || order.updatedAt!,
        'MM/DD/YYYY',
      )} - Forshaw`,
  )

  useEffect(() => {
    dispatch(requestDetailsOrder(orderId)).then(setOrder)
  }, [])

  return (
    <Modal onClose={onClose} title="Order Details">
      <div className="order-details">
        {order ? (
          <>
            <div className="order-details_col -left">
              <div className="order-details_title">
                <div className="order-details_title-icon">
                  <OrderIcon order={order} />
                </div>
                {isPlannedOrder(order) ? 'replenishment' : 'fill in'}

                { order && <OrderDownloadCsv name={`order-${order.id}.csv`} navCsv={order.navCsv} /> }
              </div>

              <dl className="order-details_props">
                <dt>Status</dt>
                <dd>{order.status}</dd>
              </dl>
              {isSubmittedOrder(order) ? (
                <>
                  <dl className="order-details_props">
                    <dt>Submitted</dt>
                    <dd>{format(order.submittedAt, 'MM/DD/YYYY')}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Nav Order id</dt>
                    <dd>{order.navOrderId || 'n/a'}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Client</dt>
                    <dd>{name(order.account)}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Branch</dt>
                    <dd>{name(order.branch)}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Submitted By</dt>
                    <dd>{name(order.submittedBy)}</dd>
                  </dl>
                </>
              ) : (
                <>
                  <dl className="order-details_props">
                    <dt>Planned</dt>
                    <dd>
                      {order.dueDateAt && format(order.dueDateAt, 'MM/DD/YYYY')}
                    </dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Nav Order id</dt>
                    <dd>{order.navOrderId || 'n/a'}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Client</dt>
                    <dd>{name(order.account)}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Branch</dt>
                    <dd>{name(order.branch)}</dd>
                  </dl>
                  <dl className="order-details_props">
                    <dt>Updated By</dt>
                    <dd>{name(order.updatedBy)}</dd>
                  </dl>
                </>
              )}
            </div>
            <div className="order-details_col -right">
              {order.orderItems.map(oi => (
                <PlanProduct productOrOrderItem={oi} key={oi.product.id} />
              ))}
            </div>
          </>
        ) : (
          <Splash isLoading />
        )}
      </div>
    </Modal>
  )
}

export default OrderDetails
