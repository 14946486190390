import React, { memo } from 'react'
import cn from 'classnames'
import Tooltip from '../../components/Tooltip'
import { IUser } from '../../types/user'
import GroupIcon from '../../icons/group.svg'
import { initials, info, role } from './utils'

interface IOwnProps {
  user: IUser
  onClick: (user: IUser) => void
  className?: string
}

const UserCard: React.FC<IOwnProps> = ({ user, className, onClick }) => (
  <div className={cn('user-card', className)} onClick={() => onClick(user)}>
    <div className="user-card_avatar">{initials(user.name)}</div>
    <div className="user-card_info">
      <div className="user-card_name">{user.name}</div>
      <div className="user-card_email">{user.email}</div>
      <div className="user-card_details">
        {role(user)}
        {info(user).map((e, i) => (
          <React.Fragment key={i}>
            &nbsp;&bull;&nbsp;
            {e}
          </React.Fragment>
        ))}
      </div>
    </div>
    <div className="user-card_label">
      {user.checkoutManager && (
        <Tooltip id={`tooltip-threshold-${user.id}`}>
          <div
            className="input_label-tooltip"
            data-tip="Manage checkout requests"
            data-for={`tooltip-threshold-${user.id}`}
            data-effect="solid"
            data-place="top"
          >
            <GroupIcon className="product-notify_threshold" />
          </div>
        </Tooltip>
      )}
    </div>
  </div>
)

export default memo(UserCard)
