import { createAction } from 'redux-actions'
import { __, assign, concat, findIndex, get, identity, update } from 'lodash/fp'
import { startOfYear, endOfYear } from 'date-fns'
import { Action, ThunkAction } from '../types/common'
import { IIdentifiableOrderAttrs, IOrder, IOrderItem } from '../types/orders'
import { query } from '../common/api'
import {
  ANNUAL_PLAN_QUERY,
  MANAGER_ANNUAL_PLAN_QUERY,
  CREATE_ORDER_ITEM_QUERY,
  UPDATE_ORDER_ITEM_QUERY,
  ANNUAL_PLAN_BRANCH_QUERY,
  MANAGER_ANNUAL_PLAN_BRANCH_QUERY,
} from '../plan/planQueries'
import { IProduct } from '../types/products'
import {
  PRODUCT_FORECASTS_QUERY,
  PRODUCT_QUERY,
  MANAGER_PRODUCT_QUERY,
  MANAGER_PRODUCT_FORECASTS_QUERY,
} from './productQueries'
import extractErrors from '../common/extractErrors'
import { updatePlanOrder } from '../plan/planActions'
import { isPlanSegmentSelector } from './productSelectors'
import { batch } from 'react-redux'
import { IPlannedBranch } from '../types/user'
import { currentUserSelector } from '../auth/authSelectors'

export const CLEAR_PRODUCT = 'PRODUCT/CLEAR'
export type CLEAR_PRODUCT = Action<void>
export const clearProduct = createAction<void, void>(CLEAR_PRODUCT, identity)

export const SET_PRODUCT_YEAR = 'PRODUCT/SET_YEAR'
export type SET_PRODUCT_YEAR = Action<number>
export const setProductYear = createAction<number, number>(
  SET_PRODUCT_YEAR,
  identity
)

export const SET_PRODUCT_ORDERS = 'PRODUCT/SET_ORDERS'
export type SET_PRODUCT_ORDERS = Action<IOrder[]>
export const setProductOrders = createAction<IOrder[], IOrder[]>(
  SET_PRODUCT_ORDERS,
  identity
)

export const SET_PRODUCT_COMPARE_ORDERS = 'PRODUCT/SET_COMPARE_ORDERS'
export type SET_PRODUCT_COMPARE_ORDERS = Action<IOrder[]>
export const setProductCompareOrders = createAction<IOrder[], IOrder[]>(
  SET_PRODUCT_COMPARE_ORDERS,
  identity
)

export const UPDATE_PRODUCT_ORDER = 'PRODUCT/UPDATE_ORDER'
export type UPDATE_PRODUCT_ORDER = Action<IIdentifiableOrderAttrs>
export const updateProductOrder = createAction<
  IIdentifiableOrderAttrs,
  IIdentifiableOrderAttrs
>(UPDATE_PRODUCT_ORDER, identity)

export const SET_PRODUCT = 'PRODUCT/SET_PRODUCT'
export type SET_PRODUCT = Action<IProduct>
export const setProduct = createAction<IProduct, IProduct>(
  SET_PRODUCT,
  identity
)

export const SET_REQUESTED_BRANCH = 'PRODUCT/SET_REQUESTED_BRANCH'
export type SET_REQUESTED_BRANCH = Action<IPlannedBranch>
export const setRequestedBranch = createAction<IPlannedBranch, IPlannedBranch>(
  SET_REQUESTED_BRANCH,
  identity
)

export const SET_REQUESTED_COMPARE_BRANCH =
  'PRODUCT/SET_REQUESTED_COMPARE_BRANCH'
export type SET_REQUESTED_COMPARE_BRANCH = Action<IPlannedBranch>
export const setRequestedCompareBranch = createAction<
  IPlannedBranch,
  IPlannedBranch
>(SET_REQUESTED_COMPARE_BRANCH, identity)

export const SET_COMPARE = 'PRODUCT/SET_COMPARE'
export type SET_COMPARE = Action<boolean>
export const setCompare = createAction<boolean, boolean>(SET_COMPARE, identity)

export const requestProductYearPlan = (
  accountId: number,
  branchId: number,
  year: number,
  productId: number
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  // await query(ANNUAL_PLAN_QUERY, { branchId, year, productId })

  const currentUser = currentUserSelector(getState())
  const isInternal = currentUser.role === 'internal'
  const annualPlanParams = { branchId, year }
  const forecastParams = {
    accountId,
    branchId,
    year,
    productId,
    fromDate: startOfYear(`${year}-01-01`).toISOString(),
    toDate: endOfYear(`${year}-01-01`).toISOString(),
  }

  const productOrdersQuery = isInternal
    ? ANNUAL_PLAN_QUERY
    : MANAGER_ANNUAL_PLAN_QUERY
  const branchQuery = isInternal
    ? PRODUCT_FORECASTS_QUERY
    : MANAGER_PRODUCT_FORECASTS_QUERY

  const productOrdersDataKey = isInternal
    ? 'internal.retrieveAnnualPlan'
    : 'manager.retrieveAnnualPlan'
  const branchDataKey = isInternal
    ? 'internal.accounts.nodes[0].branches[0]'
    : 'manager.account.branches[0]'

  const [productOrdersData, branchData] = await Promise.all([
    query(productOrdersQuery, annualPlanParams),
    query(branchQuery, forecastParams),
  ])

  const productOrders = get(productOrdersDataKey, productOrdersData)
  const branch = get(branchDataKey, branchData)

  batch(() => {
    dispatch(setProductOrders(productOrders))
    dispatch(setRequestedBranch(branch))
  })
}

export const requestProductCompareYearPlan = (
  accountId: number,
  branchId: number,
  year: number,
  productId: number
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState())
  const isInternal = currentUser.role === 'internal'
  const forecastParams = {
    accountId,
    branchId,
    year,
    productId,
    fromDate: startOfYear(`${year}-01-01`).toISOString(),
    toDate: endOfYear(`${year}-01-01`).toISOString(),
  }

  const branch = isInternal
    ? get(
        'internal.accounts.nodes[0].branches[0]',
        await query(PRODUCT_FORECASTS_QUERY, forecastParams)
      )
    : get(
        'manager.account.branches[0]',
        await query(MANAGER_PRODUCT_FORECASTS_QUERY, forecastParams)
      )

  dispatch(setRequestedCompareBranch(branch))
}

export const requestProduct = (
  accountId: number,
  productId: number,
  branchId: number
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState())
  const isInternal = currentUser.role === 'internal'

  const product = isInternal
    ? get(
        'internal.accounts.nodes[0].products[0]',
        await query(PRODUCT_QUERY, { accountId, productId, branchId })
      )
    : get(
        'manager.account.branches[0].products[0]',
        await query(MANAGER_PRODUCT_QUERY, { branchId, productId })
      )
  dispatch(setProduct(product))
}

export const requestUpdateOrderItem = (
  order: IOrder,
  { id, ...attrs }: { id: number } & Partial<IOrderItem>
): ThunkAction<Promise<void | string>> => async (dispatch, getState) => {
  try {
    const index = findIndex({ id }, order.orderItems)

    const {
      orderItemUpdate: { result: updatedAttrs, errors },
    } = await query(UPDATE_ORDER_ITEM_QUERY, { id, attributes: attrs })
    if (errors) return extractErrors(errors)

    const updatedOrder = update(
      `orderItems[${index}]`,
      assign(__, updatedAttrs),
      order
    )

    if (isPlanSegmentSelector(getState()))
      dispatch(updatePlanOrder(updatedOrder))
    else dispatch(updateProductOrder(updatedOrder))
  } catch (e) {
    return 'Server error'
  }
}

export const requestCreateOrderItem = (
  order: IOrder,
  attrs: Partial<IOrderItem>
): ThunkAction<Promise<void | string>> => async (dispatch, getState) => {
  try {
    const {
      orderItemCreate: { result: createdItem, errors },
    } = await query(CREATE_ORDER_ITEM_QUERY, {
      attributes: { orderId: order.id, ...attrs },
    })
    if (errors) return extractErrors(errors)

    const updatedOrder = update('orderItems', concat(createdItem), order)

    if (isPlanSegmentSelector(getState()))
      dispatch(updatePlanOrder(updatedOrder))
    else dispatch(updateProductOrder(updatedOrder))
  } catch (e) {
    return 'Server error'
  }
}
