import { createAction } from 'redux-actions'
import {
  __,
  assign,
  concat,
  findIndex,
  get,
  identity,
  indexBy,
  map,
  omit,
  set,
  update,
  values,
  reject,
} from 'lodash/fp'
import { Action, IHash, ThunkAction } from '../types/common'
import { query } from '../common/api'
import {
  ACCOUNTS_QUERY,
  CATALOG_ACCOUNT_QUERY,
  MANAGER_CATALOG_ACCOUNT_QUERY,
  CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY,
  MANAGER_CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY,
  CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY,
  MANAGER_CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY,
  CATALOG_ACCOUNT_PRODUCTS_QUERY,
  MANAGER_CATALOG_ACCOUNT_PRODUCTS_QUERY,
  CREATE_CHECKOUTABLE_PRODUCT_QUERY,
  CREATE_TECHNICIAN_QUERY,
  DETAILED_ACCOUNT_QUERY,
  REMOVE_TECHNICIAN_QUERY,
  SCHEDULE_ACCOUNT_SYNC_QUERY,
  CREATE_BRANCH_QUERY,
  UPDATE_BRANCH_QUERY,
  UPDATE_MANAGER_QUERY,
  UPDATE_CHECKOUTABLE_PRODUCT_QUERY,
  UPDATE_TECHNICIAN_QUERY,
  UPLOAD_ACCOUNTS_QUERY,
  UPLOAD_SHIPMENTS_QUERY,
  UPLOAD_TECHNICIANS_QUERY,
  USERS_ACCOUNT_QUERY,
  BRANCH_DELETE,
  STORE_ACCOUNT_LINKS_QUERY,
  UPLOAD_BRANCHES_QUERY,
  UPLOAD_PRODUCTS_QUERY,
  ACCOUNT_UPDATE_QUERY,
} from './accountsQueries'
import {
  accountsDictSelector,
  accountsSearchIndexSelector,
  accountsSelector,
  catalogAccountSearchIndexSelector,
  catalogAccountSelector,
  detailedAccountSelector,
  divisionsAccountSelector,
  usersAccountSelector,
} from './accountsSelectors'
import { IAccount, IBILink, IBranch, IDivision, UserRole } from '../types/user'
import { IProduct, IProductKind, GenericProductKind } from '../types/products'
import { IPaginationState, IPaginationReq } from '../types/common'
import extractErrors from '../common/extractErrors'
import { accountProducts } from '../common/products'
import { currentUserSelector } from '../auth/authSelectors'

export const SET_ACCOUNTS = 'ACCOUNTS/SET_ACCOUNTS'
export type SET_ACCOUNTS = Action<IAccount[]>
export const setAccounts = createAction<IAccount[], IAccount[]>(
  SET_ACCOUNTS,
  identity
)

export const UPDATE_ACCOUNT = 'ACCOUNTS/UPDATE_ACCOUNT'
export type UPDATE_ACCOUNT = Action<IAccount>
export const updateAccount = createAction<IAccount, IAccount>(
  UPDATE_ACCOUNT,
  identity
)

export const SET_ACCOUNTS_PAGINATION_STATE = 'ACCOUNTS/SET_PAGINATION_STATE'
export type SET_ACCOUNTS_PAGINATION_STATE = Action<IPaginationState>
export const setPaginationState = createAction<
  IPaginationState,
  IPaginationState
>(SET_ACCOUNTS_PAGINATION_STATE, identity)

export const SET_ACCOUNTS_TOTAL = 'ACCOUNTS/SET_TOTAL'
export type SET_ACCOUNTS_TOTAL = Action<number>
export const setTotal = createAction<number, number>(
  SET_ACCOUNTS_TOTAL,
  identity
)

export interface IAccountFilter {
  text?: string
  accountId?: number
}
export type FilterAccountsReq = IPaginationReq & IAccountFilter
export const requestAccounts = (
  req: FilterAccountsReq
): ThunkAction<Promise<void>> => async dispatch => {
  const response = await query(ACCOUNTS_QUERY, req)
  const accounts = get('internal.accounts.nodes', response)
  const pageInfo: IPaginationState = get('internal.accounts.pageInfo', response)
  const total: number = get('internal.accounts.totalItems', response)
  dispatch(setPaginationState(pageInfo))
  dispatch(setAccounts(accounts))
  dispatch(setTotal(total))
}

export const SET_DETAILED_ACCOUNT = 'ACCOUNTS/SET_DETAILED_ACCOUNT'
export type SET_DETAILED_ACCOUNT = Action<IAccount>
export const setDetailedAccount = createAction<IAccount, IAccount>(
  SET_DETAILED_ACCOUNT,
  identity
)

export const UPDATE_DETAILED_ACCOUNT = 'ACCOUNTS/UPDATE_DETAILED_ACCOUNT'
export type UPDATE_DETAILED_ACCOUNT = Action<Partial<IAccount>>
export const updateDetailedAccount = createAction<
  Partial<IAccount>,
  Partial<IAccount>
>(UPDATE_DETAILED_ACCOUNT, identity)

export const SET_CATALOG_ACCOUNT = 'ACCOUNTS/SET_CATALOG_ACCOUNT'
export type SET_CATALOG_ACCOUNT = Action<IAccount>
export const setCatalogAccount = createAction<IAccount, IAccount>(
  SET_CATALOG_ACCOUNT,
  identity
)

export const SET_USERS_ACCOUNT = 'ACCOUNTS/SET_USERS_ACCOUNT'
export type SET_USERS_ACCOUNT = Action<IAccount>
export const setUsersAccount = createAction<IAccount, IAccount>(
  SET_USERS_ACCOUNT,
  identity
)

export const requestDetailedAccount = (
  id: number
): ThunkAction<Promise<void>> => async dispatch => {
  const today = new Date()
  const account = get(
    'internal.accounts.nodes[0]',
    await query(DETAILED_ACCOUNT_QUERY, {
      id,
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    })
  )
  dispatch(setDetailedAccount(account))
}

export const requestCatalogAccount = (
  id: number,
  branchId: number,
  term: string,
  kind: IProductKind = 'catalog',
  showAll: boolean = true
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const user = currentUserSelector(getState())
  const isManager = user.role === 'manager'

  let accountQuery = isManager
    ? MANAGER_CATALOG_ACCOUNT_QUERY
    : CATALOG_ACCOUNT_QUERY // in case of catalog

  if (kind === 'checkoutable')
    accountQuery = isManager
      ? MANAGER_CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY
      : CATALOG_ACCOUNT_PRODUCTS_CHECKOUTABLE_QUERY
  if (kind === 'orderable')
    accountQuery = isManager
      ? MANAGER_CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY
      : CATALOG_ACCOUNT_PRODUCTS_ORDERABLE_QUERY
  if (kind === 'account')
    accountQuery = isManager
      ? MANAGER_CATALOG_ACCOUNT_PRODUCTS_QUERY
      : CATALOG_ACCOUNT_PRODUCTS_QUERY

  const account = get(
    isManager ? 'manager.account' : 'internal.accounts.nodes[0]',
    await query(accountQuery, { id, branchId, term, showAll })
  )
  dispatch(setCatalogAccount(account))
}

export const requestUsersAccount = (
  id: number
): ThunkAction<Promise<void>> => async dispatch => {
  const account = get(
    'internal.accounts.nodes[0]',
    await query(USERS_ACCOUNT_QUERY, { id })
  )
  dispatch(setUsersAccount(account))
}

export const searchAccounts = (
  term: string
): ThunkAction<Promise<IAccount[]>> => async (dispatch, getState) => {
  if (term) {
    await dispatch(requestAccounts({ text: term }))
    const accountsIndex = accountsSearchIndexSelector(getState())
    return accountsIndex.search(term)
  } else {
    return accountsSelector(getState())
  }
}

export const searchProducts = (
  accountId: number,
  branchId: number,
  term: string,
  kind: IProductKind = 'catalog'
  // showAll: boolean = false
): ThunkAction<Promise<IProduct[]>> => async (dispatch, getState) => {
  let account = catalogAccountSelector(getState())

  await dispatch(requestCatalogAccount(accountId, branchId, term, kind))

  if (!account || account.id !== accountId) {
    account = catalogAccountSelector(getState())
  }

  const catalogIndex = catalogAccountSearchIndexSelector(kind)(getState())
  if (!catalogIndex || !account) return []

  const allProducts = accountProducts(account, kind)
  if (!allProducts) return []

  if (term) {
    return catalogIndex.search(term)
  } else {
    return allProducts
  }
}

export const uploadAccounts = (
  file: File
): ThunkAction<Promise<number | { [rowNum: string]: string[] }>> => async (
  dispatch,
  getState
) => {
  const { accounts: updatedAccounts, errors } = get(
    'loadAccountsCsv',
    await query(UPLOAD_ACCOUNTS_QUERY, {}, { file })
  )
  if (errors) return errors

  const storedAccounts = accountsDictSelector(getState())
  dispatch(
    setAccounts(values(assign(storedAccounts, indexBy('id', updatedAccounts))))
  )

  return updatedAccounts.length
}

export const uploadBranches = (
  file: File
): ThunkAction<Promise<number | { [rowNum: string]: string[] }>> => async (
  dispatch,
  getState
) => {
  const detailedAccount = detailedAccountSelector(getState())
  if (!detailedAccount) return 'No account selected'

  const { branches: updatedBranches, errors } = get(
    'loadBranchesCsv',
    await query(
      UPLOAD_BRANCHES_QUERY,
      { accountId: detailedAccount.id.toString() },
      { file }
    )
  )
  if (errors) return errors

  const updatedAccount = {
    ...detailedAccount,
    branches: [...detailedAccount.branches, ...updatedBranches],
  }
  dispatch(updateAccount(updatedAccount))
  dispatch(setDetailedAccount(updatedAccount))

  return updatedBranches.length
}

export const uploadTechnicians = (
  file: File,
  accountId: number
): ThunkAction<Promise<number | { [rowNum: string]: string[] }>> => async (
  dispatch,
  getState
) => {
  const { technicians: updatedTechnicians, errors } = get(
    'loadTechniciansCsv',
    await query(
      UPLOAD_TECHNICIANS_QUERY,
      { accountId: String(accountId) },
      { file }
    )
  )
  if (errors) return errors

  const account = usersAccountSelector(getState())
  if (account) {
    const storedUsers = indexBy('id', account.users)
    const updatedUsers = values(
      assign(storedUsers, indexBy('id', updatedTechnicians))
    )
    const updatedAccount = set('users', updatedUsers, account)
    dispatch(setUsersAccount(updatedAccount))
  }

  return updatedTechnicians.length
}

export const uploadShipments = (
  file: File,
  accountId: number
): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const { success } = get(
    'loadShipmentsCsv',
    await query(
      UPLOAD_SHIPMENTS_QUERY,
      { accountId: String(accountId) },
      { file }
    )
  )

  return !!success
}

export const uploadProducts = (
  file: File,
  branchId: number
): ThunkAction<Promise<number | { [rowNum: string]: string[] }>> => async (
  dispatch,
  getState
) => {
  const { errors, result } = get(
    'loadDivisionProductsCsv',
    await query(
      UPLOAD_PRODUCTS_QUERY,
      { branchId: String(branchId) },
      { file }
    )
  )
  if (errors) return errors

  return result.length
}

export const updateAccountUser = (attrs: {
  id?: number
  role?: UserRole
  name?: string
  email?: string
  branches?: IBranch[]
  divisions?: IDivision[]
  avatar?: File
  accountId?: number
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  let account
  let updatedUser
  let updatedUsersAccount

  const {
    id,
    role,
    avatar,
    branches,
    divisions,
    accountId,
    ...userAttributes
  } = attrs
  const branchIds = map('id', branches)
  const divisionIds = map('id', divisions)

  if (role === 'technician') {
    const { errors: techErrors, result: updatedTechnician } = id
      ? get(
          'technicianUpdate',
          await query(
            UPDATE_TECHNICIAN_QUERY,
            { id, attributes: { ...userAttributes, branchIds, divisionIds } },
            avatar ? { file: avatar } : undefined
          )
        )
      : get(
          'technicianCreate',
          await query(
            CREATE_TECHNICIAN_QUERY,
            {
              attributes: {
                ...userAttributes,
                branchIds,
                divisionIds,
                accountId,
              },
            },
            avatar ? { file: avatar } : undefined
          )
        )

    if (techErrors) return techErrors

    updatedUser = updatedTechnician
  }

  if (role === 'manager' && id) {
    const { errors: managerErrors, result: updatedManager } = get(
      'managerUpdate',
      await query(
        UPDATE_MANAGER_QUERY,
        { id, attributes: { branchIds } },
        avatar ? { file: avatar } : undefined
      )
    )

    if (managerErrors) return managerErrors

    updatedUser = updatedManager
  }

  account = usersAccountSelector(getState())
  if (!account || !updatedUser) return
  if (id) {
    const userIndex = findIndex({ id: attrs.id }, account.users)
    updatedUsersAccount = update(
      `users[${userIndex}]`,
      assign(__, updatedUser),
      account
    )
  } else updatedUsersAccount = update(`users`, concat(updatedUser), account)

  if (updatedUsersAccount) dispatch(setUsersAccount(updatedUsersAccount))
}

export const removeAccountTechnician = (
  id: number
): ThunkAction<Promise<void | IHash<string>>> => async (dispatch, getState) => {
  const { errors } = get(
    'technicianRemove',
    await query(REMOVE_TECHNICIAN_QUERY, { id })
  )
  if (errors) return errors

  const account = usersAccountSelector(getState())
  if (account) {
    const updatedUsersAccount = update(`users`, reject({ id }), account)
    dispatch(setUsersAccount(updatedUsersAccount))
  }
}

export const createAccountBranch = ({
  ...attributes
}: {
  id: number
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  const { result: createdAttrs } = get(
    'branchCreate',
    await query(CREATE_BRANCH_QUERY, { attributes })
  )

  const detailedAccount = detailedAccountSelector(getState())
  if (detailedAccount) {
    const branchIndex = detailedAccount.branches.length + 1

    const updatedAccount = update(
      `branches[${branchIndex}]`,
      assign(__, createdAttrs),
      detailedAccount
    )
    dispatch(setDetailedAccount(updatedAccount))
  }
}

export const updateAccountBranch = ({
  id,
  ...attributes
}: {
  id: number
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  const { result: updatedAttrs } = get(
    'branchUpdate',
    await query(UPDATE_BRANCH_QUERY, { id, attributes })
  )

  const detailedAccount = detailedAccountSelector(getState())
  if (detailedAccount) {
    const branchIndex = findIndex({ id }, detailedAccount.branches)

    const updatedAccount = update(
      `branches[${branchIndex}]`,
      assign(__, updatedAttrs),
      detailedAccount
    )
    dispatch(setDetailedAccount(updatedAccount))
  }
}

export const scheduleAccountSync = (
  id: number
): ThunkAction<Promise<void | string>> => async (dispatch, getState) => {
  const { errors, result: lastSync } = get(
    'scheduleAccountSync',
    await query(SCHEDULE_ACCOUNT_SYNC_QUERY, { id })
  )

  if (errors) return extractErrors(errors)

  const accounts = accountsSelector(getState())
  const index = findIndex({ id }, accounts)
  const updatedAccounts = set(`${index}.lastSync`, lastSync, accounts)
  dispatch(setAccounts(updatedAccounts))
}

export const updateAccountRepacking = (attrs: {
  productId: number
  id?: number
  amount?: number
  unit?: string
  quantity?: number
  enabled?: boolean
  kind?: GenericProductKind
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  const account =
    catalogAccountSelector(getState()) || divisionsAccountSelector(getState())
  const accountId = account ? account.id : null
  const { result: updatedAttrs, errors } =
    attrs.kind === 'checkoutable'
      ? get(
          'checkoutableProductUpdate',
          await query(UPDATE_CHECKOUTABLE_PRODUCT_QUERY, {
            accountId,
            productId: attrs.productId,
            attributes: {
              ...omit(['id', 'enabled', 'productId', 'kind'], attrs),
            },
          })
        )
      : get(
          'checkoutableProductCreate',
          await query(CREATE_CHECKOUTABLE_PRODUCT_QUERY, {
            attributes: {
              ...omit(['id', 'enabled', 'kind'], attrs),
              accountId,
            },
          })
        )

  if (errors) return errors

  if (account) {
    const productIndex = findIndex(
      { id: attrs.productId },
      account.productsCheckoutable
    )
    const updatedAccount = set(
      `productsCheckoutable[${productIndex}]`,
      updatedAttrs,
      account
    )
    dispatch(setCatalogAccount(updatedAccount))
  }
}

export const requestBranchDelete = (
  branchId: number
): ThunkAction<Promise<void | string>> => async () => {
  try {
    const {
      branchDelete: { errors },
    } = await query(BRANCH_DELETE, { branchId })
    if (errors) return extractErrors(errors)
  } catch (e) {
    return 'Server error'
  }
}

export const requestStoreAccountLinks = (
  accountId: number,
  links: IBILink[]
): ThunkAction<Promise<void | string>> => async dispatch => {
  const {
    storeBiLinks: { errors, result },
  } = await query(STORE_ACCOUNT_LINKS_QUERY, {
    accountId,
    links: map(omit(['id']), links),
  })
  if (result) {
    dispatch(updateDetailedAccount({ biLinks: result }))
  }
  return errors
}

export const requestAccountUpdate = (
  account: IAccount
): ThunkAction<Promise<void | string>> => async dispatch => {
  const {
    accountUpdate: { errors, result },
  } = await query(ACCOUNT_UPDATE_QUERY, {
    id: account.id,
    attributes: {
      live: account.live,
      orderApproveFlow: account.orderApproveFlow,
    },
  })
  if (result) {
    dispatch(updateAccount({ ...account, ...result }))
  }
  return extractErrors(errors)
}
