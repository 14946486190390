import React, { FC, memo, useEffect, useState } from 'react'
import ClickOutside from 'react-click-outside'
import { useSelector } from 'react-redux'
import ChevronDown from '../../icons/chevron_down.svg'
import ExternalLink from '../../icons/external_link.svg'
import BasePopover from '../../components/BasePopover'
import useThunkDispatch from '../../common/useThunkDispatch'
import { requestManagerLinks } from '../managerActions'
import { managerBiLinksSelector } from '../managerSelectors'

interface IManagerBILinksProps {
  className?: string
}

const ManagerBILinks: FC<IManagerBILinksProps> = ({ className }) => {
  const dispatch = useThunkDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const biLinks = useSelector(managerBiLinksSelector)

  useEffect(() => {
    dispatch(requestManagerLinks())
  }, [])

  if (!biLinks.length) return null

  return (
    <div className={className}>
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <BasePopover
          className="menu_popup -settings"
          isOpen={open}
          offset={20}
          body={
            <>
              {biLinks.map(link => (
                <a
                  key={link.id}
                  href={link.url}
                  className="menu_popup-link -external"
                  target="_blank"
                  rel="noopener"
                >
                  <ExternalLink /> {link.title}
                </a>
              ))}
            </>
          }
        >
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="clear-button link menu_toggle"
          >
            Reports
            <ChevronDown className="menu_popup-icon" />
          </button>
        </BasePopover>
      </ClickOutside>
    </div>
  )
}

export default memo(ManagerBILinks)
