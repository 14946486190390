import {
  filter,
  flatMap,
  flow,
  get,
  getOr,
  map,
  set,
  sortBy,
  transform,
  values,
  orderBy,
} from 'lodash/fp'
import { getMonth, parse } from 'date-fns'

import { IHash, Selector } from '../types/common'
import { createSelector } from 'reselect'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
} from '../types/orders'
import { IPlannedBranch } from '../types/user'
import { IProduct } from '../types/products'

export const planYearSelector: Selector<number> = get('plan.year')
export const planOrdersDictSelector: Selector<IHash<IOrder>> = get(
  'plan.orders'
)
export const planRequestedBranchSelector: Selector<
  IPlannedBranch | undefined
> = get('plan.requestedBranch')

export const planProductsSelector: Selector<IProduct[]> = createSelector(
  planRequestedBranchSelector,
  flow(
    getOr([], 'products'),
    sortBy<IProduct>('name')
  )
)

export const planMonthsSelectorFn = flow(
  values,
  filter({ kind: 'replenish' }),
  sortBy<IPlannedOrder>('month')
)
export const planMonthsSelector: Selector<IPlannedOrder[]> = createSelector(
  planOrdersDictSelector,
  planMonthsSelectorFn
)

export interface IProductMonthGrouped<T> {
  [productId: string]: { [month: string]: T }
}
export const indexByProductMonth = <
  T extends { productId: number; month: number }
>(
  data: T[] = []
) =>
  transform<T, IProductMonthGrouped<T>>(
    (grid, item) => {
      grid[item.productId] = grid[item.productId] || {}
      grid[item.productId][item.month] = item
    },
    {},
    data
  )

export const planForecastsSelectorFn = (branch: IPlannedBranch) =>
  branch ? indexByProductMonth(branch.forecasts) : {}
export const planForecastsSelector: Selector<
  IProductMonthGrouped<IBranchForecast>
> = createSelector(
  planRequestedBranchSelector,
  planForecastsSelectorFn
)

export const planShipmentsSelectorFn = (branch: IPlannedBranch) =>
  branch
    ? transform<IBranchShipment, IProductMonthGrouped<IBranchShipment[]>>(
        (grid, item) => {
          const date = item.date.split('T')[0] // no timezone
          const month = getMonth(date) + 1
          grid[item.productId] = grid[item.productId] || {}
          grid[item.productId][month] = grid[item.productId][month] || []
          grid[item.productId][month].push({ ...item, date })
        },
        {},
        branch.shipments
      )
    : {}
export const planShipmentsSelector: Selector<
  IProductMonthGrouped<IBranchShipment[]>
> = createSelector(
  planRequestedBranchSelector,
  planShipmentsSelectorFn
)

export const planReplenishSelectorFn = flow(
  flatMap((order: IPlannedOrder) => map(set('order', order), order.orderItems)),
  transform<
    IPlannedOrderItem & { order: IPlannedOrder },
    IProductMonthGrouped<IPlannedOrderItem & { order: IPlannedOrder }>
  >(
    (grid = {}, item) => {
      grid[item.product.id] = grid[item.product.id] || {}
      grid[item.product.id][item.order.month] = item
    },
    null as any
  )
)
export const planReplenishSelector: Selector<
  IProductMonthGrouped<IPlannedOrderItem & { order: IPlannedOrder }>
> = createSelector(
  planMonthsSelector,
  planReplenishSelectorFn
)

export const productMonthFillinSelectorFn = flow(
  values,
  filter<IFillinOrder>({ kind: 'fill_in' }),
  flatMap((order: IFillinOrder) => map(set('order', order), order.orderItems)),
  transform<
    IOrderItem & { order: IFillinOrder },
    IProductMonthGrouped<Array<IOrderItem & { order: IFillinOrder }>>
  >(
    (grid = {}, item) => {
      grid[item.product.id] = grid[item.product.id] || {}
      grid[item.product.id][item.order.month] =
        grid[item.product.id][item.order.month] || []
      grid[item.product.id][item.order.month].push(item)
    },
    null as any
  )
)
export const productMonthFillinSelector: Selector<
  IProductMonthGrouped<Array<IOrderItem & { order: IFillinOrder }>>
> = createSelector(
  planOrdersDictSelector,
  productMonthFillinSelectorFn
)
