export const PRODUCT_QUERY = `
query AnnualPlan($branchId: Int, $accountId: Int!, $productId: Int) {
  internal {
    accounts(id: $accountId) {
      nodes {
        products: productsOrderable(id: $productId, branchId: $branchId) {
          id
          name
          code
          photo
        }
      }
    }
  }
}
`

export const MANAGER_PRODUCT_QUERY = `
query ManagerAnnualPlan($branchId: Int!, $productId: Int) {
  manager {
    account {
      branches(id: $branchId) {
        products: plannedProducts(id: $productId) {
          id
          name
          code
          photo
        }
      }
    }
  }
}
`

export const PRODUCT_FORECASTS_QUERY = `
query ProductForecasts(
  $accountId: Int!,
  $branchId: Int!,
  $year: Int!,
  $productId: Int!,
  $fromDate: ISO8601DateTime,
  $toDate: ISO8601DateTime
) {
  internal {
    accounts(id: $accountId) {
      nodes {
        branches(id: $branchId) {
          plannedYears
          forecasts(year: $year, productId: $productId) {
            id
            year
            month
            productId
            amountSuggested
            amountMinSuggested
            amountMaxSuggested
          }
          shipments(productId: $productId, fromDate: $fromDate, toDate: $toDate) {
            id
            date
            productId
            amount
          }
        }
      }
    }
  }
}
`

export const MANAGER_PRODUCT_FORECASTS_QUERY = `
query ManagerProductForecasts(
  $branchId: Int!,
  $year: Int!,
  $productId: Int!,
  $fromDate: ISO8601DateTime,
  $toDate: ISO8601DateTime
) {
  manager {
    account {
      branches(id: $branchId) {
        plannedYears
        forecasts(year: $year, productId: $productId) {
          id
          year
          month
          productId
          amountSuggested
          amountMinSuggested
          amountMaxSuggested
        }
        shipments(productId: $productId, fromDate: $fromDate, toDate: $toDate) {
          id
          date
          productId
          amount
        }
      }
    }
  }
}
`
