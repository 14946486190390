import {
  ComponentType,
  default as React,
  FunctionComponent,
  RefObject,
  useState,
} from 'react'
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import {
  planMonthsSelector,
  productMonthFillinSelector,
  planForecastsSelector,
  planReplenishSelector,
  planShipmentsSelector,
} from '../planSelectors'
import { useSelector } from 'react-redux'
import PlanTableCell from './PlanTableCell'
import { IProduct } from '../../types/products'
import { IPlannedOrder, isEditableOrder } from '../../types/orders'
import PlanTableProduct from './PlanTableProduct'
import { IHash } from '../../types/common'
import Splash from '../../components/Splash'

interface IOwnProps {
  listRef?: RefObject<FixedSizeList>
  products: IProduct[]
  plannedProductsDict: IHash<IProduct>
  selectedMonth: IPlannedOrder
}

const Row: ComponentType<ListChildComponentProps> = ({
  data,
  index,
  style,
}) => {
  const {
    months,
    products,
    plannedProductsDict,
    forecasts,
    shipments,
    replenish,
    fillins,
    selectedMonth,
  } = data
  const [updating, setUpdating] = useState(false)

  const inPlan = !!plannedProductsDict[products[index].id]

  return (
    <div style={style} className="replenishment_row">
      <Splash bgColor="#f2f6fa" isLoading={updating} />
      <PlanTableProduct
        setUpdating={setUpdating}
        inPlan={inPlan}
        product={products[index]}
      />

      {months.map((month: IPlannedOrder) => (
        <div className="replenishment_col" key={month.id}>
          <PlanTableCell
            inPlan={inPlan}
            product={products[index]}
            month={month}
            isSelected={month === selectedMonth}
            editable={inPlan && isEditableOrder(month)}
            forecast={
              forecasts[products[index].id] &&
              forecasts[products[index].id][month.month]
            }
            shipments={
              shipments[products[index].id] &&
              shipments[products[index].id][month.month]
            }
            replenish={
              replenish[products[index].id] &&
              replenish[products[index].id][month.month]
            }
            fillins={
              fillins[products[index].id] &&
              fillins[products[index].id][month.month]
            }
          />
        </div>
      ))}
    </div>
  )
}

const PlanTable: FunctionComponent<IOwnProps> = ({
  products,
  plannedProductsDict,
  listRef,
  selectedMonth,
}) => {
  const months = useSelector(planMonthsSelector)
  const forecasts = useSelector(planForecastsSelector)
  const shipments = useSelector(planShipmentsSelector)
  const replenish = useSelector(planReplenishSelector)
  const fillins = useSelector(productMonthFillinSelector)

  const ItemKey: ListItemKeySelector = index => products[index].id

  return (
    <AutoSizer>
      {size => (
        <FixedSizeList
          {...size}
          ref={listRef}
          className="js-replenishment-scroll-container wonder-scroll"
          itemData={{
            months,
            products,
            plannedProductsDict,
            forecasts,
            shipments,
            replenish,
            fillins,
            selectedMonth,
          }}
          itemCount={products.length}
          itemSize={102}
          itemKey={ItemKey}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  )
}

export default PlanTable
