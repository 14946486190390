import { handleActions } from 'redux-actions'
import { set, __, assign, findIndex, update } from 'lodash/fp'
import { IAccount } from '../types/user'
import { IPaginationState } from '../types/common'
import {
  SET_ACCOUNTS,
  SET_CATALOG_ACCOUNT,
  SET_DETAILED_ACCOUNT,
  SET_USERS_ACCOUNT,
  SET_ACCOUNTS_PAGINATION_STATE,
  SET_ACCOUNTS_TOTAL,
  UPDATE_DETAILED_ACCOUNT,
  UPDATE_ACCOUNT,
} from './accountsActions'

const DEFAULT_STATE = {
  accounts: [] as IAccount[],
  detailedAccount: undefined as IAccount | undefined,
  catalogAccount: undefined as IAccount | undefined,
  usersAccount: undefined as IAccount | undefined,
  divisionsAccount: undefined as IAccount | undefined,
  paginationState: {} as IPaginationState,
  total: 0 as number,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [SET_ACCOUNTS]: (state, { payload: accounts }: SET_ACCOUNTS) =>
      set('accounts', accounts, state),
    [SET_DETAILED_ACCOUNT]: (state, { payload }: SET_DETAILED_ACCOUNT) =>
      set('detailedAccount', payload, state),
    [UPDATE_DETAILED_ACCOUNT]: (state, { payload }: UPDATE_DETAILED_ACCOUNT) =>
      set('detailedAccount', { ...state.detailedAccount, ...payload }, state),
    [SET_CATALOG_ACCOUNT]: (state, { payload }: SET_CATALOG_ACCOUNT) =>
      set('catalogAccount', payload, state),
    [SET_USERS_ACCOUNT]: (state, { payload }: SET_USERS_ACCOUNT) =>
      set('usersAccount', payload, state),
    [SET_ACCOUNTS_PAGINATION_STATE]: (
      state,
      { payload }: SET_ACCOUNTS_PAGINATION_STATE
    ) => set('paginationState', payload, state),
    [SET_ACCOUNTS_TOTAL]: (state, { payload }: SET_ACCOUNTS_TOTAL) =>
      set('total', payload, state),
    [UPDATE_ACCOUNT]: (state, { payload: account }: UPDATE_ACCOUNT) => {
      const index = findIndex<IAccount>(
        a => a.id === account.id,
        state.accounts
      )
      return update(`accounts[${index}]`, assign(__, account), state)
    },
  },
  DEFAULT_STATE
)
