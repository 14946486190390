import { get, indexBy, sortBy } from 'lodash/fp'

import { IHash, Selector, IPaginationState } from '../types/common'
import { createSelector } from 'reselect'
import { IAccount } from '../types/user'
import { IProduct, IProductKind } from '../types/products'
import { accountProducts } from '../common/products'
import { SimpleIndex } from '../common/simpleSearch'

export const accountsSelector: Selector<IAccount[]> = createSelector(
  get('accounts.accounts'),
  sortBy<IAccount>('id')
)

export const accountsPaginationStateSelector: Selector<IPaginationState> = get(
  'accounts.paginationState'
)
export const accountsTotalSelector: Selector<number> = get('accounts.total')

export const accountsDictSelector: Selector<IHash<IAccount>> = createSelector(
  accountsSelector,
  accounts => indexBy('id', accounts)
)

export const accountsSearchIndexSelector: Selector<
  SimpleIndex<IAccount>
> = createSelector(
  accountsSelector,
  accounts =>
    new SimpleIndex<IAccount>(accounts, {
      keys: ['name', 'navAccountId', 'navSalesCode'],
    })
)

export const detailedAccountSelector: Selector<IAccount | undefined> = get(
  'accounts.detailedAccount'
)

export const catalogAccountSelector: Selector<IAccount | undefined> = get(
  'accounts.catalogAccount'
)

export const usersAccountSelector: Selector<IAccount | undefined> = get(
  'accounts.usersAccount'
)

export const divisionsAccountSelector: Selector<IAccount | undefined> = get(
  'accounts.divisionsAccount'
)

export const catalogAccountSearchIndexSelector = (
  kind: IProductKind
): Selector<SimpleIndex<IProduct> | undefined> =>
  createSelector(
    catalogAccountSelector,
    account => {
      if (!account) return undefined
      const products = accountProducts(account, kind)

      return products && new SimpleIndex(products, { keys: ['name', 'code'] })
    }
  )
