import { default as React, FunctionComponent, useEffect } from 'react'
import { NavLink, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import useThunkDispatch from '../useThunkDispatch'
import { useSelector } from 'react-redux'
import { detailedAccountSelector } from '../../accounts/accountsSelectors'
import { find, isEmpty } from 'lodash/fp'
import { requestDetailedAccount } from '../../accounts/accountsActions'
import { managerAccountBranchesSelector } from '../../manager/managerSelectors'
import { IAccount, IBranch } from '../../types/user'
import withAccountAndBranch from '../withAccountAndBranch'

interface IRouteParams {
  accountId: string
  branchId?: string
}

interface IOwnProps {
  account?: IAccount
}

const SubHeaderComponent: FunctionComponent<
  RouteComponentProps<IRouteParams> & IOwnProps
> = ({ match, account: currentAccount }) => {
  const { accountId, branchId } = match.params

  const dispatch = useThunkDispatch()
  const account = useSelector(detailedAccountSelector)
  const branches: IBranch[] = useSelector(managerAccountBranchesSelector)

  useEffect(() => {
    if (isEmpty(account) || isEmpty(branches))
      dispatch(requestDetailedAccount(parseInt(accountId, 10)))
  }, [])

  // const account = accounts[accountId]
  const branch = branchId && find({ id: Number(branchId) }, branches)

  if (!account || !branch) {
    return <></>
  }

  const ordersEnabled = (currentAccount?.ordersEnabled) ?? true;

  return (
    <div className="subheader_menu">
      <NavLink
        activeClassName="-active"
        isActive={(match, location) => {
          if (!match) {
            return false
          }

          return !!location.pathname.match(
            /^\/clients\/[\d]*\/branches\/[\d]*$/
          )
        }}
        className="menu_root-link link"
        to={`/clients/${accountId}/branches/${branch.id}`}
      >
        Replenishment Plan
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to={`/clients/${accountId}/branches/${branch.id}/inventory`}
      >
        Inventory
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to={`/clients/${accountId}/branches/${branch.id}/users`}
      >
        Users
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to={`/clients/${accountId}/branches/${branch.id}/groups`}
      >
        Divisions & Teams
      </NavLink>

      {ordersEnabled && (
        <NavLink
          activeClassName="-active"
          className="menu_root-link link"
          to={`/clients/${accountId}/branches/${branch.id}/orders`}
        >
          Orders
        </NavLink>
      )}

      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to={`/clients/${accountId}/branches/${branch.id}/inbound_inventories`}
      >
        Inbound Inventory
      </NavLink>
    </div>
  )
}

const SubHeaderInternal = () => (
  <Route
    path="/clients/:accountId/(branches)?/:branchId?"
    component={withAccountAndBranch(SubHeaderComponent)}
  />
)

export default SubHeaderInternal
