import { getOr, isNumber, sumBy } from 'lodash/fp'
import {
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
  isReplenishSubmittedOrder,
} from '../types/orders'
import useMonthInfo from './useMonthInfo'

interface IFunctionProps {
  month: IPlannedOrder
  shipments?: IBranchShipment[]
  replenish?: IPlannedOrderItem & { order: IPlannedOrder }
  fillins?: Array<IOrderItem & { order: IFillinOrder }>
}

export default ({
  month,
  shipments,
  replenish,
  fillins,
}: IFunctionProps): number => {
  const { isPastMonth, isCurrentMonth, isFutureMonth } = useMonthInfo(month)
  const replenishSubmitted = isReplenishSubmittedOrder(month)

  const shippedAmount = sumBy('amount', shipments)
  // const fillinRequested = sumBy('amountRequested', fillins)
  // const replenishRequested = getOr(0, 'amountRequested', replenish)
  // const totalRequested = (fillinRequested || 0) + replenishRequested

  // number
  // past month - sum of shipments in the month
  const pathMonthNumber = isPastMonth ? shippedAmount : ''
  // current month, replenishment order submitted - sum of all orders submitted in the month
  const currentMonthWithReplenishment =
    isCurrentMonth && replenishSubmitted ? shippedAmount : ''
  // current month, replenishment order is not submitted  - replenishment order’s amount_requested
  const currentMonthWithoutReplenishment =
    isCurrentMonth && !replenishSubmitted && replenish
      ? replenish.amountRequested
      : ''
  // future month - replenishment order’s amount_requested
  const futureMonthNumber =
    isFutureMonth && replenish ? replenish.amountRequested : ''

  // actual number
  return (
    pathMonthNumber ||
    currentMonthWithReplenishment ||
    currentMonthWithoutReplenishment ||
    futureMonthNumber ||
    0
  )
}
