import React, { ComponentType, FC, useEffect, useState } from 'react'

import useThunkDispatch from '../../common/useThunkDispatch'
import { requestManagerInboundInventories } from '../managerActions'
import Splash from '../../components/Splash'
import { IAccount, IBranch } from '../../types/user'
import { IInboundInventory } from '../../types/common'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import useClientTitle from '../../common/useClientTitle'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { Redirect, RouteComponentProps } from 'react-router'
import BugIcon from '../../icons/bug.svg'
import { startCase } from 'lodash'

interface IProps extends RouteComponentProps {
  account?: IAccount
  branch: IBranch
}

const ManagerInboundInventories: FC<IProps> = ({ branch, account, match }) => {
  // const accountId = account.id
  const dispatch = useThunkDispatch()
  const branchId = branch && branch.id

  const [loading, setLoading] = useState(true)
  const [openedInboundInventory, setOpenedInboundInventory] = useState<
    IInboundInventory | undefined
  >()

  // common
  useClientTitle({ branch, account })

  const [inboundInventorires, setInboundInventorys] = useState<
    IInboundInventory[]
  >([])

  // fetch/refetch catalog
  const loadInboundInventory = async (): Promise<void> => {
    setLoading(true)
    const list = await dispatch<Promise<IInboundInventory[]>>(
      requestManagerInboundInventories(branch.id, account && account.id)
    )
    setInboundInventorys(list)
    setLoading(false)
  }

  // initial load
  useEffect(() => {
    if (branchId) {
      loadInboundInventory()
    }
    setOpenedInboundInventory(undefined)
  }, [branch, match])

  if (openedInboundInventory)
    return <Redirect to={`${match.url}/${openedInboundInventory.id}`} />

  const nothingFound = !inboundInventorires.length && !loading

  const Row: ComponentType<ListChildComponentProps> = ({ index, style }) => {
    const inboundInventoriy = inboundInventorires[index]

    return (
      <div style={style}>
        <div
          className="table_tr -fixed-height -link"
          onClick={() => setOpenedInboundInventory(inboundInventoriy)}
        >
          <div className="table_td -g16">{inboundInventoriy.invoiceDate}</div>
          <div className="table_td -g16">
            {startCase(inboundInventoriy.recordType)}
          </div>
          <div className="table_td -g16">
            {inboundInventoriy.navId
              .replace('Invoice_', '')
              .replace('Transfer_', 'TR_')}
          </div>
          <div className="table_td -g16">
            {inboundInventoriy.navOriginalOrderId || '-'}
          </div>
          <div className="table_td -g16">{inboundInventoriy.itemsCount}</div>
          <div className="table_td -g16">
            {inboundInventoriy.approvedItemsCount}
          </div>
          <div className="table_td -g16">${inboundInventoriy.totalPrice}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      {!nothingFound && (
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -g16 table_title">Posting Date</div>
            <div className="table_td -g16 table_title">Type</div>
            <div className="table_td -g16 table_title">ID</div>
            <div className="table_td -g16 table_title">Original Order ID</div>
            <div className="table_td -g16 table_title">Items</div>
            <div className="table_td -g16 table_title">Items Added</div>
            <div className="table_td -g16 table_title">Total</div>
          </div>
        </div>
      )}

      <div className="table_loading-wrapper">
        {nothingFound ? (
          <div className="replenishment-empty" style={{ minHeight: '500px' }}>
            <div className="replenishment-empty_title">Nothing here</div>
            <div className="replenishment-empty_message">
              New Inbound Inventories will appear here
            </div>
            <BugIcon className="replenishment-empty_icon" />
          </div>
        ) : (
          <AutoSizer
            className="table"
            style={{ height: 'calc(100vh - 200px)' }}
          >
            {size => (
              <FixedSizeList
                className="wonder-scroll"
                {...size}
                itemCount={inboundInventorires.length}
                itemSize={55 + 17}
                itemKey={index => inboundInventorires[index].id}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
        <Splash isLoading={loading} bgColor="#F2F6FA" />
      </div>
    </div>
  )
}

export default withAccountAndBranch(ManagerInboundInventories)
