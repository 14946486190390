import React, { FC, memo, useState, useEffect } from 'react'
import cn from 'classnames'

import ProductForm from './ProductForm'
import DivisionsForm from './DivisionsForm'
import InventoryEvents from './InventoryEvents'

import { IProduct } from '../../types/products'
import { IAccount, IBranch, IDivision } from '../../types/user'
import { IHash } from '../../types/common'
import DateUpdated from '../../components/DateUpdated'

interface IProductFormProps {
  product: IProduct
  branch: IBranch
  account?: IAccount
  divisions: IDivision[]
  distributors: string[]
  loading: boolean
  errors: IHash<string>
  onSave: (product: IProduct) => void
  onRemove: (product: IProduct) => void
  onCancel: () => void
  onRefetch: () => void
}

type ProductInfoTab = 'info' | 'history' | 'divisions'

const filters: Array<{ name: string; type: ProductInfoTab }> = [
  { name: 'Info', type: 'info' },
  { name: 'History', type: 'history' },
  { name: 'Divisions', type: 'divisions' },
]

const ProductInfo: FC<IProductFormProps> = ({
  product,
  branch,
  onSave,
  loading,
  account,
  ...rest
}) => {
  const [tab, setTab] = useState<ProductInfoTab>('info')
  const [saving, setSaving] = useState(false)

  const isNew = !product.id

  useEffect(() => {
    setTab('info')
  }, [product.id])

  const onFinish = async (product: IProduct) => {
    setSaving(true)
    await onSave(product)
    setSaving(false)
  }

  return (
    <div className="product-info">
      <div className="product-info_section">
        <div className="product-info_title">
          {isNew ? (
            <>New Product</>
          ) : (
            <>
              {filters.map(f => (
                <div
                  key={f.type}
                  className={cn('product-info_toggler', {
                    '-active': f.type === tab,
                  })}
                  onClick={() => setTab(f.type)}
                >
                  {f.name}
                </div>
              ))}
              <DateUpdated
                loading={loading}
                updatedAt={product.updatedAt}
                saving={saving}
              />
            </>
          )}
        </div>

        {tab === 'info' && (
          <ProductForm
            product={product}
            branch={branch}
            onSave={onFinish}
            loading={loading}
            account={account}
            {...rest}
          />
        )}
        {tab === 'divisions' && (
          <DivisionsForm
            product={product}
            onSave={onFinish}
            loading={loading}
            {...rest}
          />
        )}
        {tab === 'history' && (
          <InventoryEvents
            product={product}
            branch={branch}
            account={account}
            {...rest}
          />
        )}
      </div>
    </div>
  )
}

export default memo(ProductInfo)
