import React from 'react'
import { Link, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../auth/authSelectors'
import HeaderAvatar from './HeaderAvatar'
import HeaderInternal from './HeaderInternal'
import SubHeaderInternal from './SubHeaderInternal'
import HeaderManager from './HeaderManager'
import SubHeaderManager from './SubHeaderManager'
import LogoIcon from '../../icons/logo.svg'
import HelpPopup from './HelpPopup'

const Header = () => {
  const currentUser = useSelector(currentUserSelector)
  const isInternal = currentUser.role === 'internal'
  return (
    <>
      <header className="header">
        <div className="header_container flexible-container">
          <Link to="/" className="header_logo">
            <LogoIcon />
          </Link>
          {isInternal ? <HeaderInternal /> : <HeaderManager />}
          <HelpPopup />
          <HeaderAvatar />
        </div>
      </header>
      {isInternal ? <SubHeaderInternal /> : <SubHeaderManager />}
    </>
  )
}

export default Header
