import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import withAccountAndBranch from '../withAccountAndBranch'
import { IUser } from '../../types/user'
import { withRouter } from 'react-router'

interface IOwnProps {
  user: IUser
}

const SubHeaderManager: FC<IOwnProps> = ({ user }) => {
  const ordersEnabled = (user.account?.ordersEnabled) ?? true;

  return (
    <div className="subheader_menu">
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/plan"
      >
        Replenishment Plan
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/inventory"
      >
        Inventory
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/users"
      >
        Users
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/groups"
      >
        Divisions & Teams
      </NavLink>
      {ordersEnabled && (
        <NavLink
          activeClassName="-active"
          className="menu_root-link link"
          to="/orders"
        >
          Orders
        </NavLink>
      )}

      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/inbound_inventories"
      >
        Inbound Inventory
      </NavLink>
    </div>
  )
}

export default withRouter(withAccountAndBranch(SubHeaderManager))
