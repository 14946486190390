import React, { FC, memo, useEffect, useState } from 'react'
import cn from 'classnames'

import { IBranch, IDivision, ITeam, IUser } from '../../../types/user'
import { IHash } from '../../../types/common'
import TeamsForm from './TeamsForm'
import DivisionsForm from './DivisionsForm'

interface IUserFormProps {
  user: IUser
  divisions: IDivision[]
  teams: ITeam[]
  loading: boolean
  errors: IHash<string>
  onSave: (user: IUser | { password?: string }) => void
  onDelete?: (user: IUser) => void
  onCancel?: () => void
  onPasswordUpdate?: (user: IUser, password: string) => void
  onBranchUpdate?: (user: IUser, branch: IBranch) => void
}

const GroupsForm: FC<IUserFormProps> = ({
  user: initialUser,
  divisions,
  teams,
  onSave,
}) => {
  const [user, setUser] = useState<IUser>({ ...initialUser })

  useEffect(() => setUser({ ...initialUser }), [initialUser])

  return (
    <div>
      <DivisionsForm user={user} divisions={divisions} onSave={onSave} />
      <TeamsForm user={user} teams={teams} onSave={onSave} />
    </div>
  )
}

export default memo(GroupsForm)
