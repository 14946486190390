import { default as React, FunctionComponent, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import useThunkDispatch from '../useThunkDispatch'
import { useSelector } from 'react-redux'
import { find, isEmpty } from 'lodash/fp'

import { accountsDictSelector } from '../../accounts/accountsSelectors'
import { requestAccounts } from '../../accounts/accountsActions'

interface IRouteParams {
  accountId: string
  branchId?: string
}

const ClientBranchBreadcrumb: FunctionComponent<
  RouteComponentProps<IRouteParams>
> = ({ match, history }) => {
  const { accountId, branchId } = match.params

  const dispatch = useThunkDispatch()
  const accounts = useSelector(accountsDictSelector)

  useEffect(() => {
    if (isEmpty(accounts))
      dispatch(requestAccounts({ accountId: parseInt(accountId, 10) }))
  }, [])

  const account = accounts[accountId]
  const branch =
    branchId && account && find({ id: Number(branchId) }, account.branches)

  if (account)
    return (
      <nav className="breadcrumb">
        <div className="breadcrumb_item">
          <button
            className="clear-button breadcrumb_button"
            onClick={() => history.push(`/clients/${accountId}`)}
          >
            {account.name}
          </button>
        </div>
        {branch && <div className="breadcrumb_item">{branch.name}</div>}
      </nav>
    )

  return null
}

export default ClientBranchBreadcrumb
