import {
  default as React,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import useDebounce from 'react-use/lib/useDebounce'
import useTitle from 'react-use/lib/useTitle'
import { Link } from 'react-router-dom'
import {
  scheduleAccountSync,
  uploadAccounts,
  requestAccounts,
  FilterAccountsReq,
} from './accountsActions'
import Search from '../components/Search'
import { IAccount } from '../types/user'
import { Route, RouteComponentProps, Switch } from 'react-router'
import AccountBranches from './accountBranches/AccountBranches'
import Splash from '../components/Splash'
import Pager from '../components/Pager'
import useThunkDispatch from '../common/useThunkDispatch'
import {
  accountsPaginationStateSelector,
  accountsSelector,
  accountsTotalSelector,
} from './accountsSelectors'
import AccountSync from './AccountSync'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from 'react-window'
import { TriggerImportDialog } from '../components/ImportDialog'
import Button from '../components/Button'

const PER_PAGE = 50

const Row: FunctionComponent<ListChildComponentProps> = ({
  data,
  index,
  style,
}) => {
  const account = data.accounts[index]

  return (
    <div style={style}>
      <Link
        to={`/clients/${account.id}`}
        className="table_tr -link clear-link"
        key={account.id}
      >
        <div className="table_td -fix-id">{account.navAccountId}</div>
        <div className="table_td -g33">{account.name}</div>
        <div className="table_td -g16">{account.navSalesCode}</div>
        <div className="table_td -g33">
          <AccountSync
            sync={account.lastSync}
            onSync={() => data.dispatch(scheduleAccountSync(account.id))}
          />
        </div>
        <div className="table_td -g16">
          {account.orderApproveFlow ? (
            <span className="sync_message -warning">Yes</span>
          ) : (
            <span className="sync_message">No</span>
          )}
        </div>
        <div className="table_td -g16">
          {account.live ? (
            <span className="sync_message -warning">Yes</span>
          ) : (
            <span className="sync_message">No</span>
          )}
        </div>
      </Link>
    </div>
  )
}

const AccountsList: FunctionComponent<RouteComponentProps> = ({ history }) => {
  useTitle('Clients - Forshaw')

  const dispatch = useThunkDispatch()
  const listRef = useRef<FixedSizeList>(null)

  const [page, setPage] = useState<number>(0)
  const [term, setTerm] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const accounts: IAccount[] = useSelector(accountsSelector)
  const paginationState = useSelector(accountsPaginationStateSelector)
  const total = useSelector(accountsTotalSelector)

  const loadAccounts = async (req: FilterAccountsReq, page: number) => {
    setLoading(true)
    await dispatch(requestAccounts(req))
    setPage(page)
    setLoading(false)
  }

  const handlePrevious = () => {
    loadAccounts(
      {
        last: PER_PAGE,
        before: paginationState.startCursor,
        text: term || undefined,
      },
      page - 1
    )
  }

  const handleNext = () => {
    loadAccounts(
      {
        first: PER_PAGE,
        after: paginationState.endCursor,
        text: term || undefined,
      },
      page + 1
    )
  }

  useDebounce(
    async () => {
      if (term == null) return
      if (listRef.current) listRef.current.scrollToItem(0)
      await loadAccounts({ first: PER_PAGE, text: term || undefined }, 0)
    },
    300,
    [term]
  )

  useEffect(() => {
    if (!accounts.length || accounts.length === 1) {
      loadAccounts({ first: PER_PAGE }, 0)
    }
  }, [])

  const ItemKey: ListItemKeySelector = index => accounts[index].id

  return (
    <div className="container">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            placeholder="Search Client"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
        </div>
        <div className="filter_sep" />
        <div className="filter_col">
          <TriggerImportDialog
            action={uploadAccounts}
            templateName="import_clients_template.csv"
            templateUrl="/import_clients_template.csv"
            title="Import clients"
            triggerElement={<Button block>Import Clients</Button>}
          />
        </div>
      </div>

      <div className="table">
        <div className="table_head-tr">
          <div className="table_head-tr table_td -fix-id table_title">ID</div>
          <div className="table_head-tr table_td -g33 table_title">Client</div>
          <div className="table_head-tr table_td -g16 table_title">
            Sales Code
          </div>
          <div className="table_head-tr table_td -g33 table_title">Status</div>
          <div className="table_head-tr table_td -g16 table_title">
            GM Approval
          </div>
          <div className="table_head-tr table_td -g16 table_title">Live</div>
        </div>
      </div>

      <div className="table_loading-wrapper">
        {accounts.length === 0 && !loading ? (
          <div className="table">
            <div className="table_head-tr">
              <div className="table_head-tr table_td table_title">
                {!!term && 'No clients found, please adjust search criteria'}
                {!term && 'No accounts found'}
              </div>
            </div>
          </div>
        ) : (
          <AutoSizer
            className="table"
            style={{ height: 'calc(100vh - 200px)' }}
          >
            {size => (
              <FixedSizeList
                ref={listRef}
                className="wonder-scroll"
                {...size}
                itemData={{ accounts, dispatch }}
                itemCount={accounts.length}
                itemSize={44 + 8}
                itemKey={ItemKey}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
        <Splash isLoading={loading} bgColor="#F2F6FA" />
      </div>

      <Pager
        page={page}
        total={total}
        perPage={PER_PAGE}
        previousEnabled={
          paginationState && paginationState.hasPreviousPage && !loading
        }
        nextEnabled={paginationState && paginationState.hasNextPage && !loading}
        onNext={handleNext}
        onPrevious={handlePrevious}
        className="-right"
      />
    </div>
  )
}

export default AccountsList
