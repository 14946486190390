import { default as React, FunctionComponent, memo } from 'react'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
} from '../../types/orders'
import {
  compact,
  findIndex,
  flattenDeep,
  map,
  max,
  min,
  sumBy,
} from 'lodash/fp'
import Tooltip from '../../components/Tooltip'
import usePlanChartProductAmount from '../../common/usePlanChartProductAmount'
import useMonthInfo from '../../common/useMonthInfo'
import { buildPoint } from './utils'
import Line from './Line'

interface IOwnProps {
  months: IPlannedOrder[]
  replenish?: { [month: string]: IPlannedOrderItem & { order: IPlannedOrder } }
  fillins?: { [month: string]: Array<IOrderItem & { order: IFillinOrder }> }
  forecast?: { [month: string]: IBranchForecast }
  shipments?: { [month: string]: IBranchShipment[] }
  compareShipments?: { [month: string]: IBranchShipment[] }
}

const ProductChart: FunctionComponent<IOwnProps> = ({
  months,
  replenish = {},
  fillins = {},
  forecast = {},
  shipments = {},
  compareShipments = {},
}) => {
  const allValues = flattenDeep([
    map(
      month =>
        usePlanChartProductAmount({
          month,
          shipments: shipments[month.month],
          replenish: replenish[month.month],
          fillins: fillins[month.month],
        }),
      months
    ) as any,
    map(
      f => [f.amountMaxSuggested, f.amountMinSuggested, f.amountSuggested],
      forecast
    ),
    map(month => sumBy('amount', compareShipments[month.month]), months),
  ])

  const minY = min(allValues) || 0
  const maxY = max(allValues) || 0
  const point = buildPoint.bind(null, [minY, maxY])

  const futureIdx = findIndex(
    (month: any) => useMonthInfo(month).isFutureMonth,
    months
  )

  const actualAmountPoints = compact(
    map(month => {
      const amount: number = usePlanChartProductAmount({
        month,
        shipments: shipments[month.month],
        replenish: replenish[month.month],
        fillins: fillins[month.month],
      })
      const name = useMonthInfo(month).isPastMonth ? 'Actual' : 'Planned'
      return point(month.month, amount, name)
    }, months)
  )

  const comparePoints = compact(
    map(month => {
      if (compareShipments[month.month])
        return point(
          month.month,
          sumBy('amount', compareShipments[month.month]),
          'Actual'
        )
      return null
    }, months)
  )

  const suggestedPoints = compact(
    map(
      month =>
        forecast[month.month]
          ? point(
              month.month,
              forecast[month.month].amountSuggested,
              'Forecasted'
            )
          : null,
      months
    )
  )

  const suggestedMinPoints = compact(
    map(
      month =>
        forecast[month.month]
          ? point(
              month.month,
              forecast[month.month].amountMinSuggested,
              'Forecasted Min'
            )
          : null,
      months
    )
  )

  const suggestedMaxPoints = compact(
    map(
      month =>
        forecast[month.month]
          ? point(
              month.month,
              forecast[month.month].amountMaxSuggested,
              'Forecasted Max'
            )
          : null,
      months
    )
  )

  return (
    <Tooltip id="chart" className="graph_tooltip">
      <svg height="100%" width="100%">
        <linearGradient id="compare-gradient" x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="2%" stopColor="#F19CBD" stopOpacity={0.4} />
          <stop offset="89%" stopColor="#F19CBD" stopOpacity={0} />
        </linearGradient>

        {comparePoints.length > 0 && (
          <Line
            points={comparePoints}
            className="graph_compare"
            gradientFill="compare-gradient"
          />
        )}

        <Line points={suggestedPoints} className="graph_planned" />

        <Line points={suggestedMinPoints} className="graph_minmax" />
        <Line points={suggestedMaxPoints} className="graph_minmax" />

        <Line
          points={actualAmountPoints.slice(
            0,
            futureIdx === -1 ? 12 : futureIdx
          )}
          className="graph_replenish"
        />
        {futureIdx >= 0 && (
          <Line
            points={actualAmountPoints.slice(futureIdx && futureIdx - 1, 12)}
            className="graph_replenish -dashed"
          />
        )}
      </svg>
    </Tooltip>
  )
}

export default memo(ProductChart)
