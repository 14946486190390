import { default as React, FunctionComponent, useState } from 'react'
import BasePopover from '../../components/BasePopover'
import HelpCircle from '../../icons/help_circle.svg'
import ClickOutside from 'react-click-outside'

const HelpPopup: FunctionComponent = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <BasePopover
          className="menu_popup -help"
          isOpen={open}
          offset={20}
          body={
            <>
              <h3>Forshaw Support</h3>
              <a href="mailto:support@forshaw.freshdesk.com">
                support@forshaw.freshdesk.com
              </a>
              <span>Сontact us for any questions</span>
            </>
          }
        >
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="clear-button link menu_toggle"
          >
            <HelpCircle />
          </button>
        </BasePopover>
      </ClickOutside>
    </>
  )
}

export default HelpPopup
