import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

import { IProduct } from '../../types/products'
import { IDivision } from '../../types/user'
import { IHash } from '../../types/common'
import Toggle from '../../components/Toggle'

interface IProductFormProps {
  product: IProduct
  divisions: IDivision[]
  distributors: string[]
  loading: boolean
  errors: IHash<string>
  onSave: (product: IProduct) => void
  onRemove: (product: IProduct) => void
  onCancel: () => void
}

const DivisionsForm: FC<IProductFormProps> = ({
  product: initialProduct,
  divisions,
  onSave,
}) => {
  const [product, setProduct] = useState<IProduct>({ ...initialProduct })

  useEffect(() => setProduct({ ...initialProduct }), [initialProduct])

  const productDivisions = (product.divisions || []).map(d => d.id)
  const divisionOptions = [{ id: 'all', name: 'All divisions' }, ...divisions]

  return (
    <div className="product-info_divisions">
      <label className="input_label">Divisions</label>
      {divisionOptions.map(option => (
        <Toggle<string | number | boolean>
          key={option.id}
          className={cn({ 'product-info_all': option.id === 'all' })}
          value={
            option.id === 'all'
              ? productDivisions.length === divisions.length
              : productDivisions.includes(option.id as number)
          }
          onChange={() => {
            let newDivisions =
              productDivisions.length === divisions.length ? [] : divisions
            if (option.id !== 'all') {
              if (
                (product.divisions || [])
                  .map(d => d.id)
                  .includes(option.id as number)
              ) {
                newDivisions = (product.divisions || []).filter(
                  d => d.id !== option.id
                )
              } else {
                newDivisions = [
                  ...(product.divisions || []),
                  option as IDivision,
                ]
              }
            }
            setProduct({ ...product, divisions: newDivisions })
            onSave({ ...product, divisions: newDivisions })
          }}
          option={{
            value: true,
            label: option.name,
          }}
        />
      ))}
    </div>
  )
}

export default memo(DivisionsForm)
