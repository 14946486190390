import {
  default as React,
  FunctionComponent,
  memo,
  ReactNode,
  useState,
} from 'react'
import cn from 'classnames'
import { addMonths, differenceInCalendarDays, format } from 'date-fns'
import LockIcon from '../icons/lock.svg'
import LockOkIcon from '../icons/lock_ok.svg'
import CrossIcon from '../icons/cross_cirlce_tiny.svg'
import LockWithDotIcon from '../icons/lock_with_dot.svg'
import CheckCircleIcon from '../icons/check_circle.svg'
import {
  IPlannedOrder,
  isApprovedOrder,
  isSkippedOrder,
  isSubmittedOrder,
} from '../types/orders'
import { SHORT_MONTHS } from '../common/months'
import BasePopover from '../components/BasePopover'
import useTimeoutOpen from '../common/useTimeoutOpen'
import Splash from '../components/Splash'
import useOrderState from '../common/useOrderState'
import PlanState from './PlanState'

interface IOwnProps {
  month: IPlannedOrder
  isSelected: boolean
  onSelect: () => void
}

const CURR_YEAR = new Date().getFullYear()
const CURR_MONTH = new Date().getMonth() + 1

const PlanMonth: FunctionComponent<IOwnProps> = ({
  month,
  onSelect,
  isSelected,
}) => {
  const daysToReplenish = differenceInCalendarDays(month.dueDateAt, new Date())
  const periodLength = differenceInCalendarDays(
    month.dueDateAt,
    addMonths(month.dueDateAt, -1)
  )

  const [info, openInfo, closeInfo] = useTimeoutOpen(false)
  const [action, setAction] = useState(false)

  const statusChangeable =
    month.month === CURR_MONTH &&
    month.year === CURR_YEAR &&
    !isSubmittedOrder(month)

  const [updating] = useState(false)
  const state = useOrderState(month)

  const wrap = (nodes: ReactNode) =>
    statusChangeable ? (
      <button
        onClick={() => {
          setAction(!action)
          closeInfo()
          onSelect()
        }}
        className={`replenishment_month-title -button clear-button ${
          isSelected ? '-selected' : ''
        }`}
      >
        {nodes}
      </button>
    ) : (
      <div
        className={`replenishment_month-title ${isSelected ? '-selected' : ''}`}
        onClick={() => onSelect()}
      >
        {nodes}
      </div>
    )

  return (
    <BasePopover
      preferPlace="below"
      isOpen={info && !action}
      className="replcut"
      body={<PlanState state={state} />}
    >
      <div
        className="replenishment_month"
        onMouseLeave={closeInfo}
        onMouseEnter={openInfo}
      >
        <Splash isLoading={updating} withWrapper size="small">
          {wrap(
            <>
              {state.past && state.past.isSkipped && (
                <CrossIcon className="replenishment_month-icon -cross" />
              )}
              {state.current && state.current.isRejected && (
                <CrossIcon className="replenishment_month-icon -cross" />
              )}
              {state.future && state.future.isRejected && (
                <CrossIcon className="replenishment_month-icon -cross" />
              )}
              {state.current &&
                (state.current.isSubmitted || state.current.isUrgent) && (
                  <LockWithDotIcon className="replenishment_month-icon -lock-dot" />
                )}
              {((state.current && state.current.isApproved) ||
                (state.future && state.future.isApproved)) && (
                <CheckCircleIcon className="replenishment_month-icon -check-circle" />
              )}
              {SHORT_MONTHS[month.month - 1]}
            </>
          )}
        </Splash>

        <div className="replenishment_month-status-wrapper">
          <div
            className={cn('replenishment_month-status', {
              '-skipped': state.past && state.past.isSkipped,
              '-submitted': state.past && state.past.isSubmitted,
              '-delayed':
                (state.past && state.past.isUrgent) ||
                (state.current && state.current.isUrgent),
              '-open':
                state.current &&
                !state.current.isUrgent &&
                (state.current.isNotApproved ||
                  state.current.isSubmitted ||
                  state.current.isApproved),
            })}
            style={
              state.current
                ? {
                    width: `${((periodLength - daysToReplenish) /
                      periodLength) *
                      100}%`,
                  }
                : {}
            }
          />
        </div>
      </div>
    </BasePopover>
  )
}

export default memo(PlanMonth)
