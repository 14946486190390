import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { concat, getOr, map } from 'lodash/fp'

import Toggle from '../components/Toggle'
import { ordersFilterSelector } from './ordersSelectors'
import { setOrdersFilter } from './ordersActions'
import DropdownSelect, { IOption } from '../components/DropdownSelect'
import { searchAccounts } from '../accounts/accountsActions'
import { IAccount } from '../types/user'
import { accountsDictSelector } from '../accounts/accountsSelectors'

const OrdersFilter: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const ordersFilter = useSelector(ordersFilterSelector)
  const accountsDict = useSelector(accountsDictSelector)

  const accountsOptions = (term: string) =>
    ((dispatch(searchAccounts(term)) as any) as Promise<IAccount[]>)
      .then(map((acc: IAccount): IOption<number | undefined> => ({ value: acc.id, label: acc.name })))
      .then((arr) => concat([{ value: undefined, label: 'All Accounts' }], arr))

  const branchesOptions = concat(
    [{ value: undefined, label: 'All Branches' }] as any,
    ordersFilter.accountId
      ? map(
          (acc: IAccount): IOption<number | undefined> => ({ value: acc.id, label: acc.name }),
          (accountsDict[ordersFilter.accountId] || {}).branches || []
        )
      : []
  )

  return (
    <form className="filter">
      <div className="filter_col -large">
        <Toggle<'upcoming' | 'submitted'>
          value={ordersFilter.scope}
          options={[{ label: 'Submitted', value: 'submitted' }, { label: 'Upcoming', value: 'upcoming' }]}
          onChange={(scope: 'upcoming' | 'submitted') => dispatch(setOrdersFilter({ ...ordersFilter, scope }))}
        />
      </div>
      <div className="filter_sep" />
      <div className="filter_col">
        <DropdownSelect<number | string | undefined>
          value={getOr('All Accounts', [String(ordersFilter.accountId), 'name'], accountsDict)}
          options={accountsOptions}
          onChange={(accountId) =>
            dispatch(setOrdersFilter({ ...ordersFilter, accountId: Number(accountId), branchId: undefined }))
          }
        />
      </div>
      <div className="filter_sep" />
      <div className="filter_col">
        <DropdownSelect<number | undefined>
          value={(ordersFilter.branchId || 'All Branches') as any}
          options={branchesOptions as any}
          onChange={(branchId) => dispatch(setOrdersFilter({ ...ordersFilter, branchId }))}
        />
      </div>
      <div className="filter_sep" />
    </form>
  )
}

export default OrdersFilter
