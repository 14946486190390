import {
  createContext,
  default as React,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { includes, indexBy, find } from 'lodash/fp'
import {
  planMonthsSelector,
  planProductsSelector,
  planRequestedBranchSelector,
  planYearSelector,
} from './planSelectors'
import BugIcon from '../icons/bug.svg'
import ArrowLeftIcon from '../icons/arrow_left.svg'
import ArrowRightIcon from '../icons/arrow_right.svg'
import {
  requestCreateOrderItem,
  requestUpdateOrderItem,
  requestPopulatePlan,
  requestYearPlan,
  setPlanYear,
} from './planActions'
import PlanMonth from './PlanMonth'
import useThunkDispatch from '../common/useThunkDispatch'
import Splash from '../components/Splash'
import PlanFilter from './PlanFilter'
import useClientTitle from '../common/useClientTitle'
import PlanTable from './planTable/PlanTable'
import Search from '../components/Search'
import useDebounce from 'react-use/lib/useDebounce'
import { IProduct } from '../types/products'
import { searchProducts } from '../accounts/accountsActions'
import { FixedSizeList } from 'react-window'
import withAccountAndBranch from '../common/withAccountAndBranch'
import { IBranch, IAccount, IUser } from '../types/user'
import Button from '../components/Button'
import useOrderState from '../common/useOrderState'
import PlanState from './PlanState'
import { IPlannedOrder } from '../types/orders'
import { getShouldRenderNewReplenishmentPlan } from '../common/getShouldRenderNewReplenishmentPlan'

interface IRouteParams {
  productId?: string
}

interface IOwnProps {
  user: IUser
  branch: IBranch
  account?: IAccount
}

export const PlanCellActionsContext = createContext<{
  requestUpdateOrderItem: typeof requestUpdateOrderItem
  requestCreateOrderItem: typeof requestCreateOrderItem
}>({ requestCreateOrderItem, requestUpdateOrderItem })

const Plan: FunctionComponent<
  RouteComponentProps<IRouteParams> & IOwnProps
> = ({ match, user, branch, account: currentAccount, history }) => {
  const account = currentAccount || user.account

  if (!branch || !account) return <Splash isLoading />

  const branchId = branch.id
  const accountId = account.id
  const productId = Number(match.params.productId)

  const dispatch = useThunkDispatch()
  const year = useSelector(planYearSelector)
  const requestedBranch = useSelector(planRequestedBranchSelector)
  useClientTitle({ branch, account })

  const months = useSelector(planMonthsSelector)

  const products = useSelector(planProductsSelector)
  const plannedProductsDict = useMemo(() => indexBy('id', products), [products])

  const [loading, setLoading] = useState(true)
  const [selectedMonth, setSelectedMonth] = useState<IPlannedOrder>()

  const monthsRef = useRef<HTMLDivElement>(null)
  const totalsRef = useRef<HTMLDivElement>(null)

  const selectedMonthState = selectedMonth
    ? useOrderState(selectedMonth)
    : undefined

  useEffect(() => {
    setSelectedMonth(undefined)
  }, [branch, year])

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const month = find(
      m => currentYear === m.year && currentMonth === m.month,
      months
    )

    if (month && !selectedMonth) {
      setSelectedMonth(month)
    }
  }, [year, months, branch])

  useEffect(() => {
    if (
      (months.length > 0 &&
        months[0].year === year &&
        requestedBranch &&
        requestedBranch.id &&
        requestedBranch.id === branchId) ||
      productId
    ) {
      setLoading(false)
    } else {
      setLoading(true)
      dispatch(requestYearPlan(accountId, branchId, year)).finally(() =>
        setLoading(false)
      )
    }
  }, [year, months, productId, branchId, requestedBranch])

  const shouldRenderNewReplenishmentPlan = getShouldRenderNewReplenishmentPlan(
    account.replenishmentPlanVersion
  )

  useEffect(() => {
    // Prevent whole page scrolling when the iframe is rendered
    if (shouldRenderNewReplenishmentPlan) {
      document.documentElement.classList.add('overflow-hidden')
    }

    return () => {
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [shouldRenderNewReplenishmentPlan])

  // SEARCHING LOGIC
  const [term, setTerm] = useState('')
  const [searching, setSearching] = useState(false)
  const [foundProducts, setFoundProducts] = useState<IProduct[] | null>(null)
  const listRef = useRef<FixedSizeList>(null)
  const isInternal = user.role === 'internal'
  const isProducts =
    (foundProducts && foundProducts.length) ||
    (!foundProducts && products.length)

  useDebounce(
    async () => {
      if (!term || !branch) return

      setSearching(true)
      setFoundProducts([])
      const results = await dispatch(
        searchProducts(accountId, branch.id, term, 'orderable')
      )
      setFoundProducts(results)

      if (listRef.current) listRef.current.scrollTo(0)
      setSearching(false)
    },
    500,
    [term, branch]
  )

  const onPopulate = async () => {
    setLoading(true)
    const errors = await dispatch(requestPopulatePlan(branchId))
    setLoading(false)
    if (errors) {
      alert(errors)
    } else {
      window.location.reload()
    }
  }

  if (loading) {
    return (
      <div className="flexible-container">
        <PlanFilter disabled={loading} />
        <div className="replenishment">
          <Splash isLoading />
        </div>
      </div>
    )
  }

  if (shouldRenderNewReplenishmentPlan) {
    return (
      <iframe
        className="replenishment_iframe"
        src={`/clients/${accountId}/replenishment-plan/${branchId}?embedded=true`}
      />
    )
  }

  return (
    <div className="flexible-container replenishment_container">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            searching={searching}
            placeholder="Search Product"
            value={term}
            onChange={e => {
              setTerm(e.target.value)
              if (term && !e.target.value) {
                setFoundProducts(null)
              }
            }}
          />
        </div>
        <div className="filter_col -fluid">
          <div className="pager">
            <button
              disabled={
                loading ||
                !requestedBranch ||
                !includes(year - 1, requestedBranch.plannedYears)
              }
              onClick={() => dispatch(setPlanYear(year - 1))}
              className="pager_button clear-button link"
            >
              <ArrowLeftIcon />
            </button>
            <div className="pager_title">{year}</div>
            <button
              disabled={
                loading ||
                !requestedBranch ||
                !includes(year + 1, requestedBranch.plannedYears)
              }
              onClick={() => dispatch(setPlanYear(year + 1))}
              className="pager_button clear-button link"
            >
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="replenishment">
        <div
          className={cn('replenishment_row -header', {
            '-empty': !isProducts,
          })}
          ref={monthsRef}
        >
          <div className="replenishment_col -product">
            <div style={{ width: 300 }} className="replenishment_title">
              Replenishment Plan
            </div>
          </div>
          {months.map(month => (
            <div className="replenishment_col" key={month.id}>
              <PlanMonth
                month={month}
                onSelect={() =>
                  selectedMonth === month
                    ? setSelectedMonth(undefined)
                    : setSelectedMonth(month)
                }
                isSelected={selectedMonth === month}
              />
            </div>
          ))}
        </div>

        <div
          className={`replenishment_body ${
            selectedMonth ? '-month-selected' : ''
          }`}
          onScroll={(e: any) => {
            if (
              e.target.classList.contains('js-replenishment-scroll-container')
            ) {
              if (monthsRef.current)
                monthsRef.current.scrollLeft = e.target.scrollLeft
              if (totalsRef.current)
                totalsRef.current.scrollLeft = e.target.scrollLeft
            }
          }}
        >
          {isProducts ? (
            <PlanCellActionsContext.Provider
              value={{ requestCreateOrderItem, requestUpdateOrderItem }}
            >
              <PlanTable
                listRef={listRef}
                products={foundProducts || products}
                plannedProductsDict={plannedProductsDict}
                selectedMonth={selectedMonth!}
              />
            </PlanCellActionsContext.Provider>
          ) : (
            <div className="replenishment-empty">
              <div className="replenishment-empty_title">
                {term ? 'Nothing Found' : 'Plan in empty'}
              </div>
              {!term && (
                <div className="replenishment-empty_message text-center">
                  Search products to add to the plan or
                  <br />
                  <br />
                  <button
                    disabled={loading}
                    onClick={() => onPopulate()}
                    className="list_action clear-button button -xs -primary"
                  >
                    Populate from Forecast
                  </button>
                </div>
              )}
              <BugIcon className="replenishment-empty_icon" />
            </div>
          )}
        </div>

        {selectedMonth && (
          <div className="form_controls -replenishment">
            <div className="form_controls-container">
              <PlanState state={selectedMonthState} />
              <Button
                type="button"
                onClick={() => {
                  history.push(
                    (isInternal
                      ? `/clients/${account.id}/branches/${branch.id}/orders/${selectedMonth.id}`
                      : `/orders/${selectedMonth.id}`) +
                      `?returnUrl=${window.location.pathname}`
                  )
                }}
                color="success"
                className="form_control -size"
              >
                Go To Order
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withAccountAndBranch(Plan)
