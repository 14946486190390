import React, { FC, memo, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import useThunkDispatch from '../../common/useThunkDispatch'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import Toggle from '../../components/Toggle'
import Input from '../../components/Input'
import { requestAccountUpdate } from '../accountsActions'
import { accountsDictSelector } from '../accountsSelectors'

interface IAccountSettingsRouteParams {
  accountId: string
}

const AccountSettings: FC<RouteComponentProps<IAccountSettingsRouteParams>> = ({
  match,
  history,
}) => {
  const accountId = Number(match.params.accountId)
  const dispatch = useThunkDispatch()
  const accountDict = useSelector(accountsDictSelector)
  const account = accountDict[accountId]

  const handleClose = useCallback(() => {
    history.push(`/clients/${accountId}`)
  }, [])

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const submit = async () => {
        setSubmitting(true)
        const error = await dispatch(
          requestAccountUpdate({ ...account, ...values })
        )
        if (!error) handleClose()
        else alert(error)
        setSubmitting(false)
      }

      submit()
    },
    [account]
  )

  return (
    <Modal onClose={handleClose} title="Account Info">
      <Formik
        initialValues={{
          name: account && account.name,
          orderApproveFlow: account && account.orderApproveFlow,
          live: account && account.live,
        }}
        enableReinitialize
        validate={values => {
          if (!values.name) return { name: 'Required' }
          return {}
        }}
        onSubmit={handleSubmit}
        render={({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form_group">
              <Input
                label="Title"
                value={values.name}
                disabled
                onChange={e => setFieldValue('name', e.target.value)}
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.live}
                onChange={() => setFieldValue('live', !values.live)}
                option={{
                  value: true,
                  label: 'Send orders to SANA',
                }}
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.orderApproveFlow}
                onChange={() =>
                  setFieldValue('orderApproveFlow', !values.orderApproveFlow)
                }
                option={{
                  value: true,
                  label: 'GM Approval flow',
                }}
              />
            </div>

            <div className="form_controls">
              <div className="form_controls-container">
                <Button
                  onClick={handleClose}
                  color="default-link"
                  className="form_control -size -right"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="success"
                  className="form_control -size"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default memo(AccountSettings)
