import {
  default as React,
  Fragment,
  FunctionComponent,
  HTMLAttributes,
  useContext,
  useState,
} from 'react'
import BasePopover from '../../components/BasePopover'
import useTimeoutOpen from '../../common/useTimeoutOpen'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
  isSubmittedOrder,
} from '../../types/orders'
import { getOr, sumBy } from 'lodash/fp'
import { format } from 'date-fns'
import useThunkDispatch from '../../common/useThunkDispatch'
import LoadingIcon from '../../icons/loading.svg'
import { PlanCellActionsContext } from '../Plan'
import useMonthInfo from '../../common/useMonthInfo'
import ResetForecastIcon from '../../icons/reset-forecast.svg'
import ShippedIcon from '../../icons/shipped.svg'

interface IOwnProps extends HTMLAttributes<HTMLDivElement> {
  inPlan: boolean
  editable: boolean
  month: IPlannedOrder
  forecast?: IBranchForecast
  shipments?: IBranchShipment[]
  replenish?: IPlannedOrderItem & { order: IPlannedOrder }
  fillins?: Array<IOrderItem & { order: IFillinOrder }>
}

const PlanTableCellPopup: FunctionComponent<IOwnProps> = ({
  inPlan,
  editable,
  month,
  forecast,
  shipments,
  replenish,
  fillins,
  hidden,
  ...props
}) => {
  const dispatch = useThunkDispatch()
  const [open, onOpen, onClose] = useTimeoutOpen(false)
  const { isPastMonth, isCurrentMonth, isFutureMonth } = useMonthInfo(month)
  const shippedAmount = sumBy('amount', shipments)
  const replenishRequested = getOr(null, 'amountRequested', replenish)
  const forecasted = getOr(0, 'amountSuggested', forecast)
  const submitted = isSubmittedOrder(month)
  const shipmentsList = shipments || []

  // all popup sections
  const sections = []

  const { requestUpdateOrderItem } = useContext(PlanCellActionsContext)
  const [resetting, setResetting] = useState(false)
  const onReset = async () => {
    if (replenish && forecast) {
      setResetting(true)
      await dispatch(
        requestUpdateOrderItem(replenish.order, {
          id: replenish.id,
          amountRequested: forecast.amountSuggested,
        })
      )
      setResetting(false)
    }
  }

  // planned explanation
  if ((isCurrentMonth || isPastMonth) && submitted && !!replenishRequested) {
    sections.push(
      <div className="replcut_info">
        <div className="replcut_info-text -center">
          <strong>
            {replenishRequested} units included in the
            <br />
            replenishment order
          </strong>
        </div>
      </div>
    )
  }

  // for future & current non submitted month have ability to reset to forecasted
  if (
    editable &&
    forecast &&
    replenish &&
    !submitted &&
    replenishRequested !== forecasted &&
    (isFutureMonth || (isCurrentMonth && !submitted))
  ) {
    sections.push(
      <button
        className="replcut_reset replcut_info clear-button"
        onClick={onReset}
        disabled={resetting}
      >
        {resetting ? <LoadingIcon /> : <ResetForecastIcon />}
        <span className="replcut_info-text">
          <strong>Reset to forecasted</strong>
          {forecasted} items forecasted
        </span>
      </button>
    )
  }

  // for past & current month show shipped amounts
  if (isPastMonth || isCurrentMonth) {
    const actualCount =
      shipmentsList.length > 0 && shippedAmount > 0
        ? `${shippedAmount} shipped`
        : ''

    if (sections.length) sections.push(<hr className="replcut_hr" />)

    if (actualCount) {
      sections.push(
        <div className="replcut_info -center">
          <ShippedIcon />
          <div className="replcut_info-text">
            <strong>{actualCount}</strong>
          </div>
        </div>
      )
      sections.push(
        <ul className="replcut_list">
          {shipmentsList.map(item => (
            <li key={item.id}>
              <dl>
                <dt>{format(item.date, 'MMM D')}</dt>
                <dd>{item.amount}</dd>
              </dl>
            </li>
          ))}
        </ul>
      )
    } else {
      sections.push(
        <div className="replcut_info -placeholder">
          There were no shipments
          <br />
          in this month
        </div>
      )
    }
  }

  return (
    <BasePopover
      preferPlace="below"
      isOpen={!hidden && open && sections.length > 0}
      className="replcut -notice -auto"
      body={
        <div
          className="replcut_body"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          {sections.map((sec, pos) => (
            <Fragment key={pos}>{sec}</Fragment>
          ))}
        </div>
      }
    >
      <div onMouseEnter={onOpen} onMouseLeave={onClose} {...props} />
    </BasePopover>
  )
}

export default PlanTableCellPopup
