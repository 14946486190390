import React, { FC, useEffect, useState, useMemo, useRef } from 'react'
import cn from 'classnames'
import { findIndex, update, assign, __, find, reject } from 'lodash/fp'
import { useSelector } from 'react-redux'
import useThunkDispatch from '../../common/useThunkDispatch'
import { IBranch, IUser, IAccount } from '../../types/user'
import { IHash } from '../../types/common'
import Splash from '../../components/Splash'
import Search from '../../components/Search'
import Button from '../../components/Button'
import GridLayout from '../../components/GridLayout'
import ActionButton from '../../components/ActionButton'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import useClientTitle from '../../common/useClientTitle'
import { useActionError } from './UserActionError'
import UserCard from './UserCard'
import UserInfo from './UserInfo'
import ManagerInfo from './ManagerInfo'
import {
  ISearch,
  requestUsers,
  requestTechniticianUpsert,
  requestTechniticianDelete,
  requestTechniticianPasswordChange,
  requestTechnicianMove,
  requestManagerUpdate,
} from './usersActions'
import { currentUserSelector } from '../../auth/authSelectors'
import { SimpleIndex } from '../../common/simpleSearch'
import { sortBy } from 'lodash'

interface IFilter {
  title: string
  key: string
  options: Array<{
    key: string
    label: string
    className?: string | undefined
  }>
}

interface IRouteParams {
  accountId?: string
  branchId?: string
}

const makeFilters = (list: ISearch): IFilter[] => [
  {
    title: 'Users',
    key: 'users',
    options: [
      {
        key: 'all',
        label: 'All',
        className: '-separated',
      },
      {
        key: 'managers',
        label: 'Managers',
      },
      {
        key: 'technicians',
        label: 'Technicians',
      },
      {
        key: 'inactive',
        label: 'Inactive Users',
        className: '-separated',
      },
    ],
  },
  {
    title: 'Teams',
    key: 'teams',
    options: [
      ...list.teams.map((d, idx) => ({
        key: `${d.name}-${d.id}`,
        label: d.name,
        className: idx === 0 ? '-separated' : undefined,
      })),
      {
        key: 'no-teams',
        label: 'No Teams',
        className: '-separated',
      },
    ],
  },
  {
    title: 'Divisions',
    key: 'divisions',
    options: [
      ...list.divisions.map((d, idx) => ({
        key: `${d.name}-${d.id}`,
        label: d.name,
        className: idx === 0 ? '-separated' : undefined,
      })),
      {
        key: 'no-divisions',
        label: 'No Divisions',
        className: '-separated',
      },
    ],
  },
]

const Users: FC<{ branch: IBranch; account?: IAccount }> = ({
  branch,
  account,
}) => {
  const dispatch = useThunkDispatch()
  const [visibleKeys, setVisibleKeys] = useState<string[]>([
    'users',
    'divisions',
    'teams',
  ])
  const currentUser = useSelector(currentUserSelector)
  const [errors, setErrors] = useState<IHash<string>>({})
  const [filters, setFilters] = useState<IFilter[]>([])
  const [list, setList] = useState<ISearch | undefined>(undefined)
  const [term, setTerm] = useState<string | undefined>(undefined)
  const [user, selectUser] = useState<IUser | undefined>(undefined)
  const [filter, setFilter] = useState<string>('all')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [{ open: showError }, actionError] = useActionError()
  const usersList = useRef<HTMLInputElement>(null)
  const canEditManager =
    currentUser.role === 'internal' || currentUser.generalManager

  // common
  useClientTitle({ branch, account })

  // search
  const usersIndex = useMemo(
    () =>
      new SimpleIndex<IUser>(list ? list.users.all : [], {
        keys: ['name', 'email'],
      }),
    [list]
  )

  const users = term
    ? usersIndex.search(term || '')
    : list
    ? list.users[filter]
    : []

  // scroll
  const scrollTop = () => {
    if (!usersList.current || window.scrollY < 100) return
    usersList.current.scrollIntoView({ behavior: 'smooth' })
  }

  const loadUsers = async (branch: IBranch, term?: string) => {
    const list = await dispatch(
      requestUsers(branch.id, term, account && account.id)
    )
    setList(list)
    setFilters(makeFilters(list))
  }

  const refresh = async (branch?: IBranch, term?: string) => {
    if (!branch) return
    await loadUsers(branch, term)
  }

  const handleTechicianAdd = () => {
    selectUser({
      active: true,
      name: '',
      email: '',
      checkoutManager: false,
    } as IUser)
    setErrors({})
  }

  useEffect(() => {
    setErrors({})
    if (list) {
      const updatedUser = user
        ? find({ id: user.id }, list.users.all)
        : undefined
      selectUser(updatedUser)
      scrollTop()
    }
  }, [list, filter, term])

  useEffect(() => {
    if (!!term) {
      setFilter('all')
    }
  }, [term])

  useEffect(() => {
    if (branch) {
      loadUsers(branch)
    }
  }, [branch])

  const handleSave = async (user: IUser) => {
    if (branch) {
      setSubmitting(true)
      const { result, errors } = await dispatch(
        requestTechniticianUpsert(user, branch, account && account.id)
      )

      if (errors) {
        setErrors(errors)
        scrollTop()
      } else {
        if (!user.id) {
          selectUser(result)
        }
        setErrors({})
        await refresh(branch, term)
      }
      setSubmitting(false)
    }
  }

  const handleDelete = async (user: IUser) => {
    if (branch) {
      setSubmitting(true)
      const errors = await dispatch(requestTechniticianDelete(user))
      setSubmitting(false)

      if (errors) {
        showError({
          title: 'Delete Technician',
          heading: 'We found open Requests',
          text:
            'You need to complete all user requests in mobile app to move user to another branch',
        })
        setErrors(errors)
        scrollTop()
      } else {
        setErrors({})
        refresh(branch, term)
      }
    }
  }

  const handlePasswordUpdate = (user: IUser, password: string) => {
    dispatch(requestTechniticianPasswordChange(user, password))
  }

  const handleBranchUpdate = async (user: IUser, newBranch: IBranch) => {
    const errors = await dispatch(requestTechnicianMove(user, newBranch))

    if (errors) {
      showError({
        title: 'Move to branch',
        heading: 'We found open Requests',
        text:
          'You need to complete all user requests in mobile app to move user to another branch',
      })
      scrollTop()
    } else {
      refresh(branch, term)
    }
  }

  const handleUserSelect = (user: IUser) => {
    if (!user.divisions && !user.teams && !canEditManager) return
    selectUser(user)
    setErrors({})
  }

  const handleUserUpdate = (user: IUser) => {
    const listUserIndex = findIndex(
      u => u.id === user.id,
      (list && list.users[filter]) || []
    )
    setList(
      update(`users.${filter}[${listUserIndex}]`, assign(__, user), list || [])
    )
  }

  const handleManagerSave = async (user: IUser) => {
    if (branch) {
      setSubmitting(true)
      const { errors } = await dispatch(requestManagerUpdate(branch, user))
      setSubmitting(false)

      if (errors) {
        alert(Object.values(errors).join('. '))
        scrollTop()
      } else {
        setErrors({})
        handleUserUpdate(user)
      }
    }
  }

  if (!branch || !list) return <Splash isLoading={true} />

  return (
    <div ref={usersList} className="list">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            placeholder="Search by user name or email"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
        </div>
        <div className="filter_sep" />
        <div className="filter_col">
          <Button type="submit" caliber="sm" onClick={handleTechicianAdd}>
            Add Technician User
          </Button>
        </div>
      </div>
      <GridLayout className="list_grid" offset={185}>
        <div>
          {filters.map(f => (
            <div key={f.title} className="list_filter">
              <div className="list_filter-title">
                {f.title}
                &nbsp;
                {visibleKeys.includes(f.key) ? (
                  <ActionButton
                    action="remove"
                    onClick={() =>
                      setVisibleKeys(reject(k => k === f.key, visibleKeys))
                    }
                  />
                ) : (
                  <ActionButton
                    action="add"
                    onClick={() => setVisibleKeys([...visibleKeys, f.key])}
                  />
                )}
              </div>
              {visibleKeys.includes(f.key) &&
                f.options.map(option => (
                  <div
                    key={option.key}
                    className={cn('list_filter-row', option.className)}
                    onClick={() => {
                      setTerm(undefined)
                      setFilter(option.key)
                    }}
                  >
                    <span
                      className={cn('list_filter-value', {
                        '-selected': option.key === filter,
                      })}
                    >
                      {option.label}
                    </span>
                    {(list.users[option.key] || []).length}
                  </div>
                ))}
            </div>
          ))}
        </div>
        <div>
          <div className="list_items">
            {!users.length && (
              <div className="list_placeholder">No users found</div>
            )}
            {sortBy(users, u => u.name.toLowerCase()).map(u => (
              <UserCard
                key={u.id}
                className={cn({ '-selected': user && user.id === u.id })}
                user={u}
                onClick={handleUserSelect}
              />
            ))}
          </div>
        </div>
        <div>
          {!user && filter !== 'managers' && (
            <div className="list_placeholder">Select a user for details</div>
          )}
          {user && (user.role === 'technician' || !user.id) && (
            <UserInfo
              user={user}
              account={account}
              branch={branch}
              divisions={list.divisions}
              teams={list.teams}
              errors={errors}
              loading={submitting}
              onSave={handleSave}
              onDelete={handleDelete}
              onCancel={() => {
                setErrors({})
                selectUser(undefined)
              }}
              onPasswordUpdate={handlePasswordUpdate}
              onBranchUpdate={handleBranchUpdate}
            />
          )}
          {user && user.role === 'manager' && (
            <ManagerInfo
              user={user}
              loading={submitting}
              onSave={handleManagerSave}
              teams={list.teams}
            />
          )}
        </div>
      </GridLayout>
      {actionError}
    </div>
  )
}

export default withAccountAndBranch(Users)
