import { get, omit, flow, map, sortBy } from 'lodash/fp'
import { ThunkAction } from '../../types/common'
import { accountsDictSelector } from '../../accounts/accountsSelectors'
import { query } from '../../common/api'
import { IBranch, IUser, IDivision, ITeam, ICheckout } from '../../types/user'
import {
  CREATE_TECHNICIAN_QUERY,
  UPDATE_TECHNICIAN_QUERY,
  REMOVE_TECHNICIAN_QUERY,
} from '../../accounts/accountsQueries'
import { currentUserSelector } from '../../auth/authSelectors'
import {
  USERS_QUERY,
  MOVE_USER_QUERY,
  INTERNAL_USERS_QUERY,
  UPDATE_MANAGER_QUERY,
  INTERNAL_USER_CHECKOUTS_QUERY,
  USER_CHECKOUTS_QUERY,
} from './usersQueries'

export interface ISearchUser {
  [key: string]: IUser[]
}

export interface ISearch {
  users: ISearchUser
  divisions: IDivision[]
  teams: ITeam[]
}

export const requestCheckouts = async (
  userId: number,
  branchId: number,
  accountId?: number
): Promise<ICheckout[]> => {
  const isInternal = accountId != null
  const response = isInternal
    ? await query(INTERNAL_USER_CHECKOUTS_QUERY, {
        accountId,
        branchId,
        userId,
        status: 'completed',
      })
    : await query(USER_CHECKOUTS_QUERY, {
        branchId,
        userId,
        status: 'completed',
      })
  const checkouts = isInternal
    ? get(
        'internal.accounts.nodes[0].currentBranch[0].technicians[0].checkouts',
        response
      )
    : get('manager.currentBranch[0].technicians[0].checkouts', response)

  return checkouts
}

export const requestUsers = (
  branchId: number,
  search?: string,
  accountId?: number
): ThunkAction<Promise<ISearch>> => async (dispatch, getState) => {
  const isInternal = accountId != null
  const response = isInternal
    ? await query(INTERNAL_USERS_QUERY, {
        accountId,
        branchId,
        search: (search && search) || null,
      })
    : await query(USERS_QUERY, {
        branchId,
        search: (search && search) || null,
      })
  const users = isInternal
    ? get('internal.accounts.nodes[0].currentBranch[0]', response)
    : get('manager.currentBranch[0]', response)
  const divisions = flow(
    isInternal
      ? get('internal.accounts.nodes[0].currentBranch[0].divisions')
      : get('manager.currentBranch[0].divisions'),
    sortBy<IDivision>('name')
  )(response) as IDivision[]
  const teams = flow(
    isInternal
      ? get('internal.accounts.nodes[0].currentBranch[0].teams')
      : get('manager.currentBranch[0].teams'),
    sortBy<ITeam>('name')
  )(response) as ITeam[]

  const techinitians = get('technicians', users) as IUser[]
  const managers = get('managers', users) as IUser[]
  const allUsers = [...techinitians, ...managers]
  const divisionGroups: { [key: string]: IUser[] } = divisions.reduce(
    (acct, division) => {
      const gr = techinitians.filter(t =>
        (t.divisions || [])
          .map((d: { id?: number }) => d.id)
          .includes(division.id)
      )
      return {
        ...acct,
        [`${division.name}-${division.id}`]: gr,
      }
    },
    { techinitians }
  )

  divisionGroups['no-divisions'] = techinitians.filter(
    t => !(t.divisions || []).length
  )

  const teamGroups: { [key: string]: IUser[] } = teams.reduce(
    (acct, team) => {
      const gr = allUsers.filter(t =>
        (t.teams || []).map((d: { id?: number }) => d.id).includes(team.id)
      )
      return {
        ...acct,
        [`${team.name}-${team.id}`]: gr,
      }
    },
    { allUsers }
  )

  teamGroups['no-teams'] = allUsers.filter(t => !(t.teams || []).length)

  const list = {
    all: allUsers,
    ...users,
    ...divisionGroups,
    ...teamGroups,
  }

  return {
    users: list,
    divisions,
    teams,
  }
}

export const requestTechniticianUpsert = (
  user: IUser,
  branch: IBranch,
  accountId?: number
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState())
  const { id, divisions, teams, ...userAttributes } = omit(
    [
      'checkouts',
      'password',
      'isRemovable',
      'isMovable',
      'lastSignInAt',
      'updatedAt',
      'role',
    ],
    user
  )
  const branchIds = [branch.id]
  const divisionIds = map('id', divisions)
  const teamIds = map('id', teams)
  const params = { ...userAttributes, branchIds, divisionIds, teamIds }
  const response = id
    ? get(
        'technicianUpdate',
        await query(UPDATE_TECHNICIAN_QUERY, { id, attributes: params })
      )
    : get(
        'technicianCreate',
        await query(CREATE_TECHNICIAN_QUERY, {
          attributes: {
            ...params,
            password: user.password,
            passwordConfirmation: user.password,
            accountId: accountId || currentUser.account!.id,
          },
        })
      )

  return response
}

export const requestTechniticianDelete = (
  user: IUser
): ThunkAction<Promise<any>> => async dispatch => {
  const response = get(
    'technicianRemove',
    await query(REMOVE_TECHNICIAN_QUERY, { id: user.id })
  )
  return response.errors
}

export const requestTechniticianPasswordChange = (
  user: IUser,
  password: string
): ThunkAction<Promise<any>> => async dispatch => {
  const response = get(
    'technicianUpdate',
    await query(UPDATE_TECHNICIAN_QUERY, {
      id: user.id,
      attributes: { password, passwordConfirmation: password },
    })
  )
  return response.errors
}

export const requestTechnicianMove = (
  user: IUser,
  branch: IBranch
): ThunkAction<Promise<any>> => async dispatch => {
  const response = get(
    'technicianMove',
    await query(MOVE_USER_QUERY, {
      id: user.id,
      branchIds: [branch.id],
    })
  )
  return response.errors
}

export const requestManagerUpdate = (
  branch: IBranch,
  user: IUser
): ThunkAction<Promise<any>> => async (dispatch, getState) => {
  const teamIds = map('id', user.teams)

  const response = get(
    'managerUpdate',
    await query(UPDATE_MANAGER_QUERY, {
      id: user.id,
      branchId: branch.id,
      attributes: {
        generalManager: user.generalManager,
        checkoutManager: user.checkoutManager,
        teamIds,
      },
    })
  )
  return response
}
