export const USERS_QUERY = `
{
  internal {
    internals {
      id
      name
      avatar
      email
      lastSignInAt
    }
  }
}
`

export const SCHEDULE_USERS_SYNC_QUERY = `
mutation ScheduleUsersUpdate($accountId: Int!) {
  scheduleUsersUpdate(accountId: $accountId) {
    errors
  }
}
`
