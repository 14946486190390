import { handleActions } from 'redux-actions'
import { set, flow } from 'lodash/fp'
import {
  SET_MANAGER_BRANCHES,
  SET_MANAGER_ACCOUNT_BRANCHES,
  SET_MANAGER_BRANCH,
  SET_MANAGER_BI_LINKS,
  SET_MANAGER_DIVISIONS,
} from './managerActions'
import { IBranch, IBILink } from '../types/user'

const DEFAULT_STATE = {
  branches: [] as IBranch[],
  biLinks: [] as IBILink[],
  accountBranches: [] as IBranch[],
  branch: undefined as IBranch | undefined,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [SET_MANAGER_BRANCHES]: (
      state: any,
      { payload: { branches, branch } }: SET_MANAGER_BRANCHES
    ) =>
      flow<any, any, any>(
        set('branches', branches),
        set('branch', branch || branches[0])
      )(state),
    [SET_MANAGER_BRANCH]: (
      state: any,
      { payload: { branch } }: SET_MANAGER_BRANCH
    ) => set('branch', branch, state),
    [SET_MANAGER_DIVISIONS]: (state, { payload }: SET_MANAGER_DIVISIONS) =>
      set('divisions', payload, state),
    [SET_MANAGER_ACCOUNT_BRANCHES]: (
      state: any,
      { payload: { branches } }: SET_MANAGER_ACCOUNT_BRANCHES
    ) => set('accountBranches', branches, state),
    [SET_MANAGER_BI_LINKS]: (
      state: any,
      { payload: { links } }: SET_MANAGER_BI_LINKS
    ) => set('biLinks', links, state),
  },
  DEFAULT_STATE
)
