import { default as React, FunctionComponent, useState } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import OrderDetails from './OrderDetails'

interface IRouteParams {
  id: string
}

const Order: FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const orderId = Number(match.params.id)
  const [order, setOrder] = useState<Number | undefined>(1)

  if (!order) return <Redirect to="/orders" />

  return <OrderDetails orderId={orderId} onClose={() => setOrder(undefined)} />
}

export default React.memo(Order)
