import { createAction } from 'redux-actions'
import { get, identity } from 'lodash/fp'
import { Action, ThunkAction } from '../types/common'
import { query } from '../common/api'
import { SCHEDULE_USERS_SYNC_QUERY, USERS_QUERY } from './usersQueries'
import { usersSearchIndexSelector, usersSelector } from './usersSelectors'
import { IUser } from '../types/user'
import extractErrors from '../common/extractErrors'

export const SET_USERS = 'USERS/SET_USERS'
export type SET_USERS = Action<IUser[]>
export const setUsers = createAction<IUser[], IUser[]>(SET_USERS, identity)

export const requestUsers = (): ThunkAction<
  Promise<void>
> => async dispatch => {
  const users = get('internal.internals', await query(USERS_QUERY))
  dispatch(setUsers(users))
}

export const searchUsers = (
  term: string
): ThunkAction<Promise<IUser[]>> => async (dispatch, getState) => {
  const users = usersSelector(getState())
  if (users.length === 0) await dispatch(requestUsers())

  if (term) {
    const usersIndex = usersSearchIndexSelector(getState())
    return usersIndex.search(term)
  } else {
    return usersSelector(getState())
  }
}

export const requestScheduleUsersSync = (
  accountId: number
): ThunkAction<Promise<void | string>> => async dispatch => {
  const { errors } = get(
    'scheduleUsersUpdate',
    await query(SCHEDULE_USERS_SYNC_QUERY, { accountId })
  )
  if (errors) return extractErrors(errors)
}
