import { get, identity } from 'lodash/fp'

import {
  Action,
  IPaginationReq,
  IPaginationState,
  ThunkAction,
} from '../types/common'
import { query } from '../common/api'
import { FIND_ORDER_QUERY, ORDER_FILTER } from './ordersQueries'
import { ICursoryOrder, IDetailsOrder, IOrder } from '../types/orders'
import { createAction } from 'redux-actions'

export const SET_ORDERS_PAGINATION_STATE = 'ORDERS/SET_PAGINATION_STATE'
export type SET_ORDERS_PAGINATION_STATE = Action<IPaginationState>
export type SetPaginationState = (paginationState: IPaginationState) => void
export const setPaginationState = createAction<
  IPaginationState,
  IPaginationState
>(SET_ORDERS_PAGINATION_STATE, identity)

export const SET_ORDERS_FILTER = 'ORDERS/SET_ORDERS_FILTER'
export type SET_ORDERS_FILTER = Action<IOrdersFilter>
export type SetOrdersFilter = (ordersFilter: IOrdersFilter) => void
export const setOrdersFilter = createAction<IOrdersFilter, IOrdersFilter>(
  SET_ORDERS_FILTER,
  identity,
)

export const LOAD_ORDERS = 'ORDERS/LOAD_ORDERS'
export type LOAD_ORDERS = Action<ICursoryOrder[]>
export type LoadOrders = (orders: ICursoryOrder[]) => void
export const loadOrders = createAction<ICursoryOrder[], ICursoryOrder[]>(
  LOAD_ORDERS,
  identity,
)

export interface IOrdersFilter {
  accountId?: number
  branchId?: number
  scope: 'submitted' | 'upcoming'
}
export type FilterOrdersReq = IPaginationReq & IOrdersFilter
export type FilterOrders = (req: FilterOrdersReq) => Promise<void>
export const filterOrders = (
  req: FilterOrdersReq,
): ThunkAction<Promise<void>> => async dispatch => {
  const response = await query(ORDER_FILTER, req)

  const orders: ICursoryOrder[] = get('internal.orderFilter.nodes', response)
  const pageInfo: IPaginationState = get(
    'internal.orderFilter.pageInfo',
    response,
  )

  dispatch(setPaginationState(pageInfo))
  dispatch(loadOrders(orders))
}

export const requestDetailsOrder = (
  orderId: number,
): ThunkAction<Promise<IDetailsOrder>> => async dispatch => {
  const response = await query(FIND_ORDER_QUERY, { orderId })
  return get('internal.orderFind', response)
}
