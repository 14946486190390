import React, { FC, useEffect, memo, useCallback, useState } from 'react'
import cn from 'classnames'

import Button from '../../components/Button'
import Select from '../../components/Select'
import Input from '../../components/Input'
import { IProduct } from '../../types/products'
import { IAccount, IBranch, IDivision } from '../../types/user'
import { IHash } from '../../types/common'
import Toggle from '../../components/Toggle'
import GearSmall from '../../icons/gear_small.svg'
import { useRepackingModal } from './RepackingModal'
import Transfer from '../../icons/transfer.svg'
import ProductTransfer from './ProductTransfer'
import Tooltip from '../../components/Tooltip'

interface IProductFormProps {
  product: IProduct
  account?: IAccount
  divisions: IDivision[]
  distributors: string[]
  branch: IBranch
  loading: boolean
  errors: IHash<string>
  onSave: (product: IProduct) => void
  onRefetch: () => void
  onRemove: (product: IProduct) => void
  onCancel: () => void
}

const ProductForm: FC<IProductFormProps> = ({
  product: initialProduct,
  branch,
  account,
  distributors,
  loading,
  errors,
  onSave,
  onRefetch,
  onRemove,
  onCancel,
}) => {
  const [product, setProduct] = useState<IProduct>({ ...initialProduct })
  const [price, setPrice] = useState<string>(
    (initialProduct.price || '').toString()
  )
  const [moveVisible, setMoveVisible] = useState<boolean>(false)

  useEffect(() => {
    setProduct({ ...initialProduct })
    setPrice((initialProduct.price || '').toString())
  }, [initialProduct])

  const isCustom = product.custom && !product.originalProductData

  const handleNotifyThresholdChange = useCallback(
    notifyThreshold => setProduct({ ...product, notifyThreshold }),
    [product]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setProduct({ ...product })

      onSave({ ...product })
    },
    [product]
  )
  const distributorsOptions = distributors.map(d => ({ label: d, value: d }))
  const isNew = !product.id

  const handleChangeRepacking = (product: IProduct) => {
    setProduct({ ...product })
    onSave({ ...product })
  }

  const handleChangeCurrentStock = (product: IProduct, value: string) => {
    const newValue = parseInt(value, 10)
    setProduct({
      ...product,
      inventory: !!newValue || newValue === 0 ? newValue : undefined,
    })
  }

  // repacking modal
  const [{ open: handleRepackingModal }, repackingModal] = useRepackingModal({
    product,
    errors,
    onChange: handleChangeRepacking,
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className="product-info_details">
        <div className={cn('product-info_item', { '-sku': isCustom })}>
          {isCustom ? (
            <Input
              label="Product Id (Sku)"
              error={errors.code}
              value={product.code}
              disabled={!isNew}
              onChange={e => setProduct({ ...product, code: e.target.value })}
            />
          ) : (
            <>
              <div className="product-info_label">Product Id (Sku)</div>
              <div className="product-info_value">{product.code}</div>
            </>
          )}
        </div>
        <div className={cn('product-info_item', { '-name': isCustom })}>
          {isCustom ? (
            <Input
              label="Name"
              error={errors.name}
              value={product.name}
              onChange={e => setProduct({ ...product, name: e.target.value })}
            />
          ) : (
            <>
              <div className="product-info_label">Name</div>
              <div className="product-info_value">{product.name}</div>
            </>
          )}
        </div>
        <div className="product-info_item -full-width">
          {isCustom ? (
            <Select
              creatable
              isClearable
              label="Distributor Name"
              value={
                product.distributor
                  ? distributorsOptions.find(
                      d => d.value === product.distributor
                    )
                  : undefined
              }
              options={distributorsOptions}
              onChange={(value: any) => {
                setProduct({ ...product, distributor: value && value.value })
              }}
            />
          ) : (
            <>
              <div className="product-info_label">Distributed By</div>
              <div className="product-info_value">
                {(isCustom && (product.distributor || 'n/a')) || 'Forshaw'}
              </div>
            </>
          )}
        </div>
      </div>
      {isCustom && (
        <div className="product-info_repacking -horizontal">
          <div className="product-info_pricing">
            <Input
              label="Unit price, $"
              error={errors.price}
              value={price}
              onKeyPress={evt => {
                const charCode = evt.which ? evt.which : evt.keyCode
                const value = (price || '').toString()
                const dotcontains = value.indexOf('.') !== -1
                if (dotcontains)
                  if (charCode === 46) return evt.preventDefault()
                if (charCode === 46) return true
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                  return evt.preventDefault()
                return true
              }}
              onChange={e => {
                const value = e.target.value
                setPrice(value)
                setProduct({ ...product, price: +value })
              }}
            />
          </div>

          <div className="product-info_pricing">
            <Input
              label="Unit of measure"
              error={errors.unit}
              value={product.unit}
              onChange={e => setProduct({ ...product, unit: e.target.value })}
            />
          </div>

          <div className="product-info_pricing product-info_current -with-transfer">
            <Input
              label="Current stock"
              error={errors.inventory}
              value={product.inventory}
              onChange={e => handleChangeCurrentStock(product, e.target.value)}
            />
            <Tooltip id="tooltip-transfer">
              <div
                className="input_label-tooltip"
                data-tip="Transfer to another branch"
                data-for="tooltip-transfer"
                data-effect="solid"
                data-place="bottom"
              >
                <Button
                  type="button"
                  caliber="sm"
                  disabled={!product.id}
                  className="list_action"
                  // disabled={!product.inventory || product.inventory <= 0}
                  onClick={() => setMoveVisible(true)}
                >
                  <Transfer />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      {!isCustom && (
        <div>
          <div className="product-info_current -with-transfer">
            <Input
              label="Current stock"
              error={errors.inventory}
              value={product.inventory}
              onChange={e => handleChangeCurrentStock(product, e.target.value)}
            />
            <Tooltip id="tooltip-transfer">
              <div
                className="input_label-tooltip"
                data-tip="Transfer to another branch"
                data-for="tooltip-transfer"
                data-effect="solid"
                data-place="bottom"
              >
                <Button
                  type="button"
                  caliber="sm"
                  className="list_action"
                  // disabled={!product.inventory || product.inventory <= 0}
                  onClick={() => setMoveVisible(true)}
                >
                  <Transfer />
                </Button>
              </div>
            </Tooltip>
          </div>
          <div className="product-info_repacking_details">
            <div className="product-info_repacking_lines">
              {product.quantity !== undefined && product.quantity > 1 && (
                <div className="product-info_repacking_result">
                  Repacked: ${product.price} / {product.unit}
                </div>
              )}
              {product.originalProductData ? (
                <div className="product-info_repacking_source">
                  Shipped: ${product.originalProductData.price} /{' '}
                  {product.originalProductData.unit}
                </div>
              ) : (
                <div className="product-info_repacking_source">
                  Shipped: ${product.price} / {product.unit}
                </div>
              )}
            </div>
            <div
              className="product-info_repacking_link"
              onClick={() => handleRepackingModal()}
            >
              <GearSmall />
              Repackaging settings
            </div>
          </div>
          {repackingModal}
        </div>
      )}
      {!isNew && (
        <>
          <div className="product-info_box">
            <Toggle
              value={product.notifyThreshold}
              onChange={() =>
                handleNotifyThresholdChange(!product.notifyThreshold)
              }
              option={{
                value: true,
                label: 'Notify me when low stock',
              }}
            />

            {product.notifyThreshold && (
              <div style={{ width: '100%' }}>
                <Input
                  label="Threshold value"
                  value={product.threshold}
                  error={errors.threshold}
                  onChange={e => {
                    const threshold = parseInt(
                      e.target.value.replace(/\D/g, '') || '0',
                      10
                    )
                    setProduct({ ...product, threshold })
                  }}
                />
              </div>
            )}
          </div>
          <br />
        </>
      )}
      <div className="product-info_section -footer">
        <Button
          color="success"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>
        <div className="product-info_cancel" onClick={onCancel}>
          Cancel
        </div>
        {!isNew && (
          <div
            className="product-info_archive"
            onClick={() => !loading && onRemove(product)}
          >
            Remove Item
          </div>
        )}
      </div>

      <ProductTransfer
        account={account}
        branch={branch}
        opened={moveVisible}
        product={product}
        onRefetch={onRefetch}
        onClose={() => setMoveVisible(false)}
      />
    </form>
  )
}

export default memo(ProductForm)
