import { IOrder } from '../types/orders'
import { forEach } from 'lodash/fp'

type SortMap = {
  [productId: number]: Date
}

export default function ordersToProductsSortMap(orders: IOrder[]): SortMap {
  const sortMap: SortMap = {}

  forEach(
    (order) =>
      forEach((orderItem) => {
        const updatedAt: Date = new Date(orderItem.updatedAt)

        if (!sortMap[orderItem.product.id] || sortMap[orderItem.product.id] < updatedAt) {
          sortMap[orderItem.product.id] = updatedAt
        }
      }, order.orderItems),
    orders
  )

  return sortMap
}
