import { default as React, ReactElement } from 'react'
import { uniqueId } from 'lodash'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Props as SelectProps } from 'react-select/base'

export interface IOwnProps<T> extends SelectProps<{ label: string; value: T }> {
  id?: string
  label?: string
  error?: string | boolean
  creatable?: boolean
}

const Select: <T = string>(p: IOwnProps<T>) => ReactElement<IOwnProps<T>> = ({
  id = uniqueId(),
  label,
  error,
  creatable = false,
  ...props
}) => {
  return (
    <div className="select">
      <label htmlFor={id} className="select_label">
        {label}
      </label>
      {!creatable && <ReactSelect classNamePrefix="select" {...props} />}
      {creatable && <CreatableSelect classNamePrefix="select" {...props} />}
      {error && <div className="select_error">{error}</div>}
    </div>
  )
}

export default Select
