import * as React from 'react'
import { Link } from 'react-router-dom'
import Splash from '../components/Splash'
import LogoFullIcon from '../icons/logo-full.svg'

const LOGO = require('../common/header/logo.png')

interface IProps {
  isLoading?: boolean
}

const AuthLayout: React.FunctionComponent<IProps> = ({ children, isLoading }) => (
  <Splash className="auth-wrapper" isLoading={isLoading}>
    <Link to="/" className="auth-wrapper_logo">
      <LogoFullIcon />
    </Link>
    <div className="auth-wrapper_form">
      {children}
    </div>
  </Splash>
)

export default AuthLayout
