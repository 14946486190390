import * as React from 'react'
import cn from 'classnames'
import MinusIcon from './minus.svg'
import MinusHoverIcon from './minus_hover.svg'
import PlusIcon from './plus.svg'
import PlusHoverIcon from './plus_hover.svg'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  action: 'remove' | 'add'
}

const ActionButton: React.FunctionComponent<IProps> = ({ action, ...props }) => {
  if (action === 'remove')
    return (
      <button type="button" className="action-button -delete clear-button link" {...props}>
        <MinusIcon />
        <MinusHoverIcon />
      </button>
    )

  if (action === 'add')
    return (
      <button type="button" className="action-button -delete clear-button link" {...props}>
        <PlusIcon />
        <PlusHoverIcon />
      </button>
    )

  return null
}

export default ActionButton

interface IWrapperProps {
  showAlways?: boolean
}
export const ActionButtonWrapper: React.FunctionComponent<React.HTMLProps<HTMLDivElement> & IWrapperProps> = ({
  className,
  showAlways,
  ...props
}) => <div className={cn('action-button_wrapper', className, { '-always': showAlways })} {...props} />
