import { createAction } from 'redux-actions'
import { batch } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { get, identity } from 'lodash/fp'

import { Action, IHash, IMeta, ThunkAction } from '../types/common'
import {
  IUser,
  STORAGE_USER_KEY,
  STORAGE_USER_TOKEN_KEY,
  UserRole,
} from '../types/user'
import { CURRENT_USER_QUERY } from './authQueries'
import { query } from '../common/api'
import history from '../history'

export const SET_TOKEN = 'AUTH/SET_TOKEN'
export type SET_TOKEN = Action<string>
export const setToken = createAction<string, string>(SET_TOKEN, identity)

export const SET_USER = 'AUTH/SET_USER'
export type SET_USER = Action<string>
export const setUser = createAction<IUser, IUser>(SET_USER, identity)

export const SET_META = 'AUTH/SET_META'
export type SET_META = Action<string>
export const setMeta = createAction<IMeta, IMeta>(SET_META, identity)

export const CLEAR_STATE = 'CLEAR_STATE'
export type CLEAR_STATE = Action<null>
export const clearState = (): ThunkAction<void> => dispatch => {
  localStorage.removeItem(STORAGE_USER_TOKEN_KEY)
  localStorage.removeItem(STORAGE_USER_KEY)

  dispatch({ type: CLEAR_STATE, payload: null })

  history.push('/login')
}

export const requestLogOut = (): ThunkAction<void> => async dispatch => {
  const token = localStorage.getItem(STORAGE_USER_TOKEN_KEY)

  try {
    await fetch('/auth/logout', {
      method: 'DELETE',
      body: JSON.stringify({ logout_type: 'web' }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  } finally {
    localStorage.removeItem(STORAGE_USER_TOKEN_KEY)
    dispatch(clearState())
  }
}

export const requestLogin = (
  email: string,
  password: string
): ThunkAction<Promise<string | undefined>> => async dispatch => {
  const matchedEmail = email.match(/(.+)@.+/)
  if (!matchedEmail) return 'Invalid email'

  try {
    const response = await fetch('/auth/login', {
      method: 'POST',
      body: JSON.stringify({ user: { email, password } }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    if (response.status === 401) {
      const { error } = await response.json()
      return error
    }

    const { token } = await response.json()
    const decodedToken: { role: UserRole } = jwtDecode(token)

    if (
      !['internal', 'manager'].includes(decodedToken.role) ||
      !decodedToken.role
    )
      return 'User has inappropriate role'
    localStorage.setItem(STORAGE_USER_TOKEN_KEY, token)

    const user = get('me', await query(CURRENT_USER_QUERY))

    batch(() => {
      dispatch(setToken(token))
      dispatch(setUser(user))
    })
  } catch (e) {
    return 'Server error'
  }
}

export const requestMeta = (): ThunkAction<
  Promise<void | string>
> => async dispatch => {
  try {
    const metaResponse = await fetch('/meta').then(res => res.json())
    dispatch(setMeta(metaResponse))
  } catch (e) {
    return 'Server error'
  }
}

export const requestCurrentUser = (): ThunkAction<
  Promise<void | string>
> => async dispatch => {
  try {
    const user = get('me', await query(CURRENT_USER_QUERY))
    dispatch(setUser(user))
  } catch (e) {
    return 'Server error'
  }
}
