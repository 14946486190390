import {
  default as React,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { compact, sortBy } from 'lodash'

import useDebounce from 'react-use/lib/useDebounce'
import useTitle from 'react-use/lib/useTitle'
import { Link } from 'react-router-dom'
import { getOr } from 'lodash/fp'
import { IBranch } from '../../types/user'
import Search from '../../components/Search'
import { detailedAccountSelector } from '../accountsSelectors'
import { requestDetailedAccount, uploadBranches } from '../accountsActions'
import OrderStatus from '../../orders/OrderStatus'
import useThunkDispatch from '../../common/useThunkDispatch'
import Splash from '../../components/Splash'
import name from '../../common/name'
import { TriggerImportDialog } from '../../components/ImportDialog'
import Button from '../../components/Button'
import EditIcon from '../../icons/edit.svg'
import AccountBranchNew, { INewBranch } from './AccountBranchNew'
import AccountBranchEdit from './AccountBranchEdit'
import { SimpleIndex } from '../../common/simpleSearch'

interface IRouteParams {
  accountId: string
}

const AccountBranches: FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
  history,
}) => {
  const accountId = Number(match.params.accountId)

  const dispatch = useThunkDispatch()
  const account = useSelector(detailedAccountSelector)
  useTitle(`${name(account)} - Forshaw`)

  const loadDetailedAccount = useCallback(id => {
    setLoading(true)
    dispatch(requestDetailedAccount(accountId)).then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!account || account.id !== accountId) {
      loadDetailedAccount(accountId)
    }
  }, [accountId])

  const branchesIndex = useMemo(
    () =>
      account && new SimpleIndex<IBranch>(account.branches, { keys: ['name'] }),
    [account]
  )

  const [branches, setBranches] = useState<IBranch[]>([])
  const [term, setTerm] = useState('')
  const [loading, setLoading] = useState(false)

  const [editingBranch, setEditingBranch] = useState<IBranch | null>(null)
  const [creatingBranch, setCreatingBranch] = useState<INewBranch | null>(null)

  useDebounce(
    () => {
      if (branchesIndex && term) setBranches(branchesIndex.search(term))
      else setBranches(getOr([], 'branches', account))
    },
    300,
    [term, branchesIndex]
  )

  useEffect(() => account && setBranches(account.branches), [account])

  const handleCleanUp = useCallback(() => {
    setLoading(true)
    dispatch(requestDetailedAccount(accountId)).then(() => setLoading(false))
  }, [accountId])

  const handleClose = useCallback(
    changed => {
      if (changed === true) {
        loadDetailedAccount(accountId)
      }
      setEditingBranch(null)
      setCreatingBranch(null)
    },
    [accountId]
  )

  const Row: FunctionComponent<{ branch: IBranch }> = ({
    branch,
    children,
  }) => (
    <Link
      to={`/clients/${accountId}/branches/${branch.id}`}
      className="table_tr -link clear-link"
    >
      {children}
    </Link>
  )

  return (
    <Splash isLoading={loading} withWrapper className="container">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            placeholder="Search Branch"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
        </div>
        <div className="filter_sep" />
        <div className="filter_col">
          <Button
            onClick={() =>
              setCreatingBranch({
                id: null as any,
                name: '',
                copyDivisions: true,
                copyTeams: true,
                copyInventory: true,
                showTechPrice: true,
                shipmentApproveFlow: false,
                copyManagers: true,
                copyReplenishmentPlan: true,
                copyPurchaseHistory: true,
                copyOrders: true,
              })
            }
            block
          >
            Create Branch
          </Button>
        </div>
        &nbsp;&nbsp;
        <div className="filter_col">
          <TriggerImportDialog
            action={uploadBranches}
            templateName="import_branches_template.csv"
            templateUrl="/import_branches_template.csv"
            title="Import branches"
            triggerElement={<Button block>Import Branches</Button>}
          />
        </div>
      </div>

      <div className="table">
        <div className="table_head-tr">
          <div className="table_td -g50 table_title">Branch</div>
          <div className="table_td -g50 table_title">NAV ID</div>
          <div className="table_td -fix150 table_title">Products Planned</div>
          <div className="table_td -fix200 table_title">
            This month replenishment
          </div>
          <div className="table_td -fix200 table_title">
            Manual Shipment Approval
          </div>
          <div className="table_head-tr table_td -fix100 table_title">
            &nbsp;
          </div>
        </div>
        {branches.length === 0 && (
          <div className="table_head-tr">
            <div className="table_head-tr table_td table_title">
              No branches found, please adjust search criteria
            </div>
          </div>
        )}

        {sortBy(branches, 'name').map(branch => (
          <Row branch={branch} key={branch.id}>
            <div className="table_td -g50">
              <b>{branch.name}</b>
              <br />
              <small>
                {compact([
                  branch.address,
                  branch.address2,
                  branch.city,
                  branch.state,
                  branch.zip,
                ]).join(', ')}
              </small>
            </div>
            <div className="table_td -g50 ellipsis">{branch.navId}</div>
            <div className="table_td -fix150">
              {branch.plannedProductsCount}
            </div>
            <div className="table_td -fix200">
              {branch.actualOrders && branch.actualOrders.length > 0 && (
                <OrderStatus order={branch.actualOrders[0]} />
              )}
            </div>
            <div className="table_td -fix200">
              {branch.shipmentApproveFlow ? (
                <span className="sync_message -warning">Yes</span>
              ) : (
                <span className="sync_message">No</span>
              )}
            </div>
            <div
              className="table_td -fix100 -text-right link"
              onClick={e => {
                e.preventDefault()
                setEditingBranch(branch)
              }}
            >
              <button className="clear-button">
                <EditIcon className="table_edit-link" />
              </button>
            </div>
          </Row>
        ))}
      </div>

      {creatingBranch && (
        <AccountBranchNew
          branch={creatingBranch}
          accountId={accountId}
          onClose={handleClose}
          branches={branches}
        />
      )}

      {editingBranch && (
        <AccountBranchEdit
          branch={editingBranch}
          onClose={handleClose}
          account={account}
        />
      )}
    </Splash>
  )
}

export default AccountBranches
