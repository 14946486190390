import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import Splash from '../components/Splash'
import useThunkDispatch from '../common/useThunkDispatch'
import { IUser, STORAGE_USER_TOKEN_KEY } from '../types/user'
import { currentUserSelector } from './authSelectors'
import { requestCurrentUser } from './authActions'

interface IOwnProps {
  children: (user: IUser) => any
}

const Auth = ({ children }: IOwnProps) => {
  const token = localStorage.getItem(STORAGE_USER_TOKEN_KEY)
  const user = useSelector(currentUserSelector)
  const dispatch = useThunkDispatch()

  const init = async () => {
    if (token && !user) dispatch(requestCurrentUser())
  }

  React.useEffect(() => {
    init()
  }, [])

  if (!token) return <Redirect to="/login" />

  if (!user) return <Splash isLoading={true} />

  return children(user)
}

export default Auth
