import React, { FC, memo, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IAccount, IBranch } from '../../types/user'
import useThunkDispatch from '../../common/useThunkDispatch'
import { managerAccountBranchesSelector } from '../managerSelectors'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import DropdownSelect from '../../components/DropdownSelect'
import Input from '../../components/Input'
import Button from '../../components/Button'
import CrossIcon from '../../icons/cross.svg'
import { IProduct } from '../../types/products'
import {
  requestProductTransfer,
  requestBranchesProductsList,
} from './inventoryActions'
import { compact } from 'lodash'

interface IOwnProps {
  opened: boolean
  branch: IBranch
  product: IProduct
  account?: IAccount
  onRefetch: () => void
  onClose: () => void
}

const ProductTransfer: FC<IOwnProps> = ({
  branch: currentBranch,
  opened,
  account,
  product: sourceProduct,
  onRefetch,
  onClose,
}) => {
  const dispatch = useThunkDispatch()
  const branches: IBranch[] = useSelector(managerAccountBranchesSelector)
  const [branch, setBranch] = useState<IBranch | undefined>()
  const [targetProduct, setTargetProduct] = useState<IProduct | undefined>(
    sourceProduct
  )
  const [sourceAmount, setSourceAmount] = useState<number>()
  const [destinationAmount, setDestinationAmount] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setBranch(undefined)
    setSourceAmount(undefined)
    setDestinationAmount(undefined)
    setTargetProduct(undefined)
  }, [opened])
  const handleClose = useCallback(() => {
    onClose()
  }, [])

  const handleChangeAmount = (value: string) => {
    const newValue = parseInt(value, 10)

    setSourceAmount(!!newValue || newValue === 0 ? newValue : undefined)
    if (!targetProduct || targetProduct.unit === sourceProduct.unit) {
      handleChangeDestinationAmount(value)
    }
  }

  const handleChangeDestinationAmount = (value: string) => {
    const newValue = parseInt(value, 10)

    setDestinationAmount(!!newValue || newValue === 0 ? newValue : undefined)
  }

  const handleUpdate = async () => {
    if (branch && sourceAmount && destinationAmount) {
      setLoading(true)
      const errors = await dispatch(
        requestProductTransfer(
          sourceProduct.code,
          currentBranch.id,
          branch.id,
          sourceAmount,
          destinationAmount
        )
      )
      if (!errors) {
        onRefetch()
      } else {
        alert(errors.base)
      }
      setLoading(false)
    }
    handleClose()
  }

  useEffect(() => {
    if (!branch) {
      setTargetProduct(undefined)
      return
    }

    const perform = async (branchId: number) => {
      setLoading(true)
      const tProducts = await dispatch(
        requestBranchesProductsList(
          branchId,
          sourceProduct.code,
          undefined,
          account ? account.id : undefined
        )
      )
      setTargetProduct(tProducts[0])
      setLoading(false)
    }

    perform(branch.id)
  }, [branch])

  if (!opened) return null

  const options = compact(
    branches.map(branch =>
      branch.id === currentBranch.id
        ? undefined
        : {
            value: branch.id,
            label: branch.name,
          }
    )
  )

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={handleClose} />
      <div className="import_dialog transfer-modal -sm">
        <div className="transfer-modal_title">
          Transfer {sourceProduct.name}
        </div>
        <CrossIcon className="transfer-modal_close" onClick={handleClose} />
        <div className="transfer-modal_field">
          <DropdownSelect<number | undefined>
            label="Destination BRANCH"
            className="-md"
            value={branch && branch.id}
            options={options}
            onChange={id => {
              setBranch(branches.find(b => b.id === id))
            }}
            dropdownValueClassName="input_input -flex"
          />
        </div>
        <div className="transfer-modal_field">
          <Input
            label={`SEND AMOUNT ($${sourceProduct.price} / ${sourceProduct.unit})`}
            value={sourceAmount}
            type="number"
            onChange={e => handleChangeAmount(e.target.value)}
            autoComplete="off"
          />
          <span style={{ color: '#757D9F', fontSize: 13 }}>
            Current stock: {sourceProduct.inventory || 0}{' '}
            {sourceProduct.inventory && sourceProduct.inventory > 1
              ? 'items'
              : 'item'}
          </span>
        </div>
        {targetProduct && targetProduct.unit !== sourceProduct.unit && (
          <div className="transfer-modal_field -repacking">
            <span className="alert">
              Destination branch has different repackaging settings. Please,
              specify receive amount.
            </span>
            <Input
              label={`RECEIVE AMOUNT ($${targetProduct.price} / ${targetProduct.unit})`}
              value={destinationAmount}
              onChange={e => handleChangeDestinationAmount(e.target.value)}
              autoComplete="off"
            />
          </div>
        )}
        <Button
          color="primary"
          type="submit"
          disabled={
            !branch ||
            !sourceAmount ||
            sourceAmount <= 0 ||
            !destinationAmount ||
            destinationAmount <= 0
          }
          loading={loading}
          className="transfer-modal_button"
          onClick={handleUpdate}
        >
          Transfer
        </Button>
        <div className="transfer-modal_cancel" onClick={handleClose}>
          Cancel
        </div>
      </div>
    </ModalWrapper>
  )
}

export default memo(ProductTransfer)
