import {
  default as React,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { RouteComponentProps } from 'react-router'
import { distanceInWordsToNow } from 'date-fns'
import useTitle from 'react-use/lib/useTitle'
import { useSelector } from 'react-redux'
import { map, sortBy } from 'lodash/fp'

import useThunkDispatch from '../../common/useThunkDispatch'
import { usersAccountSelector } from '../accountsSelectors'
import name from '../../common/name'
import { requestUsersAccount } from '../accountsActions'
import Splash from '../../components/Splash'
import Avatar from '../../components/Avatar'
import { IUser } from '../../types/user'
import AccountUserEdit from './AccountUserEdit'
import EditIcon from '../../icons/edit.svg'
import SyncIcon from '../../icons/sync_circle.svg'
import Search from '../../components/Search'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from 'react-window'
import useDebounce from 'react-use/lib/useDebounce'
import Button from '../../components/Button'
import { requestScheduleUsersSync } from '../../users/usersActions'
import { SimpleIndex } from '../../common/simpleSearch'

interface IRouteParams {
  accountId: string
}

const Row: FunctionComponent<ListChildComponentProps> = ({
  data,
  index,
  style,
}) => {
  const user = data.users[index]

  return (
    <div style={style}>
      <div className="table_tr">
        <div className="table_td -avatar">
          <Avatar user={user} />
        </div>
        <div className="table_td -g25 ellipsis">{name(user)}</div>
        <div className="table_td -g25 ellipsis">{user.email}</div>
        <div className="table_td -fix150 ellipsis">{user.role}</div>
        <div
          className="table_td -g50 ellipsis"
          title={map('name', user.branches).join(', ')}
        >
          {map('name', user.branches).join(', ')}
        </div>
        <div className="table_td -human-readable-date ellipsis">
          {user.lastSignInAt &&
            distanceInWordsToNow(user.lastSignInAt, {
              addSuffix: true,
            }).replace('about ', '')}
        </div>
        <div className="table_td -fix100 -text-right">
          <button
            className="clear-button link"
            onClick={e => {
              e.preventDefault()
              data.setEditingUser(user)
            }}
          >
            <EditIcon className="table_edit-link" />
          </button>
        </div>
      </div>
    </div>
  )
}

const AccountUsers: FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const accountId = Number(match.params.accountId)
  const dispatch = useThunkDispatch()

  const [editingUser, setEditingUser] = useState<Partial<IUser> | null>(null)
  const closeUserEdit = useCallback(() => setEditingUser(null), [
    setEditingUser,
  ])

  const [loading, setLoading] = useState(true)

  const account = useSelector(usersAccountSelector)
  useTitle(`Users - ${name(account)} - Forshaw`)

  const usersIndex = useMemo(
    () =>
      account &&
      account.users &&
      new SimpleIndex<IUser>(account.users, {
        keys: ['name', 'email', 'role'],
      }),
    [account]
  )

  const listRef = useRef<FixedSizeList>(null)
  const [users, setUsers] = useState<IUser[]>([])
  const [term, setTerm] = useState('')

  useEffect(() => {
    if (account && account.id === accountId) {
      setLoading(false)
      setUsers(sortBy('name', account.users!))
    } else {
      setLoading(true)
      dispatch(requestUsersAccount(accountId))
    }
  }, [account])

  useDebounce(
    () => {
      if (!usersIndex || !account || !account.users) return

      if (term) setUsers(usersIndex.search(term))
      else setUsers(sortBy('name', account.users!))
      if (listRef.current) listRef.current.scrollToItem(0)
    },
    300,
    [term]
  )

  const ItemKey: ListItemKeySelector = index => users[index].id

  const requestUsersSync = async () => {
    if (!account) {
      return
    }
    const error = await dispatch(requestScheduleUsersSync(account.id))
    if (error) alert(error)
    else
      alert(
        'Syncing users started. Please wait for about 15 seconds and refresh the page'
      )
  }

  return (
    <div className="container">
      <div className="container">
        {editingUser && account && (
          <AccountUserEdit
            accountId={accountId}
            user={editingUser}
            branches={sortBy('name', account.branches || [])}
            divisions={sortBy('name', account.divisions || [])}
            onClose={closeUserEdit}
          />
        )}

        <div className="filter">
          <div className="filter_col -large">
            <Search
              placeholder="Search Users"
              value={term}
              onChange={e => setTerm(e.target.value)}
            />
          </div>
          <div className="filter_col" style={{ minWidth: 'auto' }}>
            <button
              type="button"
              className="sync_button clear-button"
              onClick={requestUsersSync}
            >
              <SyncIcon className="sync_icon" />
              Sync Users
            </button>
          </div>
          <div className="filter_sep" />
          <div className="filter_col">
            <Button onClick={() => setEditingUser({ role: 'technician' })}>
              Create Technician
            </Button>
          </div>
        </div>

        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -avatar" />
            <div className="table_td -g25 table_title">Users</div>
            <div className="table_td -g25 table_title">Email</div>
            <div className="table_td -fix150 table_title">Role</div>
            <div className="table_td -g50 table_title">Branch</div>
            <div className="table_td -human-readable-date table_title ellipsis">
              Last log in
            </div>
            <div className="table_head-tr table_td -fix100 table_title" />
          </div>
        </div>

        <div className="table_loading-wrapper">
          {users.length === 0 && !loading ? (
            <div className="table">
              <div className="table_head-tr">
                <div className="table_head-tr table_td table_title">
                  No users found, please adjust search criteria
                </div>
              </div>
            </div>
          ) : (
            <AutoSizer
              className="table"
              style={{ height: 'calc(100vh - 200px)' }}
            >
              {size => (
                <FixedSizeList
                  ref={listRef}
                  className="wonder-scroll"
                  {...size}
                  itemData={{ users, setEditingUser }}
                  itemCount={users.length}
                  itemSize={54 + 8}
                  itemKey={ItemKey}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
          <Splash isLoading={loading} bgColor="#F2F6FA" />
        </div>
      </div>
    </div>
  )
}

export default AccountUsers
