import { flow, get, sortBy } from 'lodash/fp'
import { Selector } from '../types/common'
import { IBranch, IBILink, IDivision } from '../types/user'

export const managerBranchesSelector: Selector<IBranch[]> = flow<any, any, any>(
  get('manager.branches'),
  sortBy(['name', 'id'])
)
export const managerBranchSelector: Selector<IBranch | undefined> = state => {
  const branch = get('manager.branch', state)
  const branches = managerBranchesSelector(state)
  const pathname = get('router.location.pathname', state)
  if (branch && branch.id === -1 && pathname !== '/orders') return branches[0]
  return branch
}
export const managerAccountBranchesSelector: Selector<IBranch[]> = state => {
  const isInternal = get('auth.user.role', state) === 'internal'
  const branches = isInternal
    ? get('accounts.detailedAccount.branches', state)
    : get('manager.accountBranches', state)
  return sortBy(['name', 'id'], branches)
}
export const managerBiLinksSelector: Selector<IBILink[]> = get(
  'manager.biLinks'
)
export const managerBranchesOptionsSelector: Selector<IBranch[]> = state => {
  const branches = managerBranchesSelector(state)
  const pathname = get('router.location.pathname', state)
  if (pathname !== '/orders') return branches
  return [{ id: -1, name: 'All Branches' }, ...branches]
}

export const divisionsSelector: Selector<IDivision[]> = get(
  'divisions.divisions'
)
