import * as React from 'react'
import Downshift from 'downshift'
import { uniqueId } from 'lodash'
import ClickOutside from 'react-click-outside'
import cn from 'classnames'
import ChevronDown from '../../icons/chevron_down.svg'
import BasePopover from '../BasePopover'
import LensIcon from '../../icons/lens.svg'

export interface IOption<T> {
  label: string
  value: T
}

interface IOwnProps<T> {
  value: T
  onChange: (value: T) => void
  options: Array<IOption<T>> | ((term: string) => Promise<Array<IOption<T>>>)
  searcheable?: boolean
  className?: string
  width?: string
  itemToString(value: T): string
  children?(args: {
    value: T
    isOpen: boolean
    setOpen(): void
    itemToString(value: T): string
  }): React.ReactNode
}

interface IState {
  filter: string
  isOpenDropdown: boolean
}

export default class Dropdown extends React.Component<IOwnProps<any>, IState> {
  public readonly state = {
    placeholder: '',
    filter: '',
    isOpenDropdown: false,
  }

  public componentDidUpdate(
    prevProps: Readonly<IOwnProps<any>>,
    prevState: Readonly<IState>,
    snapshot?: any
  ) {
    if (this.state.isOpenDropdown) {
      this.listenIframeClicks()
    } else {
      this.removeIframeClickListeners()
    }
  }

  public listenIframeClicks() {
    const iframes = document.querySelectorAll('iframe')

    if (!iframes.length) {
      return
    }

    iframes.forEach(iframe => {
      if (!iframe.contentWindow) {
        return
      }

      iframe.contentWindow.document.addEventListener(
        'click',
        this.onCloseDropdown
      )
    })
  }

  public removeIframeClickListeners() {
    const iframes = document.querySelectorAll('iframe')

    if (!iframes.length) {
      return
    }

    iframes.forEach(iframe => {
      if (!iframe.contentWindow) {
        return
      }

      iframe.contentWindow.document.removeEventListener(
        'click',
        this.onCloseDropdown
      )
    })
  }

  public render(): React.ReactNode {
    const {
      options,
      onChange,
      itemToString,
      value,
      children,
      searcheable = true,
      className,
      width,
    } = this.props
    const { isOpenDropdown, filter } = this.state
    const id = `search_${uniqueId()}`

    return (
      <BasePopover
        isOpen={isOpenDropdown}
        className={cn('dropdown_popup', className)}
        style={{ width, maxWidth: '600px' }}
        body={
          <Downshift
            onChange={e => {
              onChange(e)
              this.onCloseDropdown()
            }}
            selectedItem={value || null}
            itemToString={itemToString}
            isOpen={isOpenDropdown}
          >
            {({
              getInputProps,
              getItemProps,
              highlightedIndex,
              selectedItem,
            }) => (
              <div>
                <ClickOutside onClickOutside={this.onCloseDropdown}>
                  {searcheable && (
                    <div className="dropdown_search">
                      <label className="dropdown_search-label" htmlFor={id}>
                        <LensIcon />
                      </label>
                      <input
                        {...getInputProps({
                          id,
                          autoFocus: true,
                          placeholder: 'Search...',
                          onChange: this.onFilter,
                          value: filter,
                        })}
                        type="search"
                        className="dropdown_search-input"
                      />
                    </div>
                  )}
                  <ul className="dropdown_suggest">
                    {Array.isArray(options) && (
                      <>
                        {this.filterData(options).length > 0 ? (
                          this.filterData(options).map((item, idx) => (
                            <li
                              {...getItemProps({
                                className: cn('dropdown_suggest-item link', {
                                  '-highlighted': highlightedIndex === idx,
                                  '-selected':
                                    itemToString(selectedItem) ===
                                    itemToString(item),
                                }),
                                index: idx,
                                item,
                                key: idx,
                              })}
                            >
                              {itemToString(item)}
                            </li>
                          ))
                        ) : (
                          <div className="dropdown_noresult">
                            Not found result
                          </div>
                        )}
                      </>
                    )}
                  </ul>
                </ClickOutside>
              </div>
            )}
          </Downshift>
        }
      >
        {children ? (
          children({
            isOpen: isOpenDropdown,
            setOpen: this.onOpenDropdown,
            value,
            itemToString,
          })
        ) : (
          <div className={cn('dropdown', { '-open': isOpenDropdown })}>
            <button
              onClick={this.onOpenDropdown}
              type="button"
              className="clear-button link dropdown_button"
            >
              <div className="dropdown_text">{itemToString(value)}</div>
              <ChevronDown className="dropdown_icon" />
            </button>
          </div>
        )}
      </BasePopover>
    )
  }

  private onFilter = (e: any) => {
    this.setState({ filter: e.target.value })
  }

  private onCloseDropdown = () => {
    this.setState({ isOpenDropdown: false, filter: '' })
  }

  private onOpenDropdown = () => {
    this.setState({ isOpenDropdown: true })
  }

  private filterData = (options: any[]) =>
    options.filter(
      (item: any) =>
        !this.state.filter ||
        this.props
          .itemToString(item)
          .toLocaleLowerCase()
          .includes(this.state.filter.toLocaleLowerCase())
    )
}
