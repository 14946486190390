import { find, get } from 'lodash/fp'
import qs from 'qs'
import { IHash, ParametricSelector, Selector, Store } from '../types/common'
import { createSelector } from 'reselect'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
} from '../types/orders'
import { IProduct } from '../types/products'
import {
  IProductMonthGrouped,
  planForecastsSelectorFn,
  planMonthsSelector,
  planMonthsSelectorFn,
  planOrdersDictSelector,
  planProductsSelector,
  planReplenishSelectorFn,
  planRequestedBranchSelector,
  planShipmentsSelectorFn,
  planYearSelector,
  productMonthFillinSelectorFn,
} from '../plan/planSelectors'
import { RouteComponentProps } from 'react-router'
import { IProductRouteParams } from './Product'
import { IPlannedBranch } from '../types/user'
import { IHistory } from '../types/common'

export const productYearSelector: Selector<number> = get('product.year')
export const productOrdersDictSelector: Selector<IHash<IOrder>> = get(
  'product.orders',
)
export const requestedProductSelector: Selector<IProduct | undefined> = get(
  'product.product',
)
export const compareProductSelector: Selector<boolean> = get('product.compare')
export const productParamsSelector = createSelector(
  planYearSelector,
  (state: Store, { history }: { history: IHistory }) => {
    const { year, compare } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })
    return { year: Number(year), compare }
  },
  (planYear: number, { year, compare }: { year: number; compare: string }) => {
    return { year: Number(year || planYear), compare }
  },
)

export const productIdSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  number
> = (state: Store, { match }: RouteComponentProps<IProductRouteParams>) =>
  Number(match.params.productId)

export const isPlanSegmentSelector: Selector<boolean> = createSelector(
  planYearSelector,
  planMonthsSelector,
  productYearSelector,
  (planYear, planMonths, productYear) =>
    planYear === productYear && planMonths.length > 0,
)

const productMonthsSelectorFn = (
  planYear: number,
  planMonths: IPlannedOrder[],
  productYear: number,
  productOrders: IHash<IOrder>,
) =>
  planYear === productYear && planMonths.length > 0
    ? planMonths
    : planMonthsSelectorFn(productOrders)
export const productMonthsSelector: Selector<IPlannedOrder[]> = createSelector(
  planYearSelector,
  planMonthsSelector,
  productYearSelector,
  productOrdersDictSelector,

  productMonthsSelectorFn,
)

export const productProductSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProduct | undefined
> = createSelector(
  productIdSelector,
  isPlanSegmentSelector,
  planProductsSelector,
  requestedProductSelector,
  (productId, isSegment, planProducts, requestedProduct) => {
    if (isSegment) return find({ id: productId }, planProducts)
    if (requestedProduct) return requestedProduct
    return undefined
  },
)

export const productRequestedBranchSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IPlannedBranch | undefined
> = createSelector(
  isPlanSegmentSelector,
  planRequestedBranchSelector,
  get('product.requestedBranch'),
  (isSegment, planBranch, productBranch) => {
    return isSegment ? planBranch : productBranch
  },
)

export const productForecastsSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProductMonthGrouped<IBranchForecast>
> = createSelector(
  productRequestedBranchSelector,
  planForecastsSelectorFn,
)

export const productShipmentsSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProductMonthGrouped<IBranchShipment[]>
> = createSelector(
  productRequestedBranchSelector,
  planShipmentsSelectorFn,
)

export const productShipmentsCompareSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProductMonthGrouped<IBranchShipment[]>
> = createSelector(
  get('product.requestedCompareBranch'),
  planShipmentsSelectorFn,
)

export const productReplenishSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProductMonthGrouped<IPlannedOrderItem & { order: IPlannedOrder }>
> = createSelector(
  productMonthsSelector,
  planReplenishSelectorFn,
)

export const productFillinSelector: ParametricSelector<
  RouteComponentProps<IProductRouteParams>,
  IProductMonthGrouped<Array<IOrderItem & { order: IFillinOrder }>>
> = createSelector(
  isPlanSegmentSelector,
  planOrdersDictSelector,
  productOrdersDictSelector,
  (isSegment, planOrder, productOrders) =>
    productMonthFillinSelectorFn(isSegment ? planOrder : productOrders),
)
