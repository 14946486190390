import React, { FC, memo, CSSProperties } from 'react'
import BugIcon from '../../icons/bug.svg'

interface IOrdersPlaceholderProps {
  message: string
  style?: CSSProperties
}

const OrdersPlaceholder: FC<IOrdersPlaceholderProps> = ({ message, style }) => (
  <div className="replenishment-empty" style={style}>
    <div className="replenishment-empty_title">Nothing here</div>
    <div className="replenishment-empty_message">{message}</div>
    <BugIcon className="replenishment-empty_icon" />
  </div>
)

export default memo(OrdersPlaceholder)
