import {
  default as React,
  FunctionComponent,
  useCallback,
  useState,
} from 'react'
import cn from 'classnames'
import useThunkDispatch from '../common/useThunkDispatch'
import Button from './Button'
import ModalWrapper from './Modal/ModalWrapper'
import LoadingIcon from '../icons/loading.svg'
import OkIcon from '../icons/ok_cirlce.svg'
import WrongIcon from '../icons/cross_circle.svg'
import { ThunkAction } from '../types/common'
import { Dropzone } from './ImportDialog'

interface IOwnProps {
  close: (satus?: string) => void
  action: (file: File) => ThunkAction<Promise<boolean>>
  title: string
  templateUrl?: string
  templateName?: string
}

const AsyncImportDialog: FunctionComponent<IOwnProps> = ({
  close,
  action,
  templateName,
  templateUrl,
  title,
}) => {
  const dispatch = useThunkDispatch()

  const [status, setStatus] = useState<
    'ready' | 'uploading' | 'error' | 'done'
  >('ready')

  const [file, setFile] = useState<File | undefined>(undefined)
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])
  }, [])

  const onSubmit = async () => {
    if (!file) return

    try {
      setStatus('uploading')
      const success = await dispatch(action(file))

      if (success) {
        setStatus('done')
      } else {
        setStatus('error')
      }
    } catch (e) {
      setStatus('error')
    }
  }

  const onClose = () => {
    reset()
    close(status)
  }

  const reset = () => {
    setStatus('ready')
    setFile(undefined)
  }

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />

      <div className="import_dialog">
        {status !== 'uploading' && (
          <button
            className="import_close clear-button link"
            onClick={onClose}
          />
        )}

        {/* DEFAULT STATE */}
        {status === 'ready' && (
          <>
            <div className="import_title">{title}</div>

            <Dropzone onDrop={onDrop} filename={file && file.name} />

            <Button disabled={!file} block onClick={onSubmit}>
              START IMPORT
            </Button>
            {templateUrl && (
              <a
                href={templateUrl}
                download={templateName}
                className="import_download primary-link"
              >
                Download import template
              </a>
            )}
          </>
        )}

        {/* WHEN LIST IS UPLOADING */}
        {status === 'uploading' && (
          <>
            <div className="import-icon">
              <LoadingIcon width={70} height={70} />
            </div>
            <div className="import_info-title">Importing</div>
            <div className="import_info-text">Just a moment</div>
          </>
        )}

        {/* WHEN LIST IS SUCCESSFULLY UPLOADED */}
        {status === 'done' && (
          <>
            <div className="import-icon -ok">
              <OkIcon width={50} height={50} />
            </div>
            <div className="import_info-title">File uploaded.</div>
            <div className="import_info-text">
              We will send you an email when processing is complete.
            </div>
            <Button
              block
              color="primary-link"
              onClick={onClose}
              className="import_info-button"
            >
              Done
            </Button>
          </>
        )}

        {/* WHEN LIST IS UPLOADED WITH ERRORS */}
        {status === 'error' && (
          <>
            <div className="import-icon -wrong">
              <WrongIcon width={50} height={50} />
            </div>
            <div className="import_info-title">Something went wrong</div>
            <Button
              block
              color="primary-link"
              onClick={reset}
              className="import_info-button"
            >
              Try Again
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  )
}

export default AsyncImportDialog
