import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../auth/authSelectors'
import ManagerBranchesPicker from '../../manager/common/ManagerBranchesPicker'
import ManagerBILinks from '../../manager/common/ManagerBILinks'

const SubHeaderManager: FC = () => {
  const currentUser = useSelector(currentUserSelector)
  return (
    <>
      <ManagerBranchesPicker className="header_picker" />
      <ManagerBILinks className="menu_root-link link" />
      <div className="header_separator" />
    </>
  )
}

export default SubHeaderManager
