import { FunctionComponent, ImgHTMLAttributes, default as React, useEffect, useState, memo } from 'react'
import { isString } from 'lodash/fp'

const Image: FunctionComponent<{ src: string | File } & ImgHTMLAttributes<HTMLImageElement>> = ({ src, ...props }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (isString(src)) setUrl(src)
    else {
      const generatedUrl = URL.createObjectURL(src)
      setUrl(generatedUrl)
      return () => URL.revokeObjectURL(generatedUrl)
    }
  }, [src])

  return <img src={url} {...props} />
}

export default memo(Image)
