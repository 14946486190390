import {
  ComponentType,
  default as React,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from 'react-window'
import useTitle from 'react-use/lib/useTitle'
import { format } from 'date-fns'

import {
  ordersFilterSelector,
  ordersPaginationStateSelector,
  ordersSelector,
} from './ordersSelectors'
import OrderStatus from './OrderStatus'
import OrderIcon from './OrderIcon'
import name from '../common/name'
import { ICursoryOrder, isSubmittedOrder } from '../types/orders'
import { filterOrders, FilterOrdersReq } from './ordersActions'
import OrdersFilter from './OrdersFilter'
import Splash from '../components/Splash'

const PER_PAGE = 250

const Orders: FunctionComponent = () => {
  useTitle('Orders - Forshaw')

  const dispatch = useDispatch()

  const listRef = useRef<FixedSizeList>(null)

  const [loading, setLoading] = useState(false)

  const orders = useSelector(ordersSelector)
  const paginationState = useSelector(ordersPaginationStateSelector)
  const ordersFilter = useSelector(ordersFilterSelector)

  const [openedOrder, setOpenedOrder] = useState<ICursoryOrder | undefined>(
    undefined
  )

  const loadOrders = async (req: FilterOrdersReq) => {
    setLoading(true)
    await dispatch(filterOrders(req))
    setLoading(false)
  }

  useEffect(() => {
    if (listRef.current) listRef.current.scrollToItem(0)
    loadOrders({ first: PER_PAGE, ...ordersFilter })
  }, [ordersFilter])

  if (openedOrder) return <Redirect to={`/orders/${openedOrder.id}`} />

  const Row: ComponentType<ListChildComponentProps> = ({ index, style }) => {
    const order = orders[index]

    return (
      <div style={style}>
        <div
          className="table_tr -fixed-height -link"
          onClick={() => setOpenedOrder(order)}
        >
          {ordersFilter.scope === 'submitted' ? (
            <>
              <div className="table_td -fix50">
                <OrderIcon order={order} />
              </div>
              <div className="table_td -g25">
                {isSubmittedOrder(order) &&
                  format(order.submittedAt, 'MM/DD/YYYY')}
              </div>
              <div className="table_td -g25">{order.account.name}</div>
              <div className="table_td -g25">{order.branch.name}</div>
              <div className="table_td -g25">{order.navOrderId}</div>
              <div className="table_td -g25">
                {isSubmittedOrder(order) && name(order.submittedBy)}
              </div>
            </>
          ) : (
            <>
              <div className="table_td -fix-date">
                {order.dueDateAt && format(order.dueDateAt, 'MM/DD/YYYY')}
              </div>
              <div className="table_td -fix-full-date">
                {order.updatedAt &&
                  format(order.updatedAt, 'MM/DD/YYYY hh:mmA')}
              </div>
              <div className="table_td -g16">
                <OrderStatus order={order} />
              </div>
              <div className="table_td -g16 ellipsis">{order.account.name}</div>
              <div className="table_td -g16">{order.branch.name}</div>
              <div className="table_td -g16">
                {order.updatedBy && name(order.updatedBy)}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
  const ItemKey: ListItemKeySelector = index => orders[index].id

  return (
    <div className="container">
      {/* {openedOrder && (
        <OrderDetails
          accountId={openedOrder.account.id}
          branchId={openedOrder.branch.id}
          orderId={openedOrder.id}
          onClose={() => setOpenedOrder(undefined)}
        />
      )} */}

      <OrdersFilter />
      {ordersFilter.scope === 'submitted' ? (
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -fix50" />
            <div className="table_td table_title -g25">Submitted</div>
            <div className="table_td table_title -g25">Client</div>
            <div className="table_td table_title -g25">Branch</div>
            <div className="table_td table_title -g25">Nav order ID</div>
            <div className="table_td table_title -g25">Submitted by</div>
          </div>
        </div>
      ) : (
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -fix-date table_title">Planned</div>
            <div className="table_td -fix-full-date table_title">
              Updated At
            </div>
            <div className="table_td -g16 table_title">Status</div>
            <div className="table_td -g16 table_title">Client</div>
            <div className="table_td -g16 table_title">Branch</div>
            <div className="table_td -g16 table_title">Updated by</div>
          </div>
        </div>
      )}

      <div className="table_loading-wrapper">
        <AutoSizer className="table" style={{ height: 'calc(100vh - 200px)' }}>
          {size => (
            <FixedSizeList
              ref={listRef}
              className="wonder-scroll"
              {...size}
              itemCount={orders.length}
              itemSize={55 + 17}
              itemKey={ItemKey}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
        <Splash isLoading={loading} bgColor="#F2F6FA" />
      </div>

      <button
        disabled={!paginationState.hasPreviousPage || loading}
        onClick={() =>
          loadOrders({
            last: PER_PAGE,
            before: paginationState.startCursor,
            ...ordersFilter,
          })
        }
      >
        PREV PAGE
      </button>
      <button
        disabled={!paginationState.hasNextPage || loading}
        onClick={() =>
          loadOrders({
            first: PER_PAGE,
            after: paginationState.endCursor,
            ...ordersFilter,
          })
        }
      >
        NEXT PAGE
      </button>
    </div>
  )
}

export default Orders
