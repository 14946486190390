import * as React from 'react'
import { useState } from 'react'
import Select from 'react-select'
import Async from 'react-select/async'
import { find, getOr } from 'lodash/fp'
import ClickOutside from 'react-click-outside'
import cn from 'classnames'
import ChevronDown from '../icons/chevron_down.svg'
import BasePopover from './BasePopover'

export interface IOption<T> {
  label: string
  value: T
}

interface IOwnProps<T> {
  value: T
  onChange: (value: T) => void
  options: Array<IOption<T>> | ((term: string) => Promise<Array<IOption<T>>>)
  className?: string
  dropdownClassName?: string
  dropdownValueClassName?: string
  dropdownLabelClassName?: string
  label?: React.ReactNode
}

const DropdownSelect: <T>(
  p: IOwnProps<T>,
) => React.ReactElement<IOwnProps<T>> = ({
  value,
  options,
  onChange,
  dropdownClassName,
  dropdownValueClassName,
  dropdownLabelClassName,
  className,
  label,
}) => {
  const [open, setOpen] = useState(false)
  const vValue = Array.isArray(options)
    ? getOr(value, 'label', find(op => op.value === value, options))
    : value

  const baseProps = {
    onChange: (op: any) => {
      setOpen(false)
      onChange(op && op.value)
    },
    components: { DropdownIndicator: null, IndicatorSeparator: null },
    autoFocus: true,
    backspaceRemovesValue: false,
    controlShouldRenderValue: false,
    hideSelectedOptions: false,
    isClearable: false,
    menuIsOpen: true,
    placeholder: 'Search...',
    tabSelectsValue: false,
    classNamePrefix: 'rselect',
  }

  return (
    <BasePopover
      className={cn('dropdown_popup', className)}
      isOpen={open}
      body={
        <ClickOutside onClickOutside={() => setOpen(false)}>
          {Array.isArray(options) ? (
            <Select options={options} {...baseProps} />
          ) : (
            <Async defaultOptions loadOptions={options} {...baseProps} />
          )}
        </ClickOutside>
      }
    >
      <div className={cn('dropdown', dropdownClassName, { '-open': open })}>
        {label && <label className={dropdownLabelClassName}>{label}</label>}
        <button
          onClick={() => setOpen(!open)}
          type="button"
          className={cn(
            dropdownValueClassName,
            'clear-button link dropdown_button',
          )}
        >
          <div className="dropdown_text">{vValue}</div>
          <ChevronDown className="dropdown_icon" />
        </button>
      </div>
    </BasePopover>
  )
}

export default DropdownSelect
