import { default as React, FC } from 'react'
import { format } from 'date-fns'
import { isEmpty, orderBy } from 'lodash'
import copy from 'copy-to-clipboard'

import ModalWrapper from '../../../components/Modal/ModalWrapper'
import { IDetailsOrder } from '../../../types/orders'
import CrossIcon from '../../../icons/cross.svg'
import CopyIcon from '../../../icons/copy.svg'
import Outside from '../../../icons/outside.svg'
import Tooltip from '../../../components/Tooltip'

interface IProps {
  order: IDetailsOrder
  onClose: () => void
  setShowSendApprove: (val: IDetailsOrder | undefined) => void
}

const SubmittedModal: FC<IProps> = ({ order, onClose, setShowSendApprove }) => {
  const handleCopy = (value?: string) => {
    if (isEmpty(value)) {
      return
    }
    copy(String(value))
    alert('Tracking number copied')
  }

  const orderName = order.navOrderId || 'N/A'

  return (
    <>
      <ModalWrapper className="import">
        <button className="import_backdrop clear-button" onClick={onClose} />
        <div className="import_dialog shipment-modal">
          <div className="shipment-modal_title">{`${orderName} Tracking`}</div>
          <CrossIcon className="shipment-modal_close" onClick={onClose} />
          <div className="shipment-modal_field">
            <div className="table">
              <div className="table_head-tr">
                <div className="table_td table_title -g25">Tracking Number</div>
                <div className="table_td table_title -g20">Shipping Date</div>
                <div className="table_td table_title -g33">Shipping Agent</div>
                <div className="table_td -fix50" />
              </div>
            </div>
            {orderBy(order.orderTrackingStatuses, ['date', 'id']).map(
              (ts, index) => (
                <div className="table_tr -fixed-height" key={`ts-${index}`}>
                  <div className="table_td -g25">
                    {isEmpty(ts.trackingUrl) ? (
                      <>&mdash;</>
                    ) : (
                      <a
                        href={ts.trackingUrl}
                        target="_blank"
                        style={{
                          color: ts.trackingUrl ? '#1063CD' : '#000',
                          cursor: 'pointer',
                          textDecoration: 'none',
                        }}
                      >
                        {ts.trackingNumber}
                        &nbsp;
                        <span style={{ bottom: -2, position: 'relative' }}>
                          <Outside />
                        </span>
                      </a>
                    )}
                  </div>
                  <div className="table_td -g20">
                    {ts.shippedAt ? format(ts.shippedAt, 'MMM D, YYYY') : '-'}
                  </div>
                  <div className="table_td -g33">{ts.shippingAgent || '-'}</div>
                  <div
                    className="table_td -fix50 -hint -link"
                    onClick={() => handleCopy(ts.trackingNumber)}
                  >
                    {isEmpty(ts.trackingNumber) ? (
                      <>&nbsp;</>
                    ) : (
                      <Tooltip id={`tooltip-${ts.id}`}>
                        <div
                          className="input_label-tooltip"
                          data-tip="Copy tracking number"
                          data-for={`tooltip-${ts.id}`}
                          data-effect="solid"
                          data-place="bottom"
                        >
                          <CopyIcon />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div style={{ margin: '10px 0 5px 0' }}>
            Have any questions?&nbsp;
            <a
              onClick={() => setShowSendApprove(order)}
              style={{ color: '#1063CD', cursor: 'pointer' }}
            >
              Contact us for details
            </a>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default SubmittedModal
