import React, { FC, memo, useState, useCallback, ReactElement } from 'react'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import Button from '../../components/Button'
import CrossIcon from '../../icons/cross.svg'

interface IModalProps {
  title?: string
  heading?: string
  text?: string
}

interface IOwnProps extends IModalProps {
  onClose: () => void
}

const UserActionError: FC<IOwnProps> = ({ title, heading, text, onClose }) => {
  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />
      <div className="import_dialog user-modal -sm">
        <div className="user-modal_title">{title}</div>
        <CrossIcon className="user-modal_close" onClick={onClose} />
        <div className="user-modal_info">
          <div className="user-modal_heading">{heading}</div>
          <div className="user-modal_text">{text}</div>
        </div>
        <Button
          color="primary"
          type="submit"
          className="user-modal_button"
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </ModalWrapper>
  )
}

const useActionError = (): [
  { open: (params: IModalProps) => void; close: () => void },
  ReactElement | null,
] => {
  const [opened, setOpened] = useState<boolean>(false)
  const [params, setParams] = useState<IModalProps>({})
  const open = useCallback((params: IModalProps) => {
    setParams(params)
    setOpened(true)
  }, [])
  const close = useCallback(() => setOpened(false), [])
  const modal = opened ? <UserActionError {...params} onClose={close} /> : null
  return [{ open, close }, modal]
}

export { useActionError }
export default memo(UserActionError)
