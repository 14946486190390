import { default as React, FunctionComponent, useCallback } from 'react'
import useBlobDownload from '../../common/useBlobDownload'
import ArrowIcon from './arrow.svg'

interface IOwnProps {
  name: string
  navCsv?: string
}

const OrderDownloadCsv: FunctionComponent<IOwnProps> = ({ name, navCsv }) => {
  const downloadFile = useBlobDownload()

  const handleCSVDownload = useCallback(() => {
    if (navCsv) {
      downloadFile(navCsv, name)
    }
  }, [name, navCsv])

  return (
    <button className="order-details_download" onClick={handleCSVDownload}>
      <span className="order-details_download-icon">
        <ArrowIcon />
      </span>
      Download CSV
    </button>
  )
}

export default OrderDownloadCsv
