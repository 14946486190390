import * as React from 'react'
import cn from 'classnames'
import LoadingIcon from '../../icons/loading.svg'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | 'primary'
    | 'success'
    | 'red-link'
    | 'default-link'
    | 'link'
    | 'warning-link'
    | 'primary-link'
    | 'danger'
    | 'muted'
    | 'warning'
  caliber?: 'md' | 'sm' | 'xs'
  loading?: boolean
  block?: boolean
  border?: boolean
}

const Button: React.FunctionComponent<IProps> = ({
  children,
  className,
  border,
  block,
  caliber = 'sm',
  color = 'primary',
  type = 'button',
  loading,
  ...props
}) => (
  <button
    className={cn(
      className,
      'clear-button',
      'button',
      `-${caliber}`,
      `-${color}${border ? '-border' : ''}`,
      {
        '-block': block,
      }
    )}
    type={type}
    {...props}
  >
    {children}
    {loading && (
      <LoadingIcon
        className={cn('button_loading', {
          '-right': children,
          '-center': !children,
        })}
      />
    )}
  </button>
)

export default Button
