import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { find } from 'lodash/fp'
import { accountsDictSelector } from '../accounts/accountsSelectors'
import { currentUserSelector } from '../auth/authSelectors'
import { IAccount } from '../types/user'
import { managerBranchSelector } from '../manager/managerSelectors'

interface IRouteParams {
  accountId?: string
  branchId?: string
}

export default (Component: any) => {
  const Decorator: FC<RouteComponentProps<IRouteParams>> = props => {
    const currentUser = useSelector(currentUserSelector)
    const isInternal = currentUser.role === 'internal'
    const accounts = useSelector(accountsDictSelector)
    let branch = useSelector(managerBranchSelector)
    let account: IAccount | undefined

    if (props.match.params.branchId && props.match.params.accountId) {
      account = accounts[props.match.params.accountId]
      if (account) {
        branch = find({ id: +props.match.params.branchId }, account.branches)
      }
    }

    return (
      <Component
        {...props}
        branch={branch}
        account={account}
        isInternal={isInternal}
        user={currentUser}
      />
    )
  }

  return Decorator
}
