import * as React from 'react'

import { createPortal } from 'react-dom'

const modalRoot = document.getElementById('modal-root')

interface IProps {
  className?: string
  children: React.ReactNode
}

export default class  Modal extends React.Component<IProps> {
  private el: Element

  constructor(props: any) {
    super(props)
    this.el = document.createElement('div')

    if (this.props.className) {
      this.el.classList.add(this.props.className)
    }
  }

  public componentDidMount() {
    if (modalRoot) {
      document.body.classList.add('modal_open')
      modalRoot.appendChild(this.el)

      /**
       * Plugins can depends from resize events, f.e. "react-textarea-autosize", but Portal not provide this.
       * We need to provide this event, otherwise they can be work not correctly.
       */
      if (typeof Event === 'function') {
        window.dispatchEvent(new Event('resize'))
      }
    }
  }

  public componentWillUnmount() {
    if (modalRoot) {
      if (modalRoot.childNodes.length <= 1) {
        document.body.classList.remove('modal_open')
      }
      modalRoot.removeChild(this.el)
    }
  }

  public render() {
    return createPortal(this.props.children, this.el)
  }
}
