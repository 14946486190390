import * as React from 'react'
import cn from 'classnames'
import ArrowLeftIcon from '../../icons/arrow_left.svg'
import ArrowRightIcon from '../../icons/arrow_right.svg'

interface IProps {
  total?: number
  page?: number
  perPage?: number
  onNext: () => void
  onPrevious: () => void
  nextEnabled?: boolean
  previousEnabled?: boolean
  className?: string
}

const Pager: React.FunctionComponent<IProps> = ({
  total = 0,
  page = 0,
  perPage = 1,
  nextEnabled = true,
  previousEnabled = true,
  onPrevious,
  onNext,
  className,
}) => {
  const current = total === 0 ? 0 : page + 1
  const max = current * perPage
  const min = page * perPage + 1
  const info = `${total === 0 ? 0 : min}-${
    max > total ? total : max
  } of ${total}`
  const totalPages = Math.ceil(total / perPage)
  return (
    <div className={cn('pager', className)}>
      <div className="pager_info">{info}</div>
      <button
        className="pager_button clear-button link"
        onClick={onPrevious}
        disabled={!previousEnabled}
      >
        <ArrowLeftIcon />
      </button>
      <div className="pager_title">
        {current} of {totalPages}
      </div>
      <button
        className="pager_button clear-button link"
        onClick={onNext}
        disabled={!nextEnabled}
      >
        <ArrowRightIcon />
      </button>
    </div>
  )
}

export default Pager
