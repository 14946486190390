import React, { FC, useCallback, useEffect, useState } from 'react'

import useThunkDispatch from '../../common/useThunkDispatch'
import name from '../../common/name'
import { requestManagerTeams } from '../managerActions'
import Splash from '../../components/Splash'
import { IAccount, IBranch, ITeam } from '../../types/user'
import Edit from './Edit'
import EditIcon from '../../icons/pencil.svg'
import DeleteIcon from '../../icons/trash.svg'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import useClientTitle from '../../common/useClientTitle'
import { removeManagerTeam } from '../managerActions'
import extractErrors from '../../common/extractErrors'
import Button from '../../components/Button'

const ManagerTeams: FC<{
  branch: IBranch
  account?: IAccount
}> = ({ branch, account }) => {
  // const accountId = account.id
  const dispatch = useThunkDispatch()
  const branchId = branch && branch.id

  const [editingTeam, setEditingTeam] = useState<Partial<ITeam> | null>(null)
  const closeTeamEdit = useCallback(() => {
    setEditingTeam(null)
  }, [setEditingTeam])

  const [loading, setLoading] = useState(true)

  // common
  useClientTitle({ branch, account })

  const [teams, setTeams] = useState<ITeam[]>([])

  const [deleting, setDeleting] = useState(false)
  const onDelete = async (team: ITeam) => {
    if (!team.id) return
    if (!confirm('Are you sure you want to permanently delete the team?'))
      return

    setDeleting(true)
    try {
      const errors = await dispatch(removeManagerTeam(team.id))

      if (errors) alert(extractErrors(errors))
      loadTeams(branchId)
    } catch (e) {
      alert('Server Error')
    }
    setDeleting(false)
  }

  // fetch/refetch catalog
  const loadTeams = async (branchId: number): Promise<void> => {
    setLoading(true)
    const list = await dispatch<Promise<ITeam[]>>(
      requestManagerTeams(branch && branch.id, account && account.id)
    )
    setTeams(list)
    setLoading(false)
  }

  // initial load
  useEffect(() => {
    if (branchId) {
      loadTeams(branchId)
    }
  }, [branch])

  return (
    <div className="container">
      <Splash withWrapper isLoading={loading} className="catalog -fixed-height">
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -g25 table_title" style={{ padding: 0 }}>
              Teams
            </div>
            <div
              className="table_head-tr table_td table_title"
              style={{ margin: 0 }}
            >
              <Button
                type="button"
                block
                color="primary"
                onClick={() => setEditingTeam({})}
              >
                Add Team
              </Button>
            </div>
          </div>
          {teams &&
            !!teams.length &&
            teams.map(team => (
              <div className="table_tr" key={team.id}>
                <div className="table_td -g25">{name(team)}</div>
                <div className="table_td -fix150 -text-right">
                  <button
                    className="clear-button -primary -link"
                    disabled={deleting}
                    onClick={() => onDelete(team)}
                    color="red-link"
                  >
                    <DeleteIcon />
                  </button>
                  <button
                    className="clear-button -primary -link"
                    onClick={e => {
                      e.preventDefault()
                      setEditingTeam(team)
                    }}
                  >
                    <EditIcon />
                    &nbsp; Edit
                  </button>
                </div>
              </div>
            ))}
          {!teams ||
            (teams && !teams.length && (
              <div className="table_head-tr">
                <div className="table_head-tr table_td table_title">
                  No teams found, use "Add +" button to create one
                </div>
              </div>
            ))}
        </div>

        {editingTeam && branch && (
          <Edit
            team={editingTeam}
            branchId={branch.id}
            onClose={closeTeamEdit}
            onSave={() => loadTeams(branchId)}
          />
        )}
      </Splash>
    </div>
  )
}

export default withAccountAndBranch(ManagerTeams)
