import { get, findIndex, update, assign, __, concat, sortBy } from 'lodash/fp'
import { format } from 'date-fns'
import {
  IPaginationReq,
  IPaginationState,
  ThunkAction,
} from '../../types/common'
import { query } from '../../common/api'
import { ICursoryOrder, IDetailsOrder, IOrderItem } from '../../types/orders'
import { currentUserSelector } from '../../auth/authSelectors'
import extractErrors from '../../common/extractErrors'
import {
  FIND_ORDER_QUERY,
  MANAGER_FIND_ORDER_QUERY,
  ORDER_FILTER_QUERY,
  MANAGER_ORDER_FILTER_QUERY,
  APPROVE_ORDER_QUERY,
  REJECT_ORDER_QUERY,
  UPDATE_ORDER_ITEM_QUERY,
  CREATE_ORDER_ITEM_QUERY,
  UPDATE_ORDER_QUERY,
  CREATE_ORDER_QUERY,
  SUBMIT_ORDER_QUERY,
  REOPEN_ORDER_QUERY,
  REQUEST_ORDER_QUERY,
  ORDER_REQUEST_TRACKING_INFO,
} from './ordersQueries'
import { updatePlanOrder } from '../../plan/planActions'

export interface IOrdersFilter {
  accountId?: number
  branchId?: number
  term?: string
  text?: string
  scope:
    | 'pending_approval'
    | 'all'
    | 'all_approval'
    | 'manageable'
    | 'submitted'
  period?: string
}

export interface IOrdersList {
  orders: ICursoryOrder[]
  paginationState: IPaginationState
  total: number
}

export type FilterOrdersReq = IPaginationReq & IOrdersFilter
export type FilterOrders = (req: FilterOrdersReq) => Promise<void>
export const requestFilterOrders = (
  req: FilterOrdersReq
): ThunkAction<Promise<IOrdersList>> => async (_, getState) => {
  const user = currentUserSelector(getState())
  const isInternal = user.role === 'internal'
  const prefix = isInternal ? 'internal' : 'manager'
  const response = await query(
    isInternal ? ORDER_FILTER_QUERY : MANAGER_ORDER_FILTER_QUERY,
    {
      ...req,
      branchId: req.branchId === -1 ? undefined : req.branchId,
      text: req.text ? req.text : null,
      scope: req.scope === 'all' ? undefined : req.scope,
      period:
        req.scope === 'submitted'
          ? req.period
            ? req.period
            : format(new Date(), 'YYYY-MM-DD')
          : undefined,
    }
  )

  const orders: ICursoryOrder[] = get(`${prefix}.orderFilter.nodes`, response)
  const paginationState: IPaginationState = get(
    `${prefix}.orderFilter.pageInfo`,
    response
  )
  const total = get(`${prefix}.orderFilter.totalItems`, response)

  return {
    orders,
    paginationState,
    total,
  }
}

export const requestDetailsOrder = (
  orderId: number
): ThunkAction<Promise<IDetailsOrder>> => async (_, getState) => {
  const user = currentUserSelector(getState())
  const isInternal = user.role === 'internal'
  const prefix = isInternal ? 'internal' : 'manager'
  const response = await query(
    isInternal ? FIND_ORDER_QUERY : MANAGER_FIND_ORDER_QUERY,
    { orderId }
  )
  const order = get(`${prefix}.orderFind`, response)
  return {
    ...order,
    orderItems: sortBy(
      'product.name',
      order.orderItems
      // reject({ amountRequested: 0 }, order.orderItems)
    ),
  }
}

export const requestOrderTrackingInfo = async (
  id: number
): Promise<{ errors?: any }> => {
  const response = await query(ORDER_REQUEST_TRACKING_INFO, { id })
  const { errors } = get('orderRequestTrackingInfo', response)

  if (errors) {
    return { errors: extractErrors(errors) }
  }

  return {}
}

export const requestOrderApprove = (
  orderId: number
): ThunkAction<
  Promise<{ errors?: any; result?: IDetailsOrder }>
> => async dispatch => {
  const response = await query(APPROVE_ORDER_QUERY, { id: orderId })
  const { result, errors } = get('orderApprove', response)

  if (errors) {
    return { errors: extractErrors(errors) }
  }
  dispatch(updatePlanOrder(result))

  return {
    result,
  }
}

export const requestOrderReopen = (
  orderId: number
): ThunkAction<
  Promise<{ errors?: any; result?: IDetailsOrder }>
> => async dispatch => {
  const response = await query(REOPEN_ORDER_QUERY, { id: orderId })
  const { result, errors } = get('orderReopen', response)
  if (errors) {
    return { errors: extractErrors(errors) }
  }
  dispatch(updatePlanOrder(result))

  return {
    result,
  }
}

export const requestOrderRequest = (
  orderId: number
): ThunkAction<
  Promise<{ errors?: any; result?: IDetailsOrder }>
> => async dispatch => {
  const response = await query(REQUEST_ORDER_QUERY, { id: orderId })
  const { result, errors } = get('orderRequest', response)
  if (errors) {
    return { errors: extractErrors(errors) }
  }
  dispatch(updatePlanOrder(result))

  return {
    result,
  }
}

export const requestOrderReject = (
  orderId: number,
  rejectReason: string
): ThunkAction<
  Promise<{ errors?: any; result?: IDetailsOrder }>
> => async dispatch => {
  const response = await query(REJECT_ORDER_QUERY, {
    id: orderId,
    attributes: { rejectReason },
  })
  const { result, errors } = get('orderReject', response)
  if (errors) {
    return { errors: extractErrors(errors) }
  }
  dispatch(updatePlanOrder(result))

  return {
    result,
  }
}

export const requestOrderSubmit = (
  orderId: number
): ThunkAction<
  Promise<{ errors?: any; result?: IDetailsOrder }>
> => async dispatch => {
  const response = await query(SUBMIT_ORDER_QUERY, { id: orderId })
  const { result, errors } = get('orderSubmit', response)
  if (errors) {
    return { errors: extractErrors(errors) }
  }
  dispatch(updatePlanOrder(result))

  return {
    result,
  }
}

export const requestOrderCreate = (
  branchId: number
): ThunkAction<
  Promise<{ errors?: string; result?: IDetailsOrder }>
> => async dispatch => {
  try {
    const {
      orderCreate: { result, errors },
    } = await query(CREATE_ORDER_QUERY, {
      branchId,
    })
    if (errors) {
      return { errors: extractErrors(errors) }
    }
    dispatch(updatePlanOrder(result))

    return {
      errors: undefined,
      result,
    }
  } catch (e) {
    return { errors: 'Server error', result: undefined }
  }
}

export const requestOrderUpdate = (
  id: number,
  attrs: Partial<IDetailsOrder>
): ThunkAction<
  Promise<{ errors?: string; result?: IDetailsOrder }>
> => async dispatch => {
  try {
    const {
      orderUpdate: { result, errors },
    } = await query(UPDATE_ORDER_QUERY, {
      id,
      attributes: {
        navReferenceId: attrs.navReferenceId,
      },
    })
    if (errors) {
      return { errors: extractErrors(errors) }
    }
    dispatch(updatePlanOrder(result))

    return {
      errors: undefined,
      result,
    }
  } catch (e) {
    return { errors: 'Server error', result: undefined }
  }
}

export const requestUpdateOrderItem = (
  order: IDetailsOrder,
  { id, amountRequested }: Partial<IOrderItem>
): ThunkAction<
  Promise<{ errors?: string; result?: IDetailsOrder }>
> => async dispatch => {
  try {
    const index = findIndex({ id }, order.orderItems)

    const {
      orderItemUpdate: { result: updatedAttrs, errors },
    } = await query(UPDATE_ORDER_ITEM_QUERY, {
      id,
      attributes: { amountRequested },
    })

    if (errors) return { errors: extractErrors(errors), result: undefined }

    const updatedOrder = {
      ...update(`orderItems[${index}]`, assign(__, updatedAttrs), order),
      cost: null,
    }
    dispatch(updatePlanOrder(updatedOrder))

    return {
      errors: undefined,
      result: {
        ...updatedOrder,
        orderItems: sortBy(
          'product.name',
          updatedOrder.orderItems
          // reject({ amountRequested: 0 }, updatedOrder.orderItems)
        ),
      },
    }
  } catch (e) {
    return { errors: 'Server error', result: undefined }
  }
}

export const requestCreateOrderItem = (
  order: IDetailsOrder,
  attrs: Partial<IOrderItem>
): ThunkAction<
  Promise<{ errors?: string; result?: IDetailsOrder }>
> => async dispatch => {
  try {
    const {
      orderItemCreate: { result: createdItem, errors },
    } = await query(CREATE_ORDER_ITEM_QUERY, {
      attributes: {
        orderId: order.id,
        amountRequested: attrs.amountRequested,
        productId: attrs.product && attrs.product.id,
      },
    })
    if (errors) return { errors: extractErrors(errors), result: undefined }
    const updatedOrder = {
      ...update('orderItems', concat(createdItem), order),
      cost: null,
    }
    dispatch(updatePlanOrder(updatedOrder))

    return {
      errors: undefined,
      result: {
        ...updatedOrder,
      },
    }
  } catch (e) {
    return { errors: 'Server error', result: undefined }
  }
}
