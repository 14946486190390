import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { pick } from 'lodash/fp'

import authReducer from './auth/authReducer'
import history from './history'
import ordersReducer from './orders/ordersReducer'
import accountsReducer from './accounts/accountsReducer'
import usersReducer from './users/usersReducer'
import planReducer from './plan/planReducer'
import productReducer from './product/productReducer'
import managerReducer from './manager/managerReducer'
import { CLEAR_STATE } from './auth/authActions'
import { Action } from './types/common'

const reducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  users: usersReducer,
  accounts: accountsReducer,
  orders: ordersReducer,
  plan: planReducer,
  product: productReducer,
  manager: managerReducer,
})

export default (state: any, action: Action<any>) =>
  reducer(
    action.type === CLEAR_STATE ? pick('auth.meta', state) : state,
    action,
  )
