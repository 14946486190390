import React, { FC, memo, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { uniqueId, set, filter } from 'lodash/fp'
import useThunkDispatch from '../../common/useThunkDispatch'
import { IBILink } from '../../types/user'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import { requestStoreAccountLinks } from '../accountsActions'
import { detailedAccountSelector } from '../accountsSelectors'
import AccountLink, { IAccountLink } from './AccountLink'

interface IAccountLinksRouteParams {
  accountId: string
}

const initialState: IAccountLink[] = [
  { id: uniqueId('al_'), url: '', title: '' },
]

const AccountLinks: FC<RouteComponentProps<IAccountLinksRouteParams>> = ({
  match,
  history,
}) => {
  const accountId = Number(match.params.accountId)
  const dispatch = useThunkDispatch()
  const account = useSelector(detailedAccountSelector)

  const handleClose = useCallback(() => {
    history.push(`/clients/${accountId}`)
  }, [])

  const handleSubmit = useCallback(values => {
    const submit = async () => {
      const error = await dispatch(
        requestStoreAccountLinks(
          accountId,
          values.links.filter((l: IBILink) => !!l.title && !!l.url),
        ),
      )
      if (!error) handleClose()
    }

    submit()
  }, [])

  return (
    <Modal onClose={handleClose} title="BI Links">
      <Formik
        initialValues={{
          links:
            (account && account.biLinks.length && account.biLinks) ||
            initialState,
        }}
        enableReinitialize
        validate={values => {
          // if it's empty list with all unfilled
          if (
            values.links.length === 1 &&
            !values.links[0].title &&
            !values.links[0].url
          )
            return {}
          const isEmpty = values.links.filter(
            (link: IAccountLink) => !link.title || !link.url,
          ).length
          // if we have some unfilled values
          if (isEmpty) return { links: 'Required' }
          return {}
        }}
        onSubmit={handleSubmit}
        render={({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            {values.links.map((link: IAccountLink, index: number) => (
              <AccountLink
                key={link.id}
                title={link.title}
                url={link.url}
                onChange={(field, value) => {
                  setFieldValue(
                    'links',
                    set(`${index}.${field}`, value, values.links),
                  )
                }}
                onDelete={() => {
                  const newLinks = filter(
                    (link: IAccountLink) => link.id !== values.links[index].id,
                    values.links,
                  )
                  setFieldValue(
                    'links',
                    newLinks.length ? newLinks : [...initialState],
                  )
                }}
              />
            ))}
            {!!values.links.length && <hr className="form_divider" />}
            <Button
              color="success"
              className="form_control -size"
              onClick={() => {
                setFieldValue('links', [
                  ...values.links,
                  { id: uniqueId('al_'), url: '', title: '' },
                ])
              }}
            >
              Add
            </Button>
            <div className="form_controls">
              <div className="form_controls-container">
                <Button
                  onClick={handleClose}
                  color="default-link"
                  className="form_control -size -right"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="success"
                  className="form_control -size"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default memo(AccountLinks)
