import * as React from 'react'
import cn from 'classnames'
import HandIcon from '../../icons/hand.svg'

interface IProps {
  kind?: 'danger'
}

const Alert: React.FunctionComponent<IProps> = ({ children, kind = 'danger' }) => (
  <div
    className={cn('alert', {
      '-red': kind === 'danger',
    })}
  ><HandIcon className="alert_icon" />
    <div className="alert_text">{children}</div>
  </div>
)

export default Alert
