import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HeaderRootMenu from './HeaderRootMenu'
import ClientBranchBreadcrumb from './ClientBranchBreadcrumb'
import ClientSettings from './ClientSettings'

const HeaderInternal = () => (
  <>
    <Route
      path="/clients/:accountId/(branches)?/:branchId?"
      component={ClientBranchBreadcrumb}
    />

    <div className="header_separator" />

    <Switch>
      <Route path="/clients/:accountId" component={ClientSettings} />
      <Route component={HeaderRootMenu} />
    </Switch>
  </>
)

export default HeaderInternal
