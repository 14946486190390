import React, { FC } from 'react'
import { format } from 'date-fns'

interface IOwnProps {
  state: any
}

const PlanState: FC<IOwnProps> = ({ state }) => (
  <div className="replcut_body -notice">
    {/* past */}
    {state.past && (
      <>
        {state.past.isSubmitted && (
          <div>
            Replenishment has been approved and shipped according the plan
          </div>
        )}
        {state.past.isSkipped && (
          <div>Replenishment hasn't been approved or shipped</div>
        )}
        {state.past.isUrgent && (
          <div style={{ color: '#E4922F' }}>
            Urgent shipment requested on{' '}
            {format(state.past.urgentRequestAt, 'MMM D')}
          </div>
        )}
      </>
    )}

    {/* current */}
    {state.current && (
      <>
        {state.current.isNotApproved && (
          <div>
            <div>
              Planned shipment on{' '}
              {format(state.current.plannedShippment, 'MMM D')}
            </div>
            <div style={{ color: '#E4922F' }}>
              Approve order by {format(state.current.approveBy, 'MMM D')} for
              shipment on planned date
            </div>
          </div>
        )}

        {state.current.isApproved && (
          <div>
            <div>Approved</div>
            <div>
              Planned shipment on{' '}
              {format(state.current.plannedShippment, 'MMM D')}
            </div>
          </div>
        )}

        {state.current.isRequested && (
          <div>
            <div>Requested</div>
            <div style={{ color: '#E4922F' }}>
              Planned shipment on{' '}
              {format(state.current.plannedShippment, 'MMM D')}
            </div>
          </div>
        )}

        {state.current.isRejected && (
          <div>
            <div>Rejected: {state.current.rejectedReason}</div>
            <div>
              Planned shipment on{' '}
              {format(state.current.plannedShippment, 'MMM D')}
            </div>
          </div>
        )}

        {state.current.isSubmitted && !state.current.isUrgent && (
          <div>
            <div>Order Received</div>
            <div>
              Planned shipment on{' '}
              {format(state.current.plannedShippment, 'MMM D')}
            </div>
          </div>
        )}

        {state.current.isUrgent && (
          <div style={{ color: '#E4922F' }}>
            Urgent shipment requested on{' '}
            {format(state.current.urgentRequestAt, 'MMM D')}
          </div>
        )}
      </>
    )}

    {/* future */}
    {state.future && (
      <>
        {state.future.isUpcoming && (
          <div>
            <div>
              Planned shipment on{' '}
              {format(state.future.plannedShippment, 'MMM D')}
            </div>
            <div style={{ color: '#E4922F' }}>
              Approve order by {format(state.future.approveBy, 'MMM D')} for
              shipment on planned date
            </div>
          </div>
        )}

        {state.future.isApproved && (
          <div>
            Approved
            <br />
            Planned shipment on {format(state.future.plannedShippment, 'MMM D')}
          </div>
        )}

        {state.future.isRejected && (
          <div>
            <div>Rejected: {state.future.rejectedReason}</div>
            <div>
              Planned shipment on{' '}
              {format(state.future.plannedShippment, 'MMM D')}
            </div>
          </div>
        )}
      </>
    )}
  </div>
)

export default PlanState
