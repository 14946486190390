import { filter } from 'lodash'

type SearchKey<T> = (keyof T) | ((i: T) => string)

export class SimpleIndex<T> {
  private collection: T[]
  private keys: Array<SearchKey<T>>

  constructor(collection: any[], options: { keys: Array<SearchKey<T>> }) {
    this.collection = collection
    this.keys = options.keys
  }

  public search(term: string) {
    if (!term) {
      return this.collection
    }

    return filter(this.collection, item =>
      this.keys
        .map(k => (typeof k === 'function' ? k(item) : item[k]))
        .join('')
        .toLowerCase()
        .includes(term.toLowerCase().trim())
    )
  }
}
