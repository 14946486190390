import { default as React, FunctionComponent, memo, useEffect, useRef } from 'react'
import { reduce } from 'lodash/fp'
import { IChartPoint } from '../../types/component'

interface IOwnProps {
  points: IChartPoint[]
  className?: string
  gradientFill?: string
}

const toPath = (points: number[][]) =>
  reduce((path, point) => `${path} L${point[0]} ${point[1]}`, '', points).replace(/L/, 'M')

const toGradientPath = (points: number[][]) =>
  points.length > 0
    ? `M${points[0][0]} 100` +
      reduce((path, point) => `${path} L${point[0]} ${point[1]}`, '', points) +
      ` L${points[points.length - 1][0]} 100 Z`
    : ''

function usePrevious<T = any>(value: T): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

const DEFAULT_Y = 90

const Line: FunctionComponent<IOwnProps> = ({ points, className, gradientFill }) => {
  const prevPoints = usePrevious(points) || points.map((p) => [p.x, DEFAULT_Y])
  const pointsList = points.map(p => [p.x, p.y])
  const currPath = toPath(pointsList)

  return (
    <g className={className} opacity={1}>
      <svg key={currPath}>
        <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path className="graph_line" d={currPath} vectorEffect="non-scaling-stroke" />
          {gradientFill && (
            <path
              className="graph_gradient"
              d={toGradientPath(pointsList)}
              vectorEffect="non-scaling-stroke"
              fill={`url(#${gradientFill})`}
              stroke="none"
            />
          )}
        </svg>
        {points.map((point, pos) => (
          <circle className="graph_dot" key={pos} cx={`${point.x}%`} cy={`${point.y}%`} data-tip={point.tooltip} data-for="chart" />
        ))}
      </svg>
    </g>
  )
}

export default memo(Line)
