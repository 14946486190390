import { handleActions } from 'redux-actions'
import { set } from 'lodash/fp'

import { IOrdersFilter, LOAD_ORDERS, SET_ORDERS_FILTER, SET_ORDERS_PAGINATION_STATE } from './ordersActions'
import { IPaginationState } from '../types/common'
import { IOrder } from '../types/orders'

const DEFAULT_STATE = {
  orders: [] as IOrder[],
  ordersFilter: { scope: 'upcoming' } as IOrdersFilter,
  paginationState: {} as IPaginationState,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [LOAD_ORDERS]: (state, { payload }: LOAD_ORDERS) => set('orders', payload, state),
    [SET_ORDERS_PAGINATION_STATE]: (state, { payload }: SET_ORDERS_PAGINATION_STATE) =>
      set('paginationState', payload, state),
    [SET_ORDERS_FILTER]: (state, { payload }: SET_ORDERS_FILTER) => set('ordersFilter', payload, state),
  },
  DEFAULT_STATE
)
