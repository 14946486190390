import { ComponentType, default as React } from 'react'
import { ListChildComponentProps } from 'react-window'
import { format } from 'date-fns'
import cn from 'classnames'
import OrderIcon from '../../orders/OrderIcon'
import name from '../../common/name'
import Tooltip from '../../components/Tooltip'
import { orderTotal } from '../../common/orders'
import OrderStatus from '../../orders/OrderStatus'

type IOrdersRow = ComponentType<ListChildComponentProps>

const OrdersRow: IOrdersRow = ({ data, index, style }) => {
  const order = data.orders[index]
  const { allBranches, multiCatalogEnabled } = data
  return (
    <div style={style}>
      <div
        className="table_tr -fixed-height -link"
        onClick={() => data.onClick(order)}
      >
        <div className="table_td -fix50 -hint">
          <OrderIcon order={order} />
          <div className="table_hint">
            {order.kind === 'replenish'
              ? 'Replenishment order'
              : 'Fill in order'}
          </div>
        </div>
        <div className="table_td -g20">
          {order.shipmentDateAt
            ? format(order.shipmentDateAt, 'MMM D, YYYY')
            : '-'}
        </div>
        {allBranches && (
          <div className="table_td -g25">
            {order.branch ? order.branch.name : '-'}
          </div>
        )}
        <div className="table_td -g25">
          <Tooltip id={`tooltip-updated-by-${order.id}`}>
            <div
              className="input_list-tooltip"
              data-tip={
                order.updatedAt
                  ? format(order.updatedAt, 'MMM D, YYYY HH:MM')
                  : '-'
              }
              data-for={`tooltip-updated-by-${order.id}`}
              data-effect="solid"
              data-place="bottom"
            >
              {name(order.updatedBy)}
            </div>
          </Tooltip>
        </div>
        <div className="table_td -g20">
          <div
            className={cn('table_text status -lines-2', {
              '-success': order.status === 'approved',
              '-danger': order.status === 'rejected',
            })}
          >
            {(order.status !== 'rejected' || !order.rejectReason) && (
              <OrderStatus order={order} short />
            )}
            {order.status === 'rejected' && order.rejectReason && (
              <Tooltip id={`tooltip-updated-by-${order.id}`}>
                <div
                  className="input_list-tooltip"
                  data-tip={order.rejectReason}
                  data-for={`tooltip-updated-by-${order.id}`}
                  data-effect="solid"
                  data-place="bottom"
                >
                  {<OrderStatus order={order} short />}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {multiCatalogEnabled && <div className="table_td -g20">{order.erpCatalogName || '-'}</div>}
        <div className="table_td -g20">{order.navReferenceId || '-'}</div>
        <div className="table_td -g20">{orderTotal(order)}</div>
      </div>
    </div>
  )
}

export default OrdersRow
