import { useCallback } from 'react'

export default (() => {
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  anchor.setAttribute('style', 'display: none;')

  return () =>
    useCallback((data: string, fileName: string): void => {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      const url = window.URL.createObjectURL(blob)

      anchor.href = url
      anchor.download = fileName
      anchor.click()
      URL.revokeObjectURL(url)
    }, [])
})()
