import React, { FC, memo, useState } from 'react'
import ModalWrapper from '../../../components/Modal/ModalWrapper'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import CrossIcon from '../../../icons/cross.svg'

interface IOwnProps {
  poNumber?: string
  onSave: (poNumber: string) => void
  onClose: () => void
}

const OrderPONumber: FC<IOwnProps> = ({
  poNumber: originalNumber,
  onSave,
  onClose,
}) => {
  const [poNumber, setPONumber] = useState<string>(originalNumber || '')
  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />
      <div className="import_dialog action-modal">
        <div className="action-modal_title">Update Order</div>
        <CrossIcon className="product-modal_close" onClick={onClose} />
        <div className="action-modal_info">PO number</div>
        <div className="action-modal_field">
          <Input value={poNumber} onChange={e => setPONumber(e.target.value)} />
        </div>
        <Button
          block
          color="success"
          disabled={!poNumber}
          onClick={() => onSave(poNumber)}
        >
          Update
        </Button>
        <Button
          block
          color="primary-link"
          className="action-modal_cancel"
          onClick={onClose}
        >
          Continue Editing
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default memo(OrderPONumber)

export const useOrderPONumberModal = ({
  poNumber,
  onSave,
}: {
  poNumber?: string
  onSave: (poNumber: string) => void
}): [{ open: () => void; close: () => void }, any] => {
  const [opened, setOpened] = useState<boolean>(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const modal = (
    <OrderPONumber poNumber={poNumber} onSave={onSave} onClose={close} />
  )
  return [{ open, close }, opened ? modal : null]
}
