import React, { FC, useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { ListChildComponentProps } from 'react-window'
import ClickOutside from 'react-click-outside'
import PlaceholderIcon from '../../components/OrderSnippet/placeholder.svg'
import BinIcon from '../../icons/bin.svg'
import { IOrderItem } from '../../types/orders'
import { money } from '../../common/display'
import StockState from './StockState'

const OrderRow: FC<ListChildComponentProps> = ({ data, index, style }) => {
  const [focused, setFocused] = useState<boolean>(false)
  const [amount, setAmount] = useState<string | null>(null)
  const orderItem: IOrderItem = data.orderItem || data.orderItems[index]

  const value = amount != null ? amount : orderItem.amountRequested
  const total = money(
    +(value || 0) * (orderItem.price || orderItem.product.price || 0)
  )
  const price = `${money(
    orderItem.price || orderItem.product.price || 0
  )} / ${orderItem.unit || orderItem.product.unit}`
  const isEditable = data.editable
  const multiCatalogEnabled = data.multiCatalogEnabled
  useEffect(() => {
    setAmount(orderItem.amountRequested.toString())
  }, [orderItem.amountRequested])
  const handleDelete = useCallback(() => {
    if (!confirm('Are you sure you want to remove the item from order?')) return
    data.onDelete(orderItem)
    setAmount('0')
  }, [data.onDelete, orderItem])
  const handleSubmit = useCallback(() => {
    data.onUpdate({ ...orderItem, amountRequested: +(amount || 0) })
  }, [data.onUpdate, amount, orderItem])
  return (
    <div className="order-modal_list-item" style={style}>
      <div className="order-modal_list-product order-snippet -small">
        <div className="order-snippet_col -info -large">
          <div className="order-snippet_image-wrapper">
            {orderItem.product.photo ? (
              <img
                className="order-snippet_image"
                src={orderItem.product.photo}
                alt=""
              />
            ) : (
              <PlaceholderIcon className="order-snippet_image" />
            )}
          </div>
          <div className="order-snippet_title">{orderItem.product.name}</div>
          <div className="order-snippet_info">
            <div className="order-snippet_sku">{orderItem.product.code}</div>
            {multiCatalogEnabled && <div className="order-snippet_erp-catalog">
              {orderItem.product.erpCatalogName}
            </div>}
          </div>
          <div className="order-snippet_price">{price}</div>
        </div>
        {isEditable && (
          <div className="order-snippet_action" onClick={handleDelete}>
            <BinIcon />
          </div>
        )}
      </div>
      <div
        className={cn('tile -bordered', {
          'clear-button': isEditable,
          '-done': !isEditable,
        })}
        onClick={() => !focused && isEditable && setFocused(true)}
      >
        {focused ? (
          <ClickOutside
            onClickOutside={() => {
              setFocused(false)
              handleSubmit()
            }}
            className="tile_count"
          >
            <input
              type="number"
              value={value.toString()}
              onChange={e => setAmount(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
              className="tile_count-input"
              autoFocus
            />
          </ClickOutside>
        ) : (
          <button className="tile_count-toggle clear-button">{value}</button>
        )}
        {isEditable &&
          orderItem.product.stockState &&
          orderItem.product.stockInventory !== undefined && (
            <StockState
              state={orderItem.product.stockState}
              inventory={orderItem.product.stockInventory}
            />
          )}
      </div>
      <div className="tile -done -bordered">{total}</div>
    </div>
  )
}

export default OrderRow
