import React, { FC, memo, useEffect, useState } from 'react'
import cn from 'classnames'

import { ITeam, IUser } from '../../../types/user'
import Toggle from '../../../components/Toggle'

interface IUserFormProps {
  user: IUser
  teams: ITeam[]
  onSave: (user: IUser | { password?: string }) => void
}

const TeamsForm: FC<IUserFormProps> = ({
  user: initialUser,
  teams,
  onSave,
}) => {
  const [user, setUser] = useState<IUser>({ ...initialUser })

  useEffect(() => setUser({ ...initialUser }), [initialUser])

  const userTeams = (user.teams || []).map(d => d.id)
  const teamOptions = [{ id: 'all', name: 'All teams' }, ...teams]

  return (
    <div className="user-form_field">
      <label className="input_label">Teams</label>
      {teamOptions.map(option => (
        <Toggle<string | number | boolean>
          className={cn({ 'user-form_all': option.id === 'all' })}
          value={
            option.id === 'all'
              ? teams.every(el => userTeams.includes(el.id))
              : userTeams.includes(option.id as number)
          }
          key={option.id}
          onChange={() => {
            let newTeams = userTeams.length === teams.length ? [] : teams
            if (option.id !== 'all') {
              if (
                (user.teams || []).map(d => d.id).includes(option.id as number)
              ) {
                newTeams = (user.teams || []).filter(d => d.id !== option.id)
              } else {
                newTeams = [...(user.teams || []), option as ITeam]
              }
            }
            setUser({ ...user, teams: newTeams })
            onSave({ ...user, teams: newTeams })
          }}
          option={{
            value: true,
            label: option.name,
          }}
        />
      ))}
    </div>
  )
}

export default memo(TeamsForm)
