import React, { FC, memo, useEffect, useState } from 'react'
import { format } from 'date-fns'

import { IInventoryEvent, IProduct } from '../../types/products'
import { IAccount, IBranch } from '../../types/user'
import Splash from '../../components/Splash'
import useThunkDispatch from '../../common/useThunkDispatch'
import { requestInventoryEvents } from './inventoryActions'
import Tooltip from '../../components/Tooltip'

interface IProductFormProps {
  product: IProduct
  branch: IBranch
  account?: IAccount
}

const stateMapping = {
  manual: 'Manual adjustment',
  transfer_to: 'Added from transfer',
  transfer_from: 'Transferred',
  shipment: 'Added from shipment',
  checkout: 'Checkout',
}

const InventoryEvents: FC<IProductFormProps> = ({
  product,
  branch,
  account,
}) => {
  const [loading, setLoading] = useState(true)
  const [inventoryEvents, setInventoryEvents] = useState<IInventoryEvent[]>([])

  const dispatch = useThunkDispatch()
  const branchId = branch && branch.id

  const loadInventoryEvents = async () => {
    setLoading(true)
    const list = await dispatch<Promise<IInventoryEvent[]>>(
      await requestInventoryEvents(
        product.id,
        branch.id,
        account && account.id
      )
    )
    setInventoryEvents(list || [])
    setLoading(false)
  }

  useEffect(() => {
    if (branchId) {
      loadInventoryEvents()
    }
  }, [product, branch])

  return (
    <div className="product-inventory">
      <Splash isLoading={loading} bgColor="#F2F6FA" />
      {!loading && !inventoryEvents.length && (
        <div className="list_placeholder">No history yet</div>
      )}
      {!loading &&
        inventoryEvents.map(inventoryEvent => (
          <div key={inventoryEvent.id} className="product-inventory_item">
            <div
              className={`product-inventory_amount ${inventoryEvent.amount <
                0 && '-negative'}`}
            >
              {inventoryEvent.amount >= 0
                ? `+${inventoryEvent.amount}`
                : inventoryEvent.amount}
            </div>
            <div className="product-inventory_stock_value">
              {inventoryEvent.stockValue} in stock ({inventoryEvent.unit})
            </div>
            <div className="product-inventory_date">
              {['transfer_to', 'transfer_from'].includes(
                inventoryEvent.eventType
              ) && (
                <Tooltip id={`tooltip-branch-${inventoryEvent.id}`}>
                  <div
                    className="input_label-tooltip"
                    style={{
                      bottom: 0,
                      textDecorationStyle: 'dotted',
                      textDecorationLine: 'underline',
                      marginLeft: -4,
                      cursor: 'pointer',
                    }}
                    data-tip={`${
                      inventoryEvent.eventType === 'transfer_to' ? 'from' : 'to'
                    } ${inventoryEvent.additional.branchName}, #${
                      inventoryEvent.additional.id
                    }`}
                    data-for={`tooltip-branch-${inventoryEvent.id}`}
                    data-effect="solid"
                    data-place="bottom"
                  >
                    {stateMapping[inventoryEvent.eventType]}
                  </div>
                  &nbsp;
                </Tooltip>
              )}
              {['manual', 'shipment', 'checkout'].includes(
                inventoryEvent.eventType
              ) && <>{stateMapping[inventoryEvent.eventType]}</>}
              {inventoryEvent.createdByUser &&
                inventoryEvent.createdByUser.id !== -1 && (
                  <>
                    &nbsp;by&nbsp;
                    {inventoryEvent.createdByUser.name}
                  </>
                )}
              ,&nbsp;
              <span style={{ whiteSpace: 'nowrap' }}>
                {format(inventoryEvent.eventCreatedAt, 'MMM DD, YYYY h:mm A')}
              </span>
            </div>
          </div>
        ))}
    </div>
  )
}

export default memo(InventoryEvents)
