import { default as React, FunctionComponent } from 'react'

import { Route, RouteComponentProps, Switch } from 'react-router'
import AccountsList from './AccountsList'
import AccountBranches from './accountBranches/AccountBranches'

const Accounts: FunctionComponent<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Route exact path="/clients" component={AccountsList} />
      <Route exact path="/clients/:accountId" component={AccountBranches} />
    </Switch>
  )
}

export default Accounts
