import {
  default as React,
  FunctionComponent,
  memo,
  useContext,
  useState,
} from 'react'
import { Simulate } from 'react-dom/test-utils'
import {
  IBranchForecast,
  IPlannedOrder,
  IPlannedOrderItem,
} from '../../types/orders'
import ClickOutside from 'react-click-outside'
import useThunkDispatch from '../../common/useThunkDispatch'
import { IProduct } from '../../types/products'
import Splash from '../../components/Splash'
import { PlanCellActionsContext } from '../Plan'
import input = Simulate.input

interface IOwnProps {
  product: IProduct
  month: IPlannedOrder
  replenish?: IPlannedOrderItem & { order: IPlannedOrder }
  forecast?: IBranchForecast
  onClose: () => void
}

const PlanTableCellForm: FunctionComponent<IOwnProps> = ({
  month,
  product,
  replenish,
  forecast,
  onClose,
}) => {
  const dispatch = useThunkDispatch()

  const [amount, setAmount] = React.useState<string | null>(null)
  const [updating, setUpdating] = useState(false)

  const { requestUpdateOrderItem, requestCreateOrderItem } = useContext(
    PlanCellActionsContext
  )

  const onSubmit = async () => {
    if (amount === null) return onClose()

    setUpdating(true)
    if (replenish) {
      await dispatch(
        requestUpdateOrderItem(replenish.order, {
          id: replenish.id,
          amountRequested: Number(amount),
        })
      )
    } else {
      await dispatch(
        requestCreateOrderItem(month, {
          amountRequested: Number(amount),
          productId: product.id,
        })
      )
    }
    onClose()
  }

  return (
    <ClickOutside onClickOutside={onSubmit} className="tile_count">
      <Splash isLoading={updating}>
        <input
          type="number"
          value={
            amount === null
              ? String(replenish && replenish.amountRequested)
              : amount
          }
          onChange={e => setAmount(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') onSubmit()
          }}
          className="tile_count-input"
          autoFocus
        />

        {forecast && (
          <div className="tile_count-controls">
            <button
              onClick={() => setAmount(String(forecast.amountMinSuggested))}
              className="tile_count-control clear-button"
              type="button"
            >
              {forecast.amountMinSuggested}
            </button>
            <div className="replcut -notice tile_count-hint">
              Low range forecast.
              <br />
              Click to apply.
            </div>
            <button
              onClick={() => setAmount(String(forecast.amountMaxSuggested))}
              className="tile_count-control clear-button"
              type="button"
            >
              {forecast.amountMaxSuggested}
            </button>
            <div className="replcut -notice tile_count-hint -right">
              High range forecast.
              <br />
              Click to apply.
            </div>
          </div>
        )}
      </Splash>
    </ClickOutside>
  )
}

export default memo(PlanTableCellForm)
