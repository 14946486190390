import React, { FC, useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import useThunkDispatch from '../../common/useThunkDispatch'
import Splash from '../../components/Splash'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import { IAccount, IBranch } from '../../types/user'
import { IInboundInventory } from '../../types/common'
import { requestInboundInventory } from '../managerActions'
import ShipmentDetails from './shipmentDetials'
import TransferDetails from './transferDetails'

interface IOrderRouteParams
  extends RouteComponentProps<{
    inboundInventoryId: string
    accountId?: string
    branchId?: string
  }> {
  account: IAccount
  branch?: IBranch
}

const InboundInventory: FC<IOrderRouteParams> = ({
  account,
  branch,
  match,
  history,
}) => {
  const dispatch = useThunkDispatch()
  const id = Number(match.params.inboundInventoryId)
  const [inboundInventory, setInboundInventory] = useState<
    IInboundInventory | undefined
  >(undefined)

  // load
  useEffect(() => {
    const perform = async () => {
      const inboundInventory: IInboundInventory = await dispatch(
        requestInboundInventory(id)
      )
      setInboundInventory(inboundInventory)
    }

    perform()
  }, [id])

  // on back
  const handleBack = useCallback(() => {
    const link = match.params.accountId
      ? `/clients/${match.params.accountId}/branches/${match.params.branchId}/inbound_inventories`
      : '/inbound_inventories'
    history.push(link)
  }, [account, branch])

  if (!inboundInventory) return <Splash isLoading />

  return (
    <>
      {inboundInventory.recordType === 'invoice' ? (
        <ShipmentDetails
          onClose={handleBack}
          inboundInventory={inboundInventory}
          onSave={val => setInboundInventory(val)}
        />
      ) : (
        <TransferDetails
          onClose={handleBack}
          inboundInventory={inboundInventory}
          onSave={(val: IInboundInventory) => setInboundInventory(val)}
        />
      )}
    </>
  )
}

export default withAccountAndBranch(InboundInventory)
