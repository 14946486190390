import * as React from 'react'
import cn from 'classnames'
import LoadingIcon from '../../icons/loading.svg'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  withWrapper?: boolean
  bgColor?: string
  isLoading?: boolean
  size?: 'small'
}
const Splash: React.FunctionComponent<IProps> = ({
  withWrapper,
  children,
  isLoading,
  bgColor = '#fff',
  className,
  size,
  style,
  ...props
}) => (
  <div
    className={cn(className, {
      splash_wrapper: withWrapper,
    })}
    {...props}
  >
    {children}
    {isLoading && (
      <div className={cn('splash', `-${size}`)} style={{ backgroundColor: bgColor, ...style }}>
        <LoadingIcon className="splash_icon" />
      </div>
    )}
  </div>
)

export default Splash
