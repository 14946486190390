import React, { useEffect } from 'react'
import { useState, ChangeEvent } from 'react'
import { uniqueId } from 'lodash'
import SearchIcon from '../../icons/lens.svg'
import LoadingIcon from '../../icons/loading.svg'
import ClearIcon from '../../icons/clear.svg'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  searching?: boolean
}

const Search: React.FunctionComponent<IProps> = ({
  placeholder = 'Search',
  id = uniqueId(),
  searching = false,
  value,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string>('')
  const inputField = React.useRef<HTMLInputElement>(null)

  const clear = () => {
    const clearEvent = { target: { value: '' } } as ChangeEvent<
      HTMLInputElement
    >
    handleChange(clearEvent)
    if (inputField.current) {
      inputField.current.focus()
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(String(e.target.value))
    if (props.onChange) {
      props.onChange(e)
    }
  }

  useEffect(() => {
    setCurrentValue(value ? String(value) : '')
  }, [value])

  return (
    <div className="search">
      {searching ? (
        <div className="search_indication">
          <LoadingIcon className="splash_icon" />
        </div>
      ) : (
        <label htmlFor={id} className="search_label">
          <SearchIcon />
        </label>
      )}
      <div className="search_input">
        <input
          {...props}
          placeholder={placeholder}
          type="text"
          id={id}
          onChange={handleChange}
          ref={inputField}
          value={currentValue}
        />
        {currentValue && !!currentValue.length && <ClearIcon onClick={clear} />}
      </div>
      <div className="search_border-indication" />
    </div>
  )
}

export default Search
