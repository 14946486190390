import React, { FC, memo, useState, useCallback, useEffect } from 'react'
import { IProduct } from '../../types/products'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import Input from '../../components/Input'
import ArrowLongIcon from '../../icons/arrow_long.svg'
import { IHash } from '../../types/common'
import CrossIcon from '../../icons/cross.svg'
import Button from '../../components/Button'

interface IOwnProps {
  product: IProduct
  errors: IHash<string>
  opened: boolean
  onChange: (props: IProduct) => void
  onClose: () => void
}

const RepackingModal: FC<IOwnProps> = ({
  product: initialProduct,
  errors,
  opened,
  onChange,
  onClose,
}) => {
  const [product, setProduct] = useState<IProduct>()

  useEffect(() => {
    const isRepacked =
      initialProduct.quantity !== undefined && initialProduct.quantity > 1

    setProduct({
      ...initialProduct,
      unit: isRepacked ? initialProduct.unit : undefined,
    })
  }, [initialProduct])

  const handleSave = () => {
    if (!product) {
      return
    }

    let unit = product.unit
    if (initialProduct.originalProductData && product.quantity === 1) {
      unit = initialProduct.originalProductData.unit
    }

    onChange({ ...product, unit })
    onClose()
  }

  // on close
  const handleClose = useCallback(() => {
    onClose()
  }, [])

  if (!opened || !product) return null

  return (
    <ModalWrapper className="repacking-modal">
      <button
        className="repacking-modal_backdrop clear-button"
        onClick={handleClose}
      />
      <div className="repacking-modal_dialog repacking-modal-modal -sm">
        <div className="product-modal_title">Repackaging settings</div>
        <CrossIcon className="product-modal_close" onClick={handleClose} />
        <div className="repacking-modal-info">
          Change this settings if you distribute the product to technicians in a
          unit different from the one you purchased originally. This would help
          the system to automatically adjust inventory stocks when you purchase
          and forecast future purchases
        </div>
        <div className="product-modal_hint">
          Fill in the form to recalculate stock and prices
        </div>
        <div className="repacking-modal_form">
          <div className="product-info_pricing">
            <Input value={1} disabled />

            <div
              className="product-info_arrow text-center"
              style={{ marginTop: -10 }}
            >
              <span className="product-info_text">
                original unit
                <br />
                becomes
              </span>
              <ArrowLongIcon />
            </div>

            <Input
              value={product.quantity}
              error={errors.quantity}
              onChange={e => {
                const quantity = parseInt(
                  e.target.value.replace(/\D/g, '') || '0',
                  10
                )
                const price = initialProduct.originalProductData
                  ? +(
                      (initialProduct.originalProductData.price || 0) /
                      (quantity || 1)
                    ).toFixed(2)
                  : product.price
                setProduct({ ...product, quantity, price })
              }}
            />
          </div>

          <div className="product-info_pricing">
            <Input
              label="Purchased unit of measure"
              value={
                initialProduct.originalProductData &&
                initialProduct.originalProductData.unit
              }
              // tooltip="Unit of Measure is specified by Forshaw<br />and can’t be changed here"
              disabled
            />

            <div className="product-info_arrow">
              <ArrowLongIcon />
            </div>
            <Input
              label={
                <>
                  Repackaged unit of measure&nbsp;
                  <span style={{ color: 'red' }}>*</span>
                </>
              }
              value={product.unit}
              error={errors.unit || !product.unit}
              onChange={e => setProduct({ ...product, unit: e.target.value })}
              required
            />
          </div>

          <div className="product-info_pricing">
            <Input
              label="Purchased unit price, $"
              value={
                initialProduct.originalProductData &&
                initialProduct.originalProductData.price
              }
              // tooltip="Price is specified by Forshaw<br />and can’t be changed here"
              disabled
            />

            <div className="product-info_arrow">
              <ArrowLongIcon />
            </div>
            <Input
              label="Repackaged unit price, $"
              error={errors.price}
              value={product.price}
              disabled
              // tooltip="Checkout Unit Price is calculated automatically from the Forshaw
              //   <br />price according to repacking configuration"
            />
          </div>
        </div>

        <Button block onClick={handleSave} disabled={!product.unit}>
          UPDATE
        </Button>
        <a
          onClick={handleClose}
          className="repacking-modal_cancel primary-link"
        >
          Cancel
        </a>
      </div>
    </ModalWrapper>
  )
}

export default memo(RepackingModal)

export const useRepackingModal = ({
  product,
  errors,
  onChange,
}: {
  product: IProduct
  errors: IHash<string>
  onChange: (props: IProduct) => void
}): [{ open: () => void; close: () => void }, any] => {
  const [opened, setOpened] = useState<boolean>(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const modal = (
    <RepackingModal
      product={product}
      errors={errors}
      opened={opened}
      onChange={onChange}
      onClose={close}
    />
  )
  return [{ open, close }, modal]
}
