import React, { FC, memo, useState } from 'react'
import ModalWrapper from '../../../components/Modal/ModalWrapper'
import Button from '../../../components/Button'
import CrossIcon from '../../../icons/cross.svg'

interface IOwnProps {
  onReopen: () => void
  onClose: () => void
}

const OrderReopen: FC<IOwnProps> = ({ onReopen, onClose }) => (
  <ModalWrapper className="import">
    <button className="import_backdrop clear-button" onClick={onClose} />
    <div className="import_dialog action-modal">
      <div className="action-modal_title">Reopen Order</div>
      <CrossIcon className="product-modal_close" onClick={onClose} />
      <div className="action-modal_info">
        Are you sure you want to reopen the order?
      </div>
      <div className="action-modal_actions">
        <Button block color="warning" onClick={onReopen}>
          Reopen Order
        </Button>
        <Button
          block
          color="primary-link"
          className="action-modal_cancel"
          onClick={onClose}
        >
          Continue Editing
        </Button>
      </div>
    </div>
  </ModalWrapper>
)

export default memo(OrderReopen)

export const useOrderReopenModal = ({
  onReopen,
}: {
  onReopen: () => void
}): [{ open: () => void; close: () => void }, any] => {
  const [opened, setOpened] = useState<boolean>(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const modal = <OrderReopen onReopen={onReopen} onClose={close} />
  return [{ open, close }, opened ? modal : null]
}
