import React, { FC, memo, useEffect, useState } from 'react'
import cn from 'classnames'

import { IDivision, IUser } from '../../../types/user'
import Toggle from '../../../components/Toggle'

interface IUserFormProps {
  user: IUser
  divisions: IDivision[]
  onSave: (user: IUser | { password?: string }) => void
}

const DivisionsForm: FC<IUserFormProps> = ({
  user: initialUser,
  divisions,
  onSave,
}) => {
  const [user, setUser] = useState<IUser>({ ...initialUser })

  useEffect(() => setUser({ ...initialUser }), [initialUser])

  const userDivisions = (user.divisions || []).map(d => d.id)
  const divisionOptions = [{ id: 'all', name: 'All divisions' }, ...divisions]

  return (
    <div className="user-form_field">
      <label className="input_label">Divisions</label>
      {divisionOptions.map(option => (
        <Toggle<string | number | boolean>
          className={cn({ 'user-form_all': option.id === 'all' })}
          value={
            option.id === 'all'
              ? userDivisions.length === divisions.length
              : userDivisions.includes(option.id as number)
          }
          key={option.id}
          onChange={() => {
            let newDivisions =
              userDivisions.length === divisions.length ? [] : divisions
            if (option.id !== 'all') {
              if (
                (user.divisions || [])
                  .map(d => d.id)
                  .includes(option.id as number)
              ) {
                newDivisions = (user.divisions || []).filter(
                  d => d.id !== option.id
                )
              } else {
                newDivisions = [...(user.divisions || []), option as IDivision]
              }
            }
            setUser({ ...user, divisions: newDivisions })
            onSave({ ...user, divisions: newDivisions })
          }}
          option={{
            value: true,
            label: option.name,
          }}
        />
      ))}
    </div>
  )
}

export default memo(DivisionsForm)
