import React, { FC } from 'react'

interface IProps {
  state: string
  inventory: number
}

const StockState: FC<IProps> = ({ state, inventory }) => (
  <div className="stock-state">
    {state === 'InStock' && (
      <div className="in-stock">{inventory} in stock</div>
    )}
    {state === 'LowStock' && (
      <div className="low-stock">Low Stock ({inventory})</div>
    )}
    {state === 'OutOfStock' && <div className="out-of-stock">OUT OF STOCK</div>}
  </div>
)

export default StockState
