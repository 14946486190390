import React, { FC, memo, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IBranch } from '../../types/user'
import { managerAccountBranchesSelector } from '../managerSelectors'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import DropdownSelect from '../../components/DropdownSelect'
import Button from '../../components/Button'
import CrossIcon from '../../icons/cross.svg'

interface IOwnProps {
  branch: IBranch
  opened: boolean
  onUpdate: (branch: IBranch) => void
  onClose: () => void
}

const UserBranchMove: FC<IOwnProps> = ({
  branch: currentBranch,
  opened,
  onUpdate,
  onClose,
}) => {
  const branches: IBranch[] = useSelector(managerAccountBranchesSelector)
  const [branch, setBranch] = useState<IBranch | undefined>(currentBranch)

  useEffect(() => {
    setBranch(currentBranch)
  }, [currentBranch])

  const handleClose = useCallback(() => {
    onClose()
  }, [])

  const handleUpdate = () => {
    if (branch) {
      onUpdate(branch)
    }
    handleClose()
  }

  if (!opened || !branch) return null

  const options = branches.map(branch => ({
    value: branch.id,
    label: branch.name,
  }))

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={handleClose} />
      <div className="import_dialog user-modal -sm">
        <div className="user-modal_title">Move to Branch</div>
        <CrossIcon className="user-modal_close" onClick={handleClose} />
        <div className="user-modal_field">
          <DropdownSelect<number | undefined>
            label="Select branch"
            className="-md"
            value={branch.id}
            options={options}
            onChange={id => {
              setBranch(branches.find(b => b.id === id))
            }}
            dropdownValueClassName="input_input -flex"
          />
        </div>
        <Button
          color="primary"
          type="submit"
          className="user-modal_button"
          onClick={handleUpdate}
        >
          Move To Branch
        </Button>
        <div className="user-modal_cancel" onClick={handleClose}>
          Cancel
        </div>
      </div>
    </ModalWrapper>
  )
}

export default memo(UserBranchMove)
