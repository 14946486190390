import * as React from 'react'
import { FunctionComponent, memo, useMemo } from 'react'
import { Formik } from 'formik'
import { indexBy, map, reject, pick, sortBy } from 'lodash/fp'

import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Image from '../../components/Image'
import Select from '../../components/Select'
import Button from '../../components/Button'
import { IBranch, IDivision, IUser } from '../../types/user'
import useThunkDispatch from '../../common/useThunkDispatch'
import { removeAccountTechnician, updateAccountUser } from '../accountsActions'
import { nameableToOption } from '../../common/name'
import { useState } from 'react'
import extractErrors from '../../common/extractErrors'
import { compact } from 'lodash'

interface IOwnProps {
  user: Partial<IUser>
  branches: IBranch[]
  divisions: IDivision[]
  accountId: number
  onClose(): void
}

const AccountUserEdit: FunctionComponent<IOwnProps> = ({
  user,
  branches,
  divisions,
  accountId,
  onClose,
}) => {
  const dispatch = useThunkDispatch()

  const isTechnician = user.role === 'technician'

  const branchOptions = useMemo(
    () => sortBy('name', compact(map(nameableToOption, branches))),
    [branches]
  )
  const branchDict = useMemo(() => indexBy('id', branches), [branches])

  const divisionsOptions = useMemo(
    () => compact(map(nameableToOption, divisions)),
    [divisions]
  )
  const divisionsDict = useMemo(() => indexBy('id', divisions), [divisions])

  const filteredBranchOptions = (attrs: Partial<IUser>) => {
    const rejectMap = indexBy('id', attrs.branches)
    return reject(op => !!rejectMap[op.value], branchOptions)
  }

  const filteredDivisionOptions = (attrs: Partial<IUser>) => {
    const rejectMap = indexBy('id', attrs.divisions)
    return reject(op => !!rejectMap[op.value], divisionsOptions)
  }

  const onSubmit = async (attrs: Partial<IUser>, actions: any) => {
    actions.setSubmitting(true)
    try {
      const userAttributes: Partial<IUser> & { avatar?: File } = pick(
        ['id', 'role', 'name', 'email', 'branches', 'divisions'],
        attrs
      )
      if (user.avatar !== attrs.avatar)
        userAttributes.avatar = attrs.avatar as any

      const errors = await dispatch(
        updateAccountUser({ ...userAttributes, accountId })
      )
      if (!errors) onClose()
      else actions.setErrors(errors)
    } catch (e) {
      alert('Server Error')
    }
    actions.setSubmitting(false)
  }

  const [deleting, setDeleting] = useState(false)
  const onDelete = async () => {
    if (!user.id) return
    if (user.role !== 'technician') return
    if (!confirm('Are you sure you want to delete the user?')) return

    setDeleting(true)
    try {
      const errors = await dispatch(removeAccountTechnician(user.id))

      if (errors) alert(extractErrors(errors))
      else onClose()
    } catch (e) {
      alert('Server Error')
    }
    setDeleting(false)
  }

  return (
    <Modal onClose={onClose} title="Edit User">
      <Formik
        initialValues={user}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form_group"></div>
            <div className="upload-avatar">
              <label className="upload-avatar_circle">
                <input
                  type="file"
                  name="avatar"
                  className="upload-avatar_input"
                  onChange={e => setFieldValue('avatar', e.target.files![0])}
                />
                {values.avatar && (
                  <Image
                    className="upload-avatar_image"
                    src={values.avatar}
                    alt=""
                  />
                )}
              </label>
            </div>
            <div className="form_group">
              <Input
                disabled={!isTechnician}
                name="name"
                label="Name"
                error={errors.name}
                value={values.name || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form_group">
              <Input
                disabled={!isTechnician}
                error={errors.email || ''}
                name="email"
                label="Email"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <div className="form_group">
              <Select<number>
                label="Branch"
                isMulti={!isTechnician}
                options={filteredBranchOptions(values)}
                value={compact(map(nameableToOption, values.branches || []))}
                error={errors.branches}
                onChange={(selected: Array<{ value: number; label: string }>) =>
                  setFieldValue(
                    'branches',
                    []
                      .concat((selected as any) || [])
                      .map(
                        (value: { value: number }) => branchDict[value.value]
                      )
                  )
                }
              />
            </div>
            {user.role === 'technician' && (
              <div className="form_group">
                <Select<number>
                  label="Divisions"
                  isMulti
                  options={filteredDivisionOptions(values)}
                  value={compact(map(nameableToOption, values.divisions || []))}
                  error={errors.divisions}
                  onChange={(
                    selected: Array<{ value: number; label: string }>
                  ) =>
                    setFieldValue(
                      'divisions',
                      []
                        .concat((selected as any) || [])
                        .map(
                          (value: { value: number }) =>
                            divisionsDict[value.value]
                        )
                    )
                  }
                />
              </div>
            )}
            <div className="form_controls">
              <div className="form_controls-container">
                {user.id && user.role === 'technician' && (
                  <Button
                    disabled={deleting}
                    onClick={onDelete}
                    color="red-link"
                    className="form_control -left"
                  >
                    Delete user
                  </Button>
                )}
                <Button
                  disabled={isSubmitting}
                  onClick={onClose}
                  color="default-link"
                  className="form_control -size -right"
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="success"
                  className="form_control -size"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default memo(AccountUserEdit)
