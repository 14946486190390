import { default as React, FunctionComponent } from 'react'
import ArrowLeftIcon from '../icons/arrow_left.svg'
import ArrowRightIcon from '../icons/arrow_right.svg'

interface IOwnProps {
  year: number
  onNext?: () => void
  onPrev?: () => void
}

const YearPager: FunctionComponent<IOwnProps> = ({ year, onNext, onPrev }) => {
  return (
    <div className="pager">
      <button disabled={!onPrev} onClick={onPrev} className="pager_button clear-button link">
        <ArrowLeftIcon />
      </button>
      <div className="pager_title">{year}</div>
      <button disabled={!onNext} onClick={onNext} className="pager_button clear-button link">
        <ArrowRightIcon />
      </button>
    </div>
  )
}

export default YearPager
