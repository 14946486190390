import React, { FC, useEffect, useState } from 'react'
import { sortBy, compact } from 'lodash'
import { format } from 'date-fns'
import useThunkDispatch from '../../common/useThunkDispatch'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import Splash from '../../components/Splash'
import { IDestinationTransfer, IInboundInventory } from '../../types/common'
import { requestInboundInventoryApprove } from '../managerActions'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import CrossIcon from '../../icons/cross.svg'
import PlaceholderIcon from '../../components/OrderSnippet/placeholder.svg'
import ModalWrapper from '../../components/Modal/ModalWrapper'

interface IProps {
  onClose: () => void
  inboundInventory: IInboundInventory
  onSave: (val: IInboundInventory) => void
}

const TransferDetails: FC<IProps> = ({ inboundInventory, onSave, onClose }) => {
  const dispatch = useThunkDispatch()
  const [alertTransfer, setAlertTransfer] = useState<
    IDestinationTransfer | undefined
  >(undefined)
  const [approvingAmount, setApprovingAmount] = useState<{
    [key: number]: number
  }>({})
  const [approving, setApproving] = useState<boolean>(false)
  const [approvingAll, setApprovingAll] = useState<boolean>(false)
  const isEditable =
    inboundInventory &&
    !!(inboundInventory.transfers || []).filter(t => !t.approvedAt).length

  const [newProductOpened, setNewProductOpened] = useState<string[]>([])

  // load
  useEffect(() => {
    const newApprovingAmount: { [key: number]: number } = {}

    const transfers = inboundInventory.transfers || []

    transfers.forEach(s => {
      newApprovingAmount[s.id] = s.approvedAt
        ? s.approvedAmount
        : s.destinationAmount
    })
    setApprovingAmount(newApprovingAmount)
  }, [inboundInventory])

  // on order item remove
  const handleApprove = async (
    transfer: IDestinationTransfer,
    showNotification: boolean
  ) => {
    if (!!transfer.approvedAt) {
      return
    }
    if (!transfer.hasProduct && showNotification && transfer.product) {
      setNewProductOpened([transfer.product.name])
    }

    if (approvingAmount[transfer.id] > transfer.destinationAmount) {
      setAlertTransfer(transfer)
    } else {
      await saveTransferApprove(transfer)
    }
  }

  const saveTransferApprove = async (transfer: IDestinationTransfer) => {
    const { result, errors } = await dispatch(
      requestInboundInventoryApprove(
        inboundInventory.id,
        [transfer.id].map(id => ({
          transferId: Number(id),
          approvingAmount: approvingAmount[Number(id)],
        }))
      )
    )
    setApproving(false)
    setAlertTransfer(undefined)

    if (result) {
      onSave({ ...inboundInventory, ...result })
    } else if (errors) {
      // tslint:disable-next-line:no-console
      console.error(errors)
    }
  }

  const handleChangeAmount = (
    transfer: IDestinationTransfer,
    value: number
  ) => {
    if (!inboundInventory) {
      return
    }

    const newApprovingAmount = { ...approvingAmount, [transfer.id]: value }
    setApprovingAmount(newApprovingAmount)
  }

  const handleApproveAll = async () => {
    if (!inboundInventory) {
      return
    }
    const allTransferIds = Object.keys(approvingAmount)
    const alreadyApprovedTransferIds = compact(
      (inboundInventory.transfers || []).map(t =>
        t.approvedAt ? String(t.id) : null
      )
    )

    const transferIds = allTransferIds.filter(
      i => !alreadyApprovedTransferIds.includes(i)
    )

    if (!transferIds.length) {
      return
    }
    setApprovingAll(true)

    const { errors } = await dispatch(
      requestInboundInventoryApprove(
        inboundInventory.id,
        transferIds.map(id => ({
          transferId: Number(id),
          approvingAmount: approvingAmount[Number(id)],
        }))
      )
    )

    if (errors) {
      // tslint:disable-next-line:no-console
      console.error(errors)
      setApprovingAll(false)
    } else {
      window.location.reload()
    }
  }

  if (!inboundInventory) return <Splash isLoading />

  return (
    <>
      <Modal closeable={false} containerClassName="">
        <div className="order-modal">
          <div className="order-modal_head">
            <div className="order-modal_head-number form">
              <div className="order-modal_title">Transfer Details</div>
              <Link to="#" className="order-modal_back" onClick={onClose}>
                <CrossIcon width={15} height={16} />
              </Link>
            </div>
          </div>
          <div className="form">
            <div className="order-modal_info">
              <div>
                <div className="order-modal_label">Posting Date</div>
                <div className="order-modal_value">
                  {inboundInventory.invoiceDate
                    ? format(inboundInventory.invoiceDate, 'MM/DD/YYYY')
                    : 'n/a'}
                </div>
              </div>
              <div>
                <div className="order-modal_label">From</div>
                <div className="order-modal_value">
                  {inboundInventory.transfers
                    ? inboundInventory.transfers[0].sourceBranchName
                    : '-'}
                </div>
              </div>
              <div>
                <div className="order-modal_label">Sent by</div>
                <div className="order-modal_value">
                  {inboundInventory.transfers
                    ? inboundInventory.transfers[0].sourceAuthorName
                    : '-'}
                </div>
              </div>
              <div>
                <div className="order-modal_label">ID</div>
                <div className="order-modal_value">
                  {inboundInventory.navId.replace('Transfer_', 'TR_')}
                </div>
              </div>
            </div>
            <div className="order-modal_alert">
              Please compare the number of received items
              <br />
              with the amount in the invoice respecting original unit of measure
            </div>
            <div className="order-modal_list">
              {!inboundInventory ||
                (!inboundInventory.transfers && (
                  <div className="table">
                    <div className="table_head-tr">
                      <div className="table_head-tr table_td table_title">
                        No products found
                      </div>
                    </div>
                  </div>
                ))}
              {inboundInventory &&
                !!inboundInventory.transfers &&
                sortBy(inboundInventory.transfers, 'id').map(transfer => (
                  <div className="order-modal_list-item" key={transfer.id}>
                    {transfer.product ? (
                      <div className="order-modal_list-product order-snippet -small">
                        <div className="order-snippet_col -info -large">
                          <div className="order-snippet_image-wrapper">
                            {transfer.product.photo ? (
                              <img
                                className="order-snippet_image"
                                src={transfer.product.photo}
                                alt=""
                              />
                            ) : (
                              <PlaceholderIcon className="order-snippet_image" />
                            )}
                          </div>
                          <div className="order-snippet_title">
                            {transfer.product.name}
                          </div>
                          <div className="order-snippet_sku">
                            {transfer.product.code}
                          </div>
                          <div className="order-snippet_price">
                            ${transfer.product.price} / {transfer.product.unit}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>Product not exists</div>
                    )}
                    <div
                      className={cn('tile -bordered shipment-amount', {
                        'clear-button': isEditable,
                      })}
                    >
                      <input
                        value={approvingAmount[transfer.id]}
                        onChange={e =>
                          handleChangeAmount(
                            transfer,
                            e.target.value ? parseInt(e.target.value, 10) : 0
                          )
                        }
                        className="tile_count-input"
                        readOnly={!!transfer.approvedAt}
                        autoFocus
                      />
                      <span>{transfer.destinationAmount} transfered</span>
                    </div>
                    <div
                      className={cn(
                        'tile -done -bordered approve-button',
                        !!transfer.approvedAt && '-approved'
                      )}
                    >
                      <button
                        className="tile_count-toggle clear-button text-center"
                        disabled={!!transfer.approvedAt || !transfer.product}
                        onClick={() => handleApprove(transfer, true)}
                      >
                        {!!transfer.approvedAt ? 'Added' : 'Add to Inventory'}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="form_controls">
            <div className="order-modal_footer form_controls-container">
              <Button
                onClick={onClose}
                disabled={approving}
                color="muted"
                className="form_control -size -left"
              >
                Back
              </Button>

              {isEditable && (
                <>
                  <Button
                    type="submit"
                    color="success"
                    loading={approvingAll}
                    disabled={approvingAll}
                    className="form_control -size"
                    onClick={handleApproveAll}
                  >
                    Add ALL
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {!!newProductOpened.length && (
        <ModalWrapper className="notification">
          <button
            className="notification_backdrop clear-button"
            onClick={() => setNewProductOpened([])}
          />
          <div className="notification_dialog">
            <button
              className="notification_close clear-button link"
              onClick={() => setNewProductOpened([])}
            />
            <div className="import_title">
              <b>
                {newProductOpened.length > 1
                  ? 'New Products Added'
                  : 'New Product Added'}
              </b>
            </div>
            {newProductOpened.length > 1 ? (
              <p>
                <b>{`${newProductOpened.length} new products`}</b>
                &nbsp;were added to your Inventory as new items. Check the
                "Unallocated" section in the Inventory tab to assign these
                products to a Division for Technician use.
              </p>
            ) : (
              <p>
                <b>{newProductOpened[0]}</b>
                &nbsp;was added to your Inventory as a new item. Check the
                "Unallocated" section in the Inventory tab to assign this
                product to a Division for Technician use.
              </p>
            )}
            <Button block onClick={() => setNewProductOpened([])}>
              OK
            </Button>
          </div>
        </ModalWrapper>
      )}
      {alertTransfer && (
        <ModalWrapper className="notification">
          <button
            className="notification_backdrop clear-button"
            onClick={() => setAlertTransfer(undefined)}
          />
          <div className="notification_dialog">
            <button
              className="notification_close clear-button link"
              onClick={() => setAlertTransfer(undefined)}
            />
            <div className="import_title">
              <b>Amount Warning</b>
            </div>
            <p>
              You are about to confirm receiving&nbsp;
              <b>
                {approvingAmount[alertTransfer.id]}
                &nbsp;items of&nbsp;
                {alertTransfer.product ? alertTransfer.product.name : 'N/A'}.
              </b>
              <br />
              The expected amount according to the transfer is&nbsp;
              {alertTransfer.destinationAmount}
              &nbsp;items.
            </p>
            <p>
              Please confirm you specified the amount in the same units of
              measure as Foresight ships the product
            </p>
            <Button block onClick={() => saveTransferApprove(alertTransfer)}>
              Yes, ADD&nbsp;
              {approvingAmount[alertTransfer.id]}
              &nbsp;items
            </Button>
            <Button
              onClick={() => setAlertTransfer(undefined)}
              color="default-link"
              block
            >
              Cancel
            </Button>
          </div>
        </ModalWrapper>
      )}
    </>
  )
}

export default TransferDetails
