import React, { useState } from 'react'
import { FunctionComponent, memo, useCallback } from 'react'
import { Formik } from 'formik'

import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Button from '../../components/Button'
import DropdownSelect from '../../components/DropdownSelect'
import { IAccount, IBranch } from '../../types/user'
import useThunkDispatch from '../../common/useThunkDispatch'
import { updateAccountBranch, requestBranchDelete } from '../accountsActions'
import Toggle from '../../components/Toggle'
import { DAY, FREQUENCY } from './AccountBranchNew'

interface IOwnProps {
  account?: IAccount
  branch: IBranch
  onClose(changed?: any): void
}

const AccountBranchEdit: FunctionComponent<IOwnProps> = ({
  branch,
  onClose,
}) => {
  const dispatch = useThunkDispatch()
  const [loadingDelete, setLoadingDelete] = useState(false)

  // clear branch logic
  const deleteBranch = useCallback(async () => {
    if (
      window.confirm(
        // tslint:disable-next-line: max-line-length
        `You are about to delete the branch ${branch.name} with all underlying data. You will not be able to restore it. Are you sure you what to proceed?`
      )
    ) {
      try {
        setLoadingDelete(true)
        await dispatch(requestBranchDelete(branch.id))
        window.location.reload()
      } catch (e) {
        alert(e)
      }
      setLoadingDelete(false)
    }
  }, [branch.id])

  const onSubmit = async (attrs: IBranch, actions: any) => {
    if (
      attrs.id &&
      (attrs.replenishmentOrdinal !== branch.replenishmentOrdinal ||
        attrs.replenishmentWeekDay !== branch.replenishmentWeekDay ||
        attrs.showTechPrice !== branch.showTechPrice ||
        attrs.navId !== branch.navId ||
        attrs.name !== branch.name ||
        attrs.invoiceMappingRow !== branch.invoiceMappingRow ||
        attrs.address !== branch.address ||
        attrs.address2 !== branch.address2 ||
        attrs.city !== branch.city ||
        attrs.zip !== branch.zip ||
        attrs.state !== branch.state ||
        attrs.shipToCode !== branch.shipToCode ||
        attrs.shipmentApproveFlow !== branch.shipmentApproveFlow)
    ) {
      actions.setSubmitting(true)
      try {
        const updateAttrs: any = {
          id: attrs.id,
          name: attrs.name,
          navId: attrs.navId,
          address: attrs.address,
          address2: attrs.address2,
          city: attrs.city,
          zip: attrs.zip,
          state: attrs.state,
          replenishmentOrdinal: attrs.replenishmentOrdinal,
          replenishmentWeekDay: attrs.replenishmentWeekDay,
          showTechPrice: attrs.showTechPrice,
          invoiceMappingRow: attrs.invoiceMappingRow,
          shipmentApproveFlow: attrs.shipmentApproveFlow,
          shipToCode: attrs.shipToCode,
        }

        const errors = await dispatch(updateAccountBranch(updateAttrs))
        if (!errors) onClose(true)
        else {
          actions.setErrors(errors)
        }
      } catch (e) {
        alert(e && e.message ? e.message : 'Server Error')
      }
      actions.setSubmitting(false)
    } else {
      actions.setSubmitting(false)
    }
  }

  return (
    <Modal onClose={onClose} title="Branch Info">
      <Formik
        initialValues={branch}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          dirty,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form_group">
              <Input
                onChange={handleChange('name')}
                name="name"
                label="Name"
                value={values.name}
              />
            </div>
            <div className="form_group">
              <Input
                onChange={handleChange('navId')}
                name="nav_id"
                label="NAV ID"
                value={values.navId}
              />
            </div>
            <div className="form_group">
              <Input
                name="address"
                label="Address Line 1"
                onChange={handleChange('address')}
                value={values.address}
              />
            </div>
            <div className="form_group">
              <Input
                name="address2"
                label="Address Line 2"
                onChange={handleChange('address2')}
                value={values.address2}
              />
            </div>
            <div className="form_row">
              <div className="form_group form_col">
                <Input
                  name="city"
                  label="City"
                  onChange={handleChange('city')}
                  value={values.city}
                />
              </div>
              <div className="form_group form_col">
                <Input
                  name="state"
                  label="State"
                  onChange={handleChange('state')}
                  value={values.state}
                />
              </div>
              <div className="form_group form_col">
                <Input
                  name="zip"
                  label="ZIP"
                  onChange={handleChange('zip')}
                  value={values.zip}
                />
              </div>
            </div>
            <div className="form_group">
              <label className="input_label">Monthly replenishment on</label>

              <div className="form_row">
                <DropdownSelect<string | undefined>
                  value={values.replenishmentOrdinal}
                  options={FREQUENCY as any}
                  onChange={handleChange('replenishmentOrdinal')}
                  dropdownClassName="form_col input"
                  dropdownValueClassName="input_input -flex"
                />

                <DropdownSelect<string | undefined>
                  value={values.replenishmentWeekDay}
                  options={DAY as any}
                  onChange={handleChange('replenishmentWeekDay')}
                  dropdownClassName="form_col input"
                  dropdownValueClassName="input_input -flex"
                />
              </div>
            </div>
            <div className="form_group">
              <Input
                onChange={handleChange('shipToCode')}
                name="shipToCode"
                label="Ship To Code"
                defaultValue={values.shipToCode}
              />
            </div>
            <div className="form_group">
              <Input
                onChange={handleChange('invoiceMappingRow')}
                name="invoiceMappingRow"
                label="Invoices mapping"
                defaultValue={values.invoiceMappingRow}
              />
            </div>
            <div className="form_group">
              <Input
                name="sanaLoginManager"
                label="System manager"
                defaultValue={
                  values.sanaLoginManager
                    ? `${values.sanaLoginManager.name} (${values.sanaLoginManager.email})`
                    : ''
                }
                placeholder="Not specified"
                disabled
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.showTechPrice}
                onChange={() =>
                  handleChange('showTechPrice')(!values.showTechPrice)
                }
                option={{
                  value: true,
                  label: 'Show pricing for technicians',
                }}
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.shipmentApproveFlow}
                onChange={() =>
                  handleChange('shipmentApproveFlow')(
                    !values.shipmentApproveFlow
                  )
                }
                option={{
                  value: true,
                  label: 'Manual Shipment Approval',
                }}
              />
            </div>

            <div className="form_controls">
              <div className="form_controls-container">
                <Button
                  onClick={deleteBranch}
                  color="red-link"
                  loading={loadingDelete}
                  className="form_control -left"
                >
                  Delete
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={onClose}
                  loading={loadingDelete}
                  color="default-link"
                  className="form_control -size -right"
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting || loadingDelete}
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="success"
                  className="form_control -size"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default memo(AccountBranchEdit)
