import React, { FC, ReactNode, memo } from 'react'
import cn from 'classnames'
import RefreshIcon from './refresh.svg'
import FillInIcon from './fillin.svg'
import { IKindableOrder } from '../../types/orders'

export interface IOrderIconProps {
  order: IKindableOrder
  className?: string
}

const getIcon = (order: IKindableOrder): ReactNode => {
  switch (order.kind) {
    case 'replenish':
      return <RefreshIcon width={16} height={12} />
    case 'fill_in':
      return <FillInIcon width={19} height={17} />
  }
}

const OrderIcon: FC<IOrderIconProps> = ({ order, className }) => (
  <div className={cn('order-icon', className)}>{getIcon(order)}</div>
)

export default memo(OrderIcon)
