import * as React from 'react'
import cn from 'classnames'
import ModalWrapper from './ModalWrapper'
import CrossIcon from '../../icons/cross.svg'

interface IProps {
  title?: string
  closeable?: boolean
  onClose?: () => void
  containerClassName?: string
}

const Modal: React.FunctionComponent<IProps> = ({
  children,
  closeable = true,
  onClose,
  title,
  containerClassName = 'container',
}) => (
  <ModalWrapper className="modal">
    {/*<button className="clear-button modal_backdrop" onClick={onClose} />*/}

    {closeable && (
      <button className="clear-button link modal_close" onClick={onClose}>
        <CrossIcon />
      </button>
    )}

    <div className="modal_container">
      {title && <div className="modal_title">{title}</div>}
      <div className={cn('modal_dialog', { '-tiny': !closeable })}>
        <div className="modal_inner-dialog">
          <div className={containerClassName}>{children}</div>
        </div>
      </div>
    </div>
  </ModalWrapper>
)

export default Modal
