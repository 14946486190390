import { default as React, useState, FC } from 'react'
import { format, parse } from 'date-fns'

import Outside from '../../../icons/outside.svg'
import Tooltip from '../../../components/Tooltip'
import { IDetailsOrder } from '../../../types/orders'
import SubmittedModal from './modal'
import { SendApprove, SendResult } from './SendApprove'
import { requestOrderTrackingInfo } from '../ordersActions'

interface IProps {
  order: IDetailsOrder
}

const ShippingInfo: FC<IProps> = ({ order }) => {
  const [showModal, setShowModal] = useState(false)
  const [showSendApprove, setShowSendApprove] = useState<
    IDetailsOrder | undefined
  >()
  const [showSendResult, setShowSendResult] = useState(false)

  const trackStatuses = order.orderTrackingStatuses || []
  const hasShipments = !!trackStatuses.length
  const shipmentDelay =
    order.shipmentDateAt && parse(order.shipmentDateAt) < new Date()

  let tooltip = ''

  if (hasShipments && trackStatuses.length === 1) {
    tooltip = `
      <div style="width: 250px; font-size: 13px; font-weight: 400; color: #051350;">
        <div style="width: 250px; font-size: 16px; font-weight: bold; margin-bottom: 6px;">Shipment Details</div>
        <div style="margin-bottom: 10px">
          Click for shipment tracking details
        </div>
        <div>
        <span style="width: 120px; display: inline-block; margin-bottom: 10px">Shipping Date</span>
        ${
          trackStatuses[0].shippedAt
            ? format(trackStatuses[0].shippedAt, 'MMM D, YYYY')
            : '-'
        }
        </div>
        <div>
        <span style="width: 120px; display: inline-block;">Shipping Agent</span>
        ${trackStatuses[0].shippingAgent || '-'}
        </div>
      </div>
    `
  }

  if (hasShipments && trackStatuses.length > 1) {
    tooltip = `
      <div style="width: 250px; font-size: 13px; font-weight: 400; color: #051350;">
        <div style="font-size: 16px; font-weight: bold; margin-bottom: 6px;">
          Shipment Details
        </div>
        <div>
          Click for shipment tracking details
        </div>
      </div>
    `
  }

  if (!hasShipments && shipmentDelay) {
    tooltip = `
      <div style="width: 250px; font-size: 13px; font-weight: 400; color: #051350;">
        <div>
          Shipping may be delayed.
        </div>
        <div>
          Click for shipment tracking details
        </div>
      </div>
    `
  }

  const handleSendEmail = async () => {
    setShowSendApprove(undefined)
    await requestOrderTrackingInfo(order.id)
    setShowSendResult(true)
  }

  return (
    <>
      <Tooltip id={`tooltip-shipping-${order.id}`} className="shipping-tooltip">
        <div
          className="input_list-tooltip"
          data-tip={tooltip}
          data-for={`tooltip-shipping-${order.id}`}
          data-effect="solid"
          data-place="bottom"
          data-html
        >
          {hasShipments && trackStatuses.length > 1 && (
            <a
              style={{
                color: '#1063CD',
                textDecoration: 'none',
                borderBottom: '1px dotted #1063CD',
              }}
              onClick={() => setShowModal(true)}
            >
              {trackStatuses.length}
              &nbsp;shipments
            </a>
          )}
          {hasShipments &&
            trackStatuses.length === 1 &&
            trackStatuses[0].trackingUrl && (
              <a
                style={{
                  color: '#1063CD',
                  textDecoration: 'none',
                  borderBottom: '1px dotted #1063CD',
                }}
                target="_blank"
                href={trackStatuses[0].trackingUrl}
              >
                {trackStatuses[0].trackingNumber}
                &nbsp;
                <span style={{ bottom: -2, position: 'relative' }}>
                  <Outside />
                </span>
              </a>
            )}
          {hasShipments &&
            trackStatuses.length === 1 &&
            !trackStatuses[0].trackingUrl && (
              <a
                style={{
                  color: '#1063CD',
                  textDecoration: 'none',
                  borderBottom: '1px dotted #1063CD',
                }}
                target="_blank"
                onClick={() => setShowSendApprove(order)}
              >
                Request details
              </a>
            )}
          {!hasShipments && shipmentDelay && (
            <a
              style={{
                color: '#EA4968',
                borderBottom: '1px dotted #EA4968',
              }}
              target="_blank"
              onClick={() => setShowSendApprove(order)}
            >
              Est. Shipment on&nbsp;
              {order.shipmentDateAt
                ? format(order.shipmentDateAt, 'MMM D')
                : 'No shipping info'}
            </a>
          )}
          {!hasShipments && !shipmentDelay && (
            <span
              style={{
                color: '#757D9F',
              }}
            >
              Est. Shipment on&nbsp;
              {order.shipmentDateAt
                ? format(order.shipmentDateAt, 'MMM D')
                : 'No shipping info'}
            </span>
          )}
        </div>
      </Tooltip>
      {showModal && (
        <SubmittedModal
          order={order}
          onClose={() => setShowModal(false)}
          setShowSendApprove={setShowSendApprove}
        />
      )}
      {showSendApprove && (
        <SendApprove
          order={showSendApprove}
          onClose={() => setShowSendApprove(undefined)}
          onApprove={() => handleSendEmail()}
        />
      )}
      {showSendResult && (
        <SendResult onClose={() => setShowSendResult(false)} />
      )}
    </>
  )
}

export default ShippingInfo
