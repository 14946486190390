export interface IScaleProps {
  domain: [number, number]
  range: [number, number]
}

export type IScale = (input: number) => number

export default function(props: IScaleProps) {
  const [istart, istop] = props.domain
  const [ostart, ostop] = props.range

  return function scale(value: number): number {
    if (!istart && !istop) {
      return ostart
    }
    return ostart + (ostop - ostart) * ((value - istart) / (istop - istart))
  }
}
