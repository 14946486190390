import React, { FC, useCallback, useEffect, useState } from 'react'

import useThunkDispatch from '../../common/useThunkDispatch'
import name from '../../common/name'
import { requestManagerDivisions } from '../managerActions'
import Splash from '../../components/Splash'
import { IAccount, IBranch, IDivision } from '../../types/user'
import Edit from './Edit'
import EditIcon from '../../icons/pencil.svg'
import DeleteIcon from '../../icons/trash.svg'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import useClientTitle from '../../common/useClientTitle'
import { removeManagerDivision } from '../managerActions'
import extractErrors from '../../common/extractErrors'
import Button from '../../components/Button'

const ManagerDivisions: FC<{
  branch: IBranch
  account?: IAccount
}> = ({ branch, account }) => {
  // const accountId = account.id
  const dispatch = useThunkDispatch()
  const branchId = branch && branch.id

  const [editingDivision, setEditingDivision] = useState<Partial<
    IDivision
  > | null>(null)
  const closeDivisionEdit = useCallback(() => {
    setEditingDivision(null)
  }, [setEditingDivision])

  const [loading, setLoading] = useState(true)

  // common
  useClientTitle({ branch, account })

  const [divisions, setDivisions] = useState<IDivision[]>([])

  const [deleting, setDeleting] = useState(false)
  const onDelete = async (division: IDivision) => {
    if (!division.id) return
    if (!confirm('Are you sure you want to permanently delete the division?'))
      return

    setDeleting(true)
    try {
      const errors = await dispatch(removeManagerDivision(division.id))

      if (errors) alert(extractErrors(errors))
      loadDivisions(branchId)
    } catch (e) {
      alert('Server Error')
    }
    setDeleting(false)
  }

  // fetch/refetch catalog
  const loadDivisions = async (branchId: number): Promise<void> => {
    setLoading(true)
    const list = await dispatch<Promise<IDivision[]>>(
      requestManagerDivisions(branchId, account && account.id)
    )
    setDivisions(list)
    setLoading(false)
  }

  // initial load
  useEffect(() => {
    if (branchId) {
      loadDivisions(branchId)
    }
  }, [branch])

  return (
    <div className="container">
      <Splash withWrapper isLoading={loading} className="catalog -fixed-height">
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -g25 table_title" style={{ padding: 0 }}>
              Divisions
            </div>
            <div
              className="table_head-tr table_td table_title"
              style={{ margin: 0 }}
            >
              <Button
                type="button"
                block
                color="primary"
                onClick={() => setEditingDivision({})}
              >
                Add Division
              </Button>
            </div>
          </div>
          {divisions &&
            !!divisions.length &&
            divisions.map(division => (
              <div className="table_tr" key={division.id}>
                <div className="table_td -g25">{name(division)}</div>
                <div className="table_td -fix150 -text-right">
                  <button
                    className="clear-button -primary -link"
                    disabled={deleting}
                    onClick={() => onDelete(division)}
                    color="red-link"
                  >
                    <DeleteIcon />
                  </button>
                  <button
                    className="clear-button -primary -link"
                    onClick={e => {
                      e.preventDefault()
                      setEditingDivision(division)
                    }}
                  >
                    <EditIcon />
                    &nbsp;Edit
                  </button>
                </div>
              </div>
            ))}
          {!divisions ||
            (divisions && !divisions.length && (
              <div className="table_head-tr">
                <div className="table_head-tr table_td table_title">
                  No divisions found, use "Add +" button to create one
                </div>
              </div>
            ))}
        </div>

        {editingDivision && branch && (
          <Edit
            division={editingDivision}
            branchId={branch.id}
            onClose={closeDivisionEdit}
            onSave={() => loadDivisions(branchId)}
          />
        )}
      </Splash>
    </div>
  )
}

export default withAccountAndBranch(ManagerDivisions)
