import React from 'react'

import { distanceInWordsToNow, differenceInSeconds } from 'date-fns'

interface IProps {
  updatedAt?: string
  loading?: boolean
  saving: boolean
}

const DateUpdated = ({ updatedAt, loading, saving }: IProps) => {
  if (!updatedAt) {
    return <></>
  }

  const updatedWord = (updatedAt?: string) => {
    if (!updatedAt) {
      return ''
    }
    if (differenceInSeconds(new Date(), updatedAt) <= 60) {
      return 'Updated just now'
    }

    return `Updated ${distanceInWordsToNow(updatedAt)} ago`
  }

  return (
    <div className={`user-info_updated_at ${loading ? '-active' : ''}`}>
      {saving ? 'Saving...' : updatedWord(updatedAt)}
    </div>
  )
}

export default DateUpdated
