import { uniqBy } from 'lodash/fp'
import { IAccount } from '../types/user'
import { IProduct, IProductKind } from '../types/products'

export const accountProducts = (
  account: IAccount,
  kind: IProductKind,
): IProduct[] | undefined => {
  let products = account.catalog

  if (kind === 'all') products = account.products
  if (kind === 'checkoutable') products = account.productsCheckoutable
  if (kind === 'orderable') products = account.productsOrderable
  if (kind === 'account') products = account.accountProducts

  if (!products) return

  // TODO: move it to backend?
  return uniqBy('id', products)
}
