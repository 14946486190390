import { handleActions } from 'redux-actions'
import { set } from 'lodash/fp'
import { SET_META, SET_TOKEN, SET_USER } from './authActions'
import { IUser } from '../types/user'
import { IHash, IMeta } from '../types/common'

const DEFAULT_STATE: { token?: string, user?: IUser, meta?: IMeta } = {
  token: undefined,
  user: undefined,
  meta: undefined,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [SET_TOKEN]: (state, { payload: token }: SET_TOKEN) => set('token', token, state),
    [SET_USER]: (state, { payload: user }: SET_USER) => set('user', user, state),
    [SET_META]: (state, { payload: meta }: SET_META) => set('meta', meta, state),
  },
  DEFAULT_STATE
)
