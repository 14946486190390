import { default as React, FunctionComponent, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import BasePopover from '../../components/BasePopover'
import { Link } from 'react-router-dom'
import Gear from '../../icons/gear.svg'
import ClickOutside from 'react-click-outside'
import ImportDialog from '../../components/ImportDialog'
import {
  uploadShipments,
  uploadTechnicians,
} from '../../accounts/accountsActions'
import AsyncImportDialog from '../../components/AsyncImportDialog'

interface IRouteParams {
  accountId: string
}

const ClientSettings: FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const accountId = Number(match.params.accountId)
  const [open, setOpen] = useState(false)
  const [importTechniciansOpen, setImportTechniciansOpen] = useState(false)
  const [importShipmentsOpen, setImportShipmentsOpen] = useState(false)

  return (
    <>
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <BasePopover
          className="menu_popup -settings"
          isOpen={open}
          offset={20}
          body={
            <>
              <Link
                className="menu_popup-link"
                to={`/clients/${accountId}/settings`}
              >
                Account Settings
              </Link>
              <Link
                className="menu_popup-link"
                to={`/clients/${accountId}/users`}
              >
                Client Users
              </Link>
              <Link
                className="menu_popup-link"
                to={`/clients/${accountId}/links`}
              >
                BI Links
              </Link>
              <div className="menu_popup-divider" />
              <span
                className="menu_popup-link"
                onClick={() => setImportShipmentsOpen(true)}
              >
                Import Purchases History
              </span>
              <span
                className="menu_popup-link"
                onClick={() => setImportTechniciansOpen(true)}
              >
                Import Technicians
              </span>
            </>
          }
        >
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="clear-button link menu_toggle"
          >
            <Gear />
          </button>
        </BasePopover>
      </ClickOutside>
      {importTechniciansOpen && (
        <ImportDialog
          close={() => setImportTechniciansOpen(false)}
          action={(file: File) => uploadTechnicians(file, accountId)}
          templateName="import_technicians_template.csv"
          templateUrl="/import_technicians_template.csv"
          title="Import Technicians"
        />
      )}
      {importShipmentsOpen && (
        <AsyncImportDialog
          close={() => setImportShipmentsOpen(false)}
          action={(file: File) => uploadShipments(file, accountId)}
          templateName="import_shipments_template.csv"
          templateUrl="/import_shipments_template.csv"
          title="Import Purchases History"
        />
      )}
    </>
  )
}

export default ClientSettings
