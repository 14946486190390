import * as React from 'react'
import * as NumericInput from 'react-numeric-input'
import cn from 'classnames'
import { uniqueId } from 'lodash'
import { RefObject } from 'react'

import Tooltip from '../Tooltip'
import InfoCircleIcon from '../../icons/info_circle.svg'

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>
  label?: string | React.ReactNode
  tooltip?: string
  error?: string | boolean
  caliber?: 'md' | 'sm'
  numeric?: boolean
  wrapperClassName?: string
}

const Input: React.FunctionComponent<IProps> = React.forwardRef(
  (
    {
      label,
      wrapperClassName,
      tooltip,
      error,
      id = uniqueId(),
      caliber = 'md',
      numeric = false,
      ...props
    },
    ref
  ) => {
    const className = cn('input_input', `-${caliber}`, {
      '-error': error,
    })
    return (
      <div className={wrapperClassName || 'input'}>
        <label htmlFor={id} className="input_label">
          {label}
          {tooltip && (
            <Tooltip id={`tooltip-${id}`}>
              <div
                className="input_label-tooltip"
                data-tip={tooltip}
                data-for={`tooltip-${id}`}
                data-effect="solid"
                data-place="top"
              >
                <InfoCircleIcon />
              </div>
            </Tooltip>
          )}
        </label>
        {!numeric && (
          <input ref={ref} id={id} className={className} {...props} />
        )}
        {numeric && (
          <NumericInput
            id={id}
            className={className}
            strict
            {...(props as any)}
            style={false}
          />
        )}
        {error && <div className="input_error">{error}</div>}
      </div>
    )
  }
)

export default Input
