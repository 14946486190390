import React, { FC, memo, ReactNode, useEffect, useState, useRef } from 'react'
import cn from 'classnames'

interface GridLayoutProps {
  className?: string
  offset?: number
  children: ReactNode[]
}

const GridLayout: FC<GridLayoutProps> = ({
  className,
  offset = 0,
  children,
}) => {
  const [vh, setVh] = useState<number | undefined>(undefined)
  const layout = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let currentVh =
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0) -
      offset
    if (layout.current) {
      const left = layout.current.querySelector('div:first-child > div')
      if (left) {
        const { height = 0 } = left.getBoundingClientRect()
        currentVh = Math.max(currentVh, height)
      }
    }
    if (vh !== currentVh) {
      setVh(currentVh)
    }
  }, [children, layout.current])
  return (
    <div ref={layout} className={cn('grid-layout', className)}>
      {children.map((child, idx) => (
        <div key={idx}>
          <div
            style={
              idx === 1 && vh != null
                ? { height: `${vh}px`, maxHeight: `${vh}px`, overflow: 'auto' }
                : {}
            }
          >
            {child}
          </div>
        </div>
      ))}
    </div>
  )
}

export default memo(GridLayout)
