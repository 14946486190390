import { handleActions } from 'redux-actions'
import { set } from 'lodash/fp'
import { IUser } from '../types/user'
import { SET_USERS } from './usersActions'

const DEFAULT_STATE = {
  users: [] as IUser[],
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [SET_USERS]: (state, { payload }: SET_USERS) => set('users', payload, state),
  },
  DEFAULT_STATE
)
