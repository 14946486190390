import React, { FC, memo, useState } from 'react'
import ModalWrapper from '../../../components/Modal/ModalWrapper'
import Button from '../../../components/Button'
import CrossIcon from '../../../icons/cross.svg'

interface IOwnProps {
  onReject: (reason: string) => void
  onClose: () => void
}

const OrderReject: FC<IOwnProps> = ({ onReject, onClose }) => {
  const [reason, setReason] = useState<string>('')
  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />
      <div className="import_dialog action-modal">
        <div className="action-modal_title">Reject Order</div>
        <CrossIcon className="product-modal_close" onClick={onClose} />
        <div className="action-modal_info">Reject Reason</div>
        <div className="input action-modal_field">
          <textarea
            className="input_textarea -sm"
            rows={4}
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </div>
        <Button
          block
          color="danger"
          disabled={!reason}
          onClick={() => onReject(reason)}
        >
          Reject Order
        </Button>
        <Button
          block
          color="primary-link"
          className="action-modal_cancel"
          onClick={onClose}
        >
          Continue Editing
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default memo(OrderReject)

export const useOrderRejectModal = ({
  onReject,
}: {
  onReject: (reason: string) => void
}): [{ open: () => void; close: () => void }, any] => {
  const [opened, setOpened] = useState<boolean>(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const modal = <OrderReject onReject={onReject} onClose={close} />
  return [{ open, close }, opened ? modal : null]
}
