export type Nameable =
  | { name: string }
  | { firstName: string; lastName: string }
function isName(nameable: Nameable): nameable is { name: string } {
  return !!(nameable as { name: string }).name
}
function isFirstLastName(nameable: Nameable): nameable is { name: string } {
  return !!(nameable as { name: string }).name
}

function name(nameable?: Nameable): string {
  if (!nameable) return '-'
  if (isName(nameable)) {
    if (nameable.name === 'System user') {
      return '-'
    }
    return nameable.name
  } else return `${nameable.firstName} ${nameable.lastName}`
}

export function nameableToOption(
  model: Nameable & { id: number } | undefined
): { value: number; label: string } | undefined {
  if (!model) {
    return
  }

  return { value: model.id, label: name(model) }
}

export default name
