import React, { FC, memo, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import { filter as fpFilter } from 'lodash/fp'
import { startOfMonth, addMonths, subMonths, format } from 'date-fns'

import Search from '../../components/Search'
import FillInIcon from '../../components/OrderIcon/fillin.svg'
import Button from '../../components/Button'
import { ICursoryOrder } from '../../types/orders'
import Toggle from '../../components/Toggle'
import * as storage from '../../common/storage'

import Tooltip from '../../components/Tooltip'
import InfoCircleIcon from '../../icons/info_circle.svg'

import ArrowLeftIcon from '../../icons/arrow_left.svg'
import ArrowRightIcon from '../../icons/arrow_right.svg'
import { IOrdersFilter } from './ordersActions'

interface IOrdersFilterProps {
  loading?: boolean
  fillInOrder?: ICursoryOrder
  branchSelected?: boolean
  onClickFillIn: () => void
  onChange: (term: string, filter: IOrdersFilter) => void
}

export const FILTER_STORAGE_KEY = 'manager.orders.filter'

const OrdersFilter: FC<IOrdersFilterProps> = ({
  loading,
  onChange,
  fillInOrder,
  branchSelected,
  onClickFillIn,
}) => {
  const [term, setTerm] = useState<string>('')
  const [filter, setFilter] = useState<IOrdersFilter>(
    JSON.parse(storage.get(FILTER_STORAGE_KEY, '{"scope":"manageable"}'))
  )
  const period = filter.period || format(startOfMonth(new Date()), 'YYYY-MM-DD')

  // search by term
  useDebounce(
    () => {
      onChange(term, filter)
    },
    300,
    [term]
  )

  // filter changed
  const updateFilter = (newFilter: IOrdersFilter) => {
    setFilter(newFilter)
    onChange(term, newFilter)
    storage.set(FILTER_STORAGE_KEY, JSON.stringify(newFilter))
  }

  const nextMonth = () => {
    updateFilter({
      ...filter,
      period: format(addMonths(period, 1), 'YYYY-MM-DD'),
    })
  }

  const prevMonth = () => {
    updateFilter({
      ...filter,
      period: format(subMonths(period, 1), 'YYYY-MM-DD'),
    })
  }

  const handleFilterScopeChange = (value: 'manageable' | 'submitted') => {
    updateFilter({ ...filter, scope: value, period: undefined })
  }

  return (
    <div className="filter">
      <div className="filter_col -large">
        <Search
          searching={loading}
          placeholder="Search by PO Number or Requested by"
          value={term}
          onChange={e => setTerm(e.target.value)}
        />
      </div>
      {filter.scope === 'submitted' ? (
        <div className="filter_col" style={{ marginRight: 30 }}>
          <div className="pager">
            <button
              disabled={loading}
              onClick={prevMonth}
              className="pager_button clear-button link"
            >
              <ArrowLeftIcon />
            </button>
            <div className="pager_title">{format(period, 'MMM, YYYY')}</div>
            <button
              disabled={loading}
              onClick={nextMonth}
              className="pager_button clear-button link"
            >
              <ArrowRightIcon />
            </button>
          </div>
        </div>
      ) : (
        <div className="filter_col">
          <Button
            block
            color="warning-link"
            onClick={branchSelected ? onClickFillIn : () => ({})}
          >
            {branchSelected && (
              <>
                <FillInIcon width={19} height={17} />
                <span>&nbsp;Fill-In order&nbsp;</span>(
                {fillInOrder
                  ? fpFilter(i => i.amountRequested > 0, fillInOrder.orderItems)
                      .length
                  : 0}
                )
              </>
            )}
            {!branchSelected && (
              <Tooltip id={`tooltip-no-branch`}>
                <div
                  className="input_label-tooltip"
                  data-tip="Please select a branch to place a Fill-In order"
                  data-for={`tooltip-no-branch`}
                  data-effect="solid"
                  data-place="top"
                >
                  <FillInIcon width={19} height={17} />
                  <span style={{ position: 'relative', top: '-5px' }}>
                    &nbsp;Fill-In order&nbsp;
                  </span>
                  <InfoCircleIcon />
                </div>
              </Tooltip>
            )}
          </Button>
        </div>
      )}
      <div className="filter_col">
        <Toggle
          value={filter.scope}
          options={[
            { label: 'Open', value: 'manageable' },
            { label: 'Submitted', value: 'submitted' },
          ]}
          onChange={handleFilterScopeChange}
        />
      </div>
    </div>
  )
}

export default memo(OrdersFilter)
