import { default as React, FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import useThunkDispatch from '../common/useThunkDispatch'
import { planYearSelector } from './planSelectors'
import { setPlanYear } from './planActions'
import YearPager from '../components/YearPager'

interface IOwnProps {
  disabled?: boolean
}

const PlanFilter: FunctionComponent<IOwnProps> = ({ disabled = false }) => {
  const dispatch = useThunkDispatch()
  const year = useSelector(planYearSelector)

  return (
    <div className="filter">
      <div className="filter_sep -fluid" />
      <div className="filter_col -fluid">
        <YearPager
          year={year}
          onPrev={disabled ? undefined : () => dispatch(setPlanYear(year - 1))}
          onNext={disabled ? undefined : () => dispatch(setPlanYear(year + 1))}
        />
      </div>
    </div>
  )
}

export default PlanFilter
