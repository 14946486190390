import { default as React, FunctionComponent, useEffect, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import { distanceInWordsToNow } from 'date-fns'
import useTitle from 'react-use/lib/useTitle'

import { searchUsers } from './usersActions'
import Search from '../components/Search'
import { IUser } from '../types/user'
import name from '../common/name'
import Avatar from '../components/Avatar'
import useThunkDispatch from '../common/useThunkDispatch'
import Splash from '../components/Splash'

const InternalUsers: FunctionComponent = () => {
  useTitle('Internal Users - Forshaw')

  const dispatch = useThunkDispatch()

  const [users, setUsers] = useState<IUser[]>([])
  const [term, setTerm] = useState('')
  const [loading, setLoading] = useState(true)

  const search = async () => {
    setLoading(true)
    setUsers(await dispatch(searchUsers(term)))
    setLoading(false)
  }

  useDebounce(search, 300, [term])

  useEffect(() => {
    search()
  }, [])

  return (
    <div className="container">
      <div className="filter">
        <div className="filter_col -large">
          <Search
            placeholder="Search Internal User"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
        </div>
      </div>
      <Splash withWrapper isLoading={loading} className="table">
        <div className="table_head-tr">
          <div className="table_td -avatar" />
          <div className="table_td -g33 table_title">Users</div>
          <div className="table_td -g33 table_title">Email</div>
          <div className="table_td -g33 table_title">Last log in</div>
        </div>

        {users.length === 0 && (
          <div className="table_head-tr">
            <div className="table_td -avatar" />
            <div className="table_head-tr table_td table_title">
              No users found, please adjust search criteria
            </div>
          </div>
        )}

        {users.map(user => (
          <div className="table_tr" key={user.id}>
            <div className="table_td -avatar">
              <Avatar user={user} />
            </div>
            <div className="table_td -g33">{name(user)}</div>
            <div className="table_td -g33">{user.email}</div>
            <div className="table_td -g33">
              {user.lastSignInAt &&
                `${distanceInWordsToNow(user.lastSignInAt)} ago`}
            </div>
          </div>
        ))}
      </Splash>
    </div>
  )
}

export default InternalUsers
