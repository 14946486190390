import React, { FC, memo } from 'react'
import Input from '../../components/Input'

export interface IAccountLink {
  id?: number | string
  title?: string
  url?: string
}

interface IAccountLinkProps {
  onDelete: () => void
  onChange: (field: string, value: string) => void
}

const AccountLink: FC<IAccountLink & IAccountLinkProps> = ({
  title,
  url,
  onDelete,
  onChange,
}) => (
  <div className="form_section">
    <div className="form_group">
      <Input
        name="title"
        label="Title"
        placeholder="Main Report"
        value={title}
        onChange={e => onChange('title', e.target.value)}
      />
    </div>
    <div className="form_group">
      <Input
        name="url"
        label="Link"
        placeholder="http://www.example.com/link"
        value={url}
        onChange={e => onChange('url', e.target.value)}
      />
    </div>
    <span className="form_section-delete" onClick={onDelete}>
      Delete
    </span>
  </div>
)

export default memo(AccountLink)
