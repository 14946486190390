import { useState } from 'react'

export default (
  initial: boolean,
  openTime: number = 400,
  closeTime: number = 100
): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(initial)

  let openTimeout: any
  let closeTimeout: any

  const onOpen = () => {
    clearTimeout(closeTimeout)
    openTimeout = setTimeout(() => setOpen(true), openTime)
  }

  const onClose = () => {
    clearTimeout(openTimeout)
    closeTimeout = setTimeout(() => setOpen(false), closeTime)
  }

  return [open, onOpen, onClose]
}
