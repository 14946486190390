import React, { FC, memo } from 'react'
import cn from 'classnames'
import { includes } from 'lodash/fp'
import OrderIcon from '../../../orders/OrderIcon'
import { IDetailsOrder } from '../../../types/orders'
import Tooltip from '../../../components/Tooltip'

interface IOrderStatusProps {
  order: IDetailsOrder
  gmFlow?: boolean
}

const statuses = (order: IDetailsOrder, gmFlow?: boolean) =>
  [
    {
      key: 'open',
      label: 'Open',
      visible: true,
      selected: includes(order.status, ['delayed', 'open']),
    },
    {
      key: 'requested',
      label: 'Pending Approval',
      visible: includes(order.status, ['requested']) || gmFlow,
      selected: includes(order.status, ['requested']),
    },
    {
      key: 'approved',
      label: 'Approved',
      visible: !includes(order.status, ['rejected']) && gmFlow,
      selected: includes(order.status, ['approved']),
    },
    {
      key: 'rejected',
      label: 'Rejected',
      visible: includes(order.status, ['rejected']),
      selected: includes(order.status, ['rejected']),
    },
    {
      key: 'submitted',
      label: 'Submitted',
      visible: !includes(order.status, ['skipped']),
      selected: includes(order.status, ['submitted']),
    },
    {
      key: 'skipped',
      label: 'Skipped',
      visible: includes(order.status, ['skipped']),
      selected: includes(order.status, ['skipped']),
    },
  ].filter(i => i.visible)

// Open → Pending → APPROVED → ORDER RECEIVED
const OrderStatus: FC<IOrderStatusProps> = ({ order, gmFlow }) => {
  const orderStatuses = statuses(order, gmFlow)
  return (
    <div className="order-modal_status">
      <div className="order-modal_status-container form">
        <div className="order-modal_status-steps">
          {orderStatuses.map((status, index) => (
            <span key={status.key}>
              <span
                className={cn('order-modal_status-step', `-${status.key}`, {
                  '-selected': status.selected,
                })}
              >
                {status.key === 'rejected' && order.rejectReason ? (
                  <Tooltip id={`tooltip-threshold-${status.key}`}>
                    <div
                      data-tip={order.rejectReason}
                      data-for={`tooltip-threshold-${status.key}`}
                      data-effect="solid"
                      data-place="bottom"
                    >
                      {status.label}
                    </div>
                  </Tooltip>
                ) : (
                  status.label
                )}
              </span>
              {index < orderStatuses.length - 1 ? ' → ' : ''}
            </span>
          ))}
        </div>
        <OrderIcon order={order} className="order-modal_status-icon" />
        <div className="order-modal_status-name">
          {order.kind === 'fill_in' ? 'Fill In' : 'Replenishment'}
        </div>
      </div>
    </div>
  )
}

export default memo(OrderStatus)
