import React, { FC, memo, useState, useCallback, useEffect } from 'react'
import cn from 'classnames'

import Toggle from '../../components/Toggle'
import Tooltip from '../../components/Tooltip'
import InfoCircleIcon from '../../icons/info_circle.svg'
import Button from '../../components/Button'
import { ITeam, IUser } from '../../types/user'
import TeamsForm from './GroupsForm/TeamsForm'
import DateUpdated from '../../components/DateUpdated'

type ManagerInfoTab = 'info' | 'teams'

interface IOwnProps {
  user: IUser
  teams: ITeam[]
  loading: boolean
  onSave: (user: IUser | { password?: string }) => Promise<void>
}

const filters: Array<{ name: string; type: ManagerInfoTab }> = [
  { name: 'User Info', type: 'info' },
  { name: 'Teams', type: 'teams' },
]

const ManagerInfo: FC<IOwnProps> = ({
  user: initialUser,
  loading,
  onSave,
  teams,
}) => {
  const [tab, setTab] = useState<ManagerInfoTab>('info')
  const [user, setUser] = useState<IUser>({ ...initialUser })
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setUser({ ...initialUser })
  }, [initialUser])

  const handleSubmit = useCallback(
    async event => {
      setSaving(true)
      event.preventDefault()
      await onSave(user)
      setSaving(false)
    },
    [user, onSave]
  )

  return (
    <div className="user-info">
      <div className="user-info_info">
        <div className="user-info_title">
          {filters.map(f => (
            <div
              key={f.type}
              className={cn('user-info_toggler', {
                '-active': f.type === tab,
              })}
              onClick={() => setTab(f.type)}
            >
              {f.name}
            </div>
          ))}
          <DateUpdated
            loading={loading}
            updatedAt={user.updatedAt}
            saving={saving}
          />
        </div>

        {tab === 'teams' && (
          <TeamsForm user={initialUser} onSave={onSave} teams={teams} />
        )}

        {tab === 'info' && (
          <form className="user-form" onSubmit={handleSubmit}>
            <div className="user-form_box -slim">
              <div className="user-form_text -inline">
                <Toggle
                  value={user.generalManager}
                  onChange={() =>
                    setUser({ ...user, generalManager: !user.generalManager })
                  }
                  option={{
                    value: true,
                    label: 'General Manager',
                  }}
                />
              </div>
            </div>

            <div className="user-form_box -slim">
              <div className="user-form_text -inline">
                <Toggle
                  value={user.checkoutManager}
                  onChange={() =>
                    setUser({ ...user, checkoutManager: !user.checkoutManager })
                  }
                  option={{
                    value: true,
                    label: 'Manage inventory checkout requests',
                  }}
                />
              </div>
              <div className="user-form_text">
                <Tooltip id={`tooltip-checkout-manager`}>
                  <div
                    className="input_label-tooltip"
                    data-tip="
                    <div>
                      User will be able to manage inventory checkout requests:
                      <br />
                      <ul>
                        <li>Receive notifications about new requests</li>
                        <li>Accept or decline incoming requests</li>
                        <li>Create new requests for other users</li>
                      </ul>
                    </div>
                  "
                    data-for={`tooltip-checkout-manager`}
                    data-effect="solid"
                    data-place="top"
                    style={{ marginTop: '5px' }}
                    data-html
                  >
                    <InfoCircleIcon />
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="user-form_footer">
              <Button color="success" type="submit" loading={loading}>
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default memo(ManagerInfo)
