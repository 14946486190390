import { __, get, identity, omit, sortBy } from 'lodash/fp'
import { createAction } from 'redux-actions'
import { Action, IHash, IInboundInventory, ThunkAction } from '../types/common'
import { query } from '../common/api'
import * as storage from '../common/storage'
import { IBranch, IBILink, IDivision, ITeam } from '../types/user'
import {
  MANAGER_BRANCHES_QUERY,
  ACCOUNT_BRANCHES_QUERY,
  ACCOUNT_LINKS_QUERY,
  DIVISIONS_MANAGER_QUERY,
  DIVISIONS_INTERNAL_QUERY,
  CREATE_DIVISION_QUERY,
  REMOVE_DIVISION_QUERY,
  UPDATE_DIVISION_QUERY,
  INBOUND_INVENTORIES_MANAGER_QUERY,
  INBOUND_INVENTORIES_INTERNAL_QUERY,
  FIND_INBOUND_INVENTORY_QUERY,
  MANAGER_FIND_INBOUND_INVENTORY_QUERY,
  TEAMS_MANAGER_QUERY,
  TEAMS_INTERNAL_QUERY,
  CREATE_TEAM_QUERY,
  REMOVE_TEAM_QUERY,
  UPDATE_TEAM_QUERY,
  APPROVE_INBOUND_INVENTORY_QUERY,
} from './managerQueries'
import { currentUserSelector } from '../auth/authSelectors'
import extractErrors from '../common/extractErrors'

export const SET_MANAGER_BRANCHES = 'MANAGER/SET_MANAGER_BRANCHES'
export type SET_MANAGER_BRANCHES = Action<{
  branches: IBranch[]
  branch?: IBranch
}>
export const setManagerBranches = createAction<
  { branches: IBranch[]; branch?: IBranch },
  IBranch[],
  IBranch | undefined
>(SET_MANAGER_BRANCHES, (branches, branch) => ({ branches, branch }))

export const SET_MANAGER_ACCOUNT_BRANCHES =
  'MANAGER/SET_MANAGER_ACCOUNT_BRANCHES'
export type SET_MANAGER_ACCOUNT_BRANCHES = Action<{ branches: IBranch[] }>
export const setManagerAccountBranches = createAction<
  { branches: IBranch[] },
  IBranch[]
>(SET_MANAGER_ACCOUNT_BRANCHES, branches => ({ branches }))

export const SET_MANAGER_BI_LINKS = 'MANAGER/SET_MANAGER_BI_LINKS'
export type SET_MANAGER_BI_LINKS = Action<{ links: IBILink[] }>
export const setManagerBiLinks = createAction<{ links: IBILink[] }, IBILink[]>(
  SET_MANAGER_BI_LINKS,
  links => ({ links })
)

export const SET_MANAGER_BRANCH = 'MANAGER/SET_MANAGER_BRANCH'
export type SET_MANAGER_BRANCH = Action<{ branch: IBranch }>
export const setManagerBranch = createAction<{ branch: IBranch }, IBranch>(
  SET_MANAGER_BRANCH,
  branch => ({ branch })
)
export const requestSetManagerBranch = (
  branch: IBranch
): ThunkAction<Promise<void>> => async dispatch => {
  storage.set('manager.branch', branch.id)
  dispatch(setManagerBranch(branch))
}

export const requestManagerBranches = (): ThunkAction<
  Promise<void>
> => async dispatch => {
  const response = await query(MANAGER_BRANCHES_QUERY)
  const branches = get('manager.branches', response) as IBranch[]
  const currentBranch = storage.get('manager.branch')
  let initialBranch: IBranch = branches[0]

  if (currentBranch) {
    const branch = branches.find(b => b.id === +currentBranch)
    if (branch) {
      initialBranch = branch
    } else {
      storage.remove('manager.branch')
    }
  }

  dispatch(setManagerBranches(branches, initialBranch))
}

export const requestAccountBranches = (): ThunkAction<
  Promise<void>
> => async dispatch => {
  const response = await query(ACCOUNT_BRANCHES_QUERY)
  const branches = get('manager.account.branches', response) as IBranch[]
  dispatch(setManagerAccountBranches(branches))
}

export const requestManagerLinks = (): ThunkAction<
  Promise<void>
> => async dispatch => {
  const response = await query(ACCOUNT_LINKS_QUERY)
  const biLinks = get('manager.account.biLinks', response) as IBILink[]
  dispatch(setManagerBiLinks(biLinks))
}

export const SET_MANAGER_DIVISIONS = 'DIVISIONS/SET_DIVISIONS'
export type SET_MANAGER_DIVISIONS = Action<IDivision[]>
export const setDivisions = createAction<IDivision[], IDivision[]>(
  SET_MANAGER_DIVISIONS,
  identity
)

export const requestManagerDivisions = (
  branchId: number,
  accountId?: number
): ThunkAction<Promise<IDivision[]>> => async dispatch => {
  const isInternal = !!accountId
  const response = isInternal
    ? await query(DIVISIONS_INTERNAL_QUERY, { branchId, accountId })
    : await query(DIVISIONS_MANAGER_QUERY, { branchId })

  const divisions = (isInternal
    ? get('internal.accounts.nodes[0].currentBranch[0].divisions', response)
    : get('manager.currentBranch[0].divisions', response)) as IDivision[]

  // dispatch(setDivisions(divisions))
  return sortBy('name', divisions)
}

export const requestManagerTeams = (
  branchId: number,
  accountId?: number
): ThunkAction<Promise<ITeam[]>> => async dispatch => {
  const isInternal = !!accountId
  const response = isInternal
    ? await query(TEAMS_INTERNAL_QUERY, { branchId, accountId })
    : await query(TEAMS_MANAGER_QUERY, { branchId })

  const teams = (isInternal
    ? get('internal.accounts.nodes[0].currentBranch[0].teams', response)
    : get('manager.currentBranch[0].teams', response)) as ITeam[]

  return sortBy('name', teams)
}

export const requestManagerInboundInventories = (
  branchId: number,
  accountId?: number
): ThunkAction<Promise<IInboundInventory[]>> => async dispatch => {
  const isInternal = !!accountId
  const response = isInternal
    ? await query(INBOUND_INVENTORIES_INTERNAL_QUERY, { branchId, accountId })
    : await query(INBOUND_INVENTORIES_MANAGER_QUERY, { branchId })

  const inboundInventories = (isInternal
    ? get(
        'internal.accounts.nodes[0].currentBranch[0].inboundInventories',
        response
      )
    : get(
        'manager.currentBranch[0].inboundInventories',
        response
      )) as IInboundInventory[]

  return inboundInventories
}

export const requestInboundInventory = (
  inboundInventoryId: number
): ThunkAction<Promise<IInboundInventory>> => async (_, getState) => {
  const user = currentUserSelector(getState())
  const isInternal = user.role === 'internal'
  const prefix = isInternal ? 'internal' : 'manager'
  const response = await query(
    isInternal
      ? FIND_INBOUND_INVENTORY_QUERY
      : MANAGER_FIND_INBOUND_INVENTORY_QUERY,
    { inboundInventoryId }
  )
  const inboundInventory = get(`${prefix}.inboundInventoryFind`, response)
  return {
    ...inboundInventory,
    shipments: sortBy('product.name', inboundInventory.shipments),
  }
}

interface IInboundData {
  shipmentId?: number
  transferId?: number
  approvingAmount: number
}

export const requestInboundInventoryApprove = (
  inboundInventoryId: number,
  inboundData: IInboundData[]
): ThunkAction<
  Promise<{ errors?: any; result?: IInboundInventory }>
> => async dispatch => {
  const response = await query(APPROVE_INBOUND_INVENTORY_QUERY, {
    id: inboundInventoryId,
    inboundData,
  })
  const { result, errors } = get('inboundInventoryApprove', response)
  return {
    errors: errors ? extractErrors(errors) : undefined,
    result,
  }
}

export const updateManagerDivision = (attrs: {
  branchId: number
  id?: number
  name?: string
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  const { errors } = attrs.id
    ? get(
        'divisionUpdate',
        await query(UPDATE_DIVISION_QUERY, {
          id: attrs.id,
          attributes: omit(['id', 'branchId'], attrs),
        })
      )
    : get(
        'divisionCreate',
        await query(CREATE_DIVISION_QUERY, { attributes: omit(['id'], attrs) })
      )

  if (errors) return errors
}

export const removeManagerDivision = (
  id: number
): ThunkAction<Promise<void | IHash<string>>> => async (dispatch, getState) => {
  const { errors } = get(
    'divisionRemove',
    await query(REMOVE_DIVISION_QUERY, { id })
  )
  if (errors) return errors
}

export const updateManagerTeam = (attrs: {
  branchId: number
  id?: number
  name?: string
}): ThunkAction<Promise<void | IHash<string>>> => async (
  dispatch,
  getState
) => {
  const { errors } = attrs.id
    ? get(
        'teamUpdate',
        await query(UPDATE_TEAM_QUERY, {
          id: attrs.id,
          attributes: omit(['id', 'branchId'], attrs),
        })
      )
    : get(
        'teamCreate',
        await query(CREATE_TEAM_QUERY, { attributes: omit(['id'], attrs) })
      )

  if (errors) return errors
}

export const removeManagerTeam = (
  id: number
): ThunkAction<Promise<void | IHash<string>>> => async (dispatch, getState) => {
  const { errors } = get('teamRemove', await query(REMOVE_TEAM_QUERY, { id }))
  if (errors) return errors
}
