import React, { memo, useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { format } from 'date-fns'
import { IUser, IDivision, IBranch, IAccount, ITeam } from '../../types/user'
import { IHash } from '../../types/common'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Toggle from '../../components/Toggle'
import Tooltip from '../../components/Tooltip'
import LockIcon from '../../icons/lock_filled.svg'
import InfoCircleIcon from '../../icons/info_circle.svg'
import UserChangePassword from './UserChangePassword'
import UserBranchMove from './UserBranchMove'

export interface IOwnProps {
  user: IUser
  account?: IAccount
  divisions: IDivision[]
  teams: ITeam[]
  branch: IBranch
  loading: boolean
  errors: IHash<string>
  onSave: (user: IUser | { password?: string }) => Promise<void>
  onDelete?: (user: IUser) => void
  onCancel?: () => void
  onPasswordUpdate?: (user: IUser, password: string) => void
  onBranchUpdate?: (user: IUser, branch: IBranch) => void
}

const UserForm = ({
  user: initialUser,
  branch,
  loading,
  errors,
  onSave,
  onDelete,
  onCancel,
  onPasswordUpdate,
  onBranchUpdate,
}: IOwnProps) => {
  const [user, setUser] = useState<IUser>({ ...initialUser })
  const [password, setPassword] = useState<string>('')
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(
    false
  )
  const [moveVisible, setMoveVisible] = useState<boolean>(false)

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setUser({ ...user, password })
      onSave({ ...user, password })
    },
    [user, password]
  )

  const handleDelete = useCallback(() => {
    if (user.isRemovable && onDelete) {
      onDelete(user)
    }
  }, [user])

  useEffect(() => {
    setPassword('')
    setUser({ ...initialUser })
  }, [initialUser.id])

  const isNew = !user.id

  return (
    <form className="user-form" onSubmit={handleSubmit}>
      <div className="user-form_info">
        <div className={cn('user-form_field', { '-error': errors.email })}>
          <Input
            label="email"
            error={errors.email}
            value={user.email}
            onChange={e => setUser({ ...user, email: e.target.value })}
          />
        </div>

        {isNew && (
          <div className={cn('user-form_field', { '-error': errors.password })}>
            <Input
              label="password"
              placeholder="5+ symbols"
              error={errors.password}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        )}

        <div className={cn('user-form_field', { '-error': errors.name })}>
          <Input
            label="name"
            error={errors.name}
            value={user.name}
            onChange={e => setUser({ ...user, name: e.target.value })}
          />
        </div>

        <div className="user-form_box -slim">
          <Toggle
            value={user.active}
            onChange={() => setUser({ ...user, active: !user.active })}
            option={{
              value: true,
              label: 'Active User',
            }}
          />
        </div>

        <div className="user-form_box -slim">
          <div className="user-form_text -inline">
            <Toggle
              value={user.checkoutManager}
              onChange={() =>
                setUser({ ...user, checkoutManager: !user.checkoutManager })
              }
              option={{
                value: true,
                label: 'Manage inventory checkout requests',
              }}
            />
          </div>
          <div className="user-form_text">
            <Tooltip id={`tooltip-checkout-manager`}>
              <div
                className="input_label-tooltip"
                data-tip="
                  <div>
                    User will be able to manage inventory checkout requests:
                    <br />
                    <ul>
                      <li>Receive notifications about new requests</li>
                      <li>Accept or decline incoming requests</li>
                      <li>Create new requests for other users</li>
                    </ul>
                  </div>
                "
                data-for={`tooltip-checkout-manager`}
                data-effect="solid"
                data-place="top"
                style={{ marginTop: '5px' }}
                data-html
              >
                <InfoCircleIcon />
              </div>
            </Tooltip>
          </div>
        </div>

        {!isNew && (
          <>
            <div className="user-form_box">
              <div className="user-form_text -inline">At {branch.name}</div>
              <Tooltip id="user-form-move">
                <div
                  className={cn('user-form_text -primary -link', {
                    '-disabled': !user.isMovable,
                  })}
                  data-tip={
                    !user.isMovable
                      ? 'You need to complete all user requests in mobile app to move user to another branch'
                      : null
                  }
                  data-for="user-form-move"
                  data-effect="solid"
                  data-place="top"
                  onClick={() => user.isMovable && setMoveVisible(true)}
                >
                  Move to another branch
                </div>
              </Tooltip>
            </div>

            <div className="user-form_box">
              <div className="user-form_text">
                {user.lastSignInAt
                  ? `Last sign in ${format(user.lastSignInAt, 'MM/DD hh:mm A')}`
                  : 'Never signed in'}
              </div>
              <div
                className="user-form_text -danger -link"
                onClick={() => setChangePasswordVisible(true)}
              >
                Change password
              </div>
            </div>
          </>
        )}
      </div>

      <div className="user-form_footer">
        <Button color="success" type="submit" loading={loading}>
          Save
        </Button>
        <div className="user-form_cancel" onClick={onCancel}>
          Cancel
        </div>
        {!isNew && (
          <Tooltip id="user-form-delete">
            <div
              className="user-form_delete"
              onClick={handleDelete}
              data-tip={
                !user.isRemovable
                  ? 'This user can’t be deleted but you can deactivate the user'
                  : null
              }
              data-for="user-form-delete"
              data-effect="solid"
              data-place="top"
            >
              {!user.isRemovable && <LockIcon className="user-form_icon" />}
              Delete user
            </div>
          </Tooltip>
        )}
      </div>

      <UserChangePassword
        opened={changePasswordVisible}
        onUpdate={password =>
          onPasswordUpdate && onPasswordUpdate(user, password)
        }
        onClose={() => setChangePasswordVisible(false)}
      />

      <UserBranchMove
        branch={branch}
        opened={moveVisible}
        onUpdate={(branch: IBranch) =>
          onBranchUpdate && onBranchUpdate(user, branch)
        }
        onClose={() => setMoveVisible(false)}
      />
    </form>
  )
}

export default memo(UserForm)
