import React, { FC, memo, useState, useCallback } from 'react'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import Input from '../../components/Input'
import Button from '../../components/Button'
import CrossIcon from '../../icons/cross.svg'

interface IOwnProps {
  opened: boolean
  onUpdate: (password: string) => void
  onClose: () => void
}

const UserChangePassword: FC<IOwnProps> = ({ opened, onUpdate, onClose }) => {
  const [password, setPassword] = useState<string>('')
  const handleClose = useCallback(() => {
    setPassword('')
    onClose()
  }, [])
  const handleUpdate = () => {
    onUpdate(password)
    handleClose()
  }
  if (!opened) return null
  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={handleClose} />
      <div className="import_dialog user-modal">
        <div className="user-modal_title">Update Password</div>
        <CrossIcon className="user-modal_close" onClick={handleClose} />
        <div className="user-modal_field">
          <Input
            label="New password"
            placeholder="5+ symbols"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <Button
          color="primary"
          type="submit"
          className="user-modal_button"
          disabled={password.length < 5}
          onClick={handleUpdate}
        >
          Update password
        </Button>
        <div className="user-modal_cancel" onClick={handleClose}>
          Cancel
        </div>
      </div>
    </ModalWrapper>
  )
}

export default memo(UserChangePassword)
