// Run this example by adding <%= javascript_pack_tag 'hello_typescript' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

import * as React from 'react'
import * as ReactDOM from 'react-dom'

import '../../assets/stylesheets/application.css'

import App from '../src/App'

ReactDOM.render(<App />, document.getElementById('main'))
