import { default as React, memo } from 'react'
import cn from 'classnames'
import { format } from 'date-fns'

import {
  isDelayedOrder,
  isPlannedOrder,
  IStatusableOrder,
} from '../types/orders'

export interface IOwnProps {
  order: IStatusableOrder
  short?: boolean
}

const OrderStatus: React.FunctionComponent<IOwnProps> = ({ order, short }) => {
  let statusName: string = order.status
  if (order.status === 'requested') {
    statusName = 'Pending Approval'
  }

  return (
    <div
      className={cn('status', `-${order.status}`, {
        '-children': isPlannedOrder(order),
        '-short': short,
      })}
    >
      <div className="status_label">{statusName}</div>
      {!short && isPlannedOrder(order) && (
        <div
          className={cn('status_text', { '-delayed': isDelayedOrder(order) })}
        >
          {format(order.submittedAt || order.dueDateAt, 'MM/DD/YYYY')}
        </div>
      )}
    </div>
  )
}

export default memo(OrderStatus)
