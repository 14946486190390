import * as React from 'react'
import * as ReactPopover from 'react-popover'

interface IOwnProps extends ReactPopover.PopoverProps {
  offset?: number
}

const BasePopover: React.FunctionComponent<IOwnProps> = ({
  enterExitTransitionDurationMs = 0,
  preferPlace = 'below',
  ...props
}) => (
  <ReactPopover enterExitTransitionDurationMs={enterExitTransitionDurationMs} preferPlace={preferPlace} {...props} />
)

export default BasePopover
