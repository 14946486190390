import { createAction } from 'redux-actions'
import {
  __,
  assign,
  concat,
  findIndex,
  get,
  identity,
  update,
  forEach,
  values,
  set,
  orderBy,
  reject,
} from 'lodash/fp'
import { startOfYear, endOfYear } from 'date-fns'
import { Action, ThunkAction } from '../types/common'
import { query } from '../common/api'
import {
  ADD_PLAN_PRODUCT,
  ANNUAL_PLAN_BRANCH_QUERY,
  ANNUAL_PLAN_QUERY,
  APPROVE_ORDER_QUERY,
  CREATE_ORDER_ITEM_QUERY,
  REMOVE_PLAN_PRODUCT,
  SUBMIT_ORDER_QUERY,
  UPDATE_ORDER_ITEM_QUERY,
  UPDATE_ORDER_QUERY,
  MANAGER_ANNUAL_PLAN_QUERY,
  MANAGER_ANNUAL_PLAN_BRANCH_QUERY,
  POPULATE_PLAN,
} from './planQueries'
import { IIdentifiableOrderAttrs, IOrder, IOrderItem } from '../types/orders'
import extractErrors from '../common/extractErrors'
import { IPlannedBranch } from '../types/user'
import {
  planOrdersDictSelector,
  planRequestedBranchSelector,
} from './planSelectors'
import { IProduct } from '../types/products'
import ordersToProductsSortMap from '../common/ordersToProductsSortMap'
import { MIN_DATE } from '../common/date'
import { currentUserSelector } from '../auth/authSelectors'

export const CLEAR_PLAN = 'PLANS/CLEAR'
export type CLEAR_PLAN = Action<void>
export const clearPlan = createAction<void, void>(CLEAR_PLAN, identity)

export const SET_PLAN_YEAR = 'PLANS/SET_YEAR'
export type SET_PLAN_YEAR = Action<number>
export const setPlanYear = createAction<number, number>(SET_PLAN_YEAR, identity)

export const SET_PLAN_ORDERS = 'PLANS/SET_ORDERS'
export type SET_PLAN_ORDERS = Action<{
  orders: IOrder[]
  branch: IPlannedBranch
}>
export const setPlanOrders = createAction<
  { orders: IOrder[]; branch: IPlannedBranch },
  IPlannedBranch,
  IOrder[]
>(SET_PLAN_ORDERS, (branch, orders) => ({ branch, orders }))

export const UPDATE_PLAN_ORDER = 'PLANS/UPDATE_ORDER'
export type UPDATE_PLAN_ORDER = Action<IIdentifiableOrderAttrs>
export const updatePlanOrder = createAction<
  IIdentifiableOrderAttrs,
  IIdentifiableOrderAttrs
>(UPDATE_PLAN_ORDER, identity)

export const requestYearPlan = (
  accountId: number,
  branchId: number,
  year: number
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState())
  const isInternal = currentUser.role === 'internal'
  const annualPlanParams = { branchId, year }
  const annualPlanBranchParams = {
    accountId,
    branchId,
    year,
    fromDate: startOfYear(`${year}-01-01`).toISOString(),
    toDate: endOfYear(`${year}-01-01`).toISOString(),
  }

  const planOrdersQuery = isInternal
    ? ANNUAL_PLAN_QUERY
    : MANAGER_ANNUAL_PLAN_QUERY
  const branchQuery = isInternal
    ? ANNUAL_PLAN_BRANCH_QUERY
    : MANAGER_ANNUAL_PLAN_BRANCH_QUERY

  const planOrdersDataKey = isInternal
    ? 'internal.retrieveAnnualPlan'
    : 'manager.retrieveAnnualPlan'
  const branchDataKey = isInternal
    ? 'internal.accounts.nodes[0].branches[0]'
    : 'manager.account.branches[0]'

  const [planOrdersData, branchData] = await Promise.all([
    query(planOrdersQuery, annualPlanParams),
    query(branchQuery, annualPlanBranchParams),
  ])

  const planOrders = get(planOrdersDataKey, planOrdersData)
  const branch = get(branchDataKey, branchData)

  const sortMap = ordersToProductsSortMap(planOrders)
  const sortedBranch = update(
    'products',
    orderBy<IProduct>([product => sortMap[product.id] || MIN_DATE], ['desc']),
    branch
  )

  dispatch(setPlanOrders(sortedBranch, planOrders))
}

export const requestUpdateOrderItem = (
  order: IOrder,
  { id, ...attrs }: { id: number } & Partial<IOrderItem>
): ThunkAction<Promise<void | string>> => async dispatch => {
  try {
    const index = findIndex({ id }, order.orderItems)

    const {
      orderItemUpdate: { result: updatedAttrs, errors },
    } = await query(UPDATE_ORDER_ITEM_QUERY, { id, attributes: attrs })
    if (errors) return extractErrors(errors)

    const updatedOrder = update(
      `orderItems[${index}]`,
      assign(__, updatedAttrs),
      order
    )
    dispatch(updatePlanOrder(updatedOrder))
  } catch (e) {
    return 'Server error'
  }
}

export const requestCreateOrderItem = (
  order: IOrder,
  attrs: Partial<IOrderItem>
): ThunkAction<Promise<void | string>> => async dispatch => {
  try {
    const {
      orderItemCreate: { result: createdItem, errors },
    } = await query(CREATE_ORDER_ITEM_QUERY, {
      attributes: { orderId: order.id, ...attrs },
    })
    if (errors) return extractErrors(errors)

    const updatedOrder = update('orderItems', concat(createdItem), order)
    dispatch(updatePlanOrder(updatedOrder))
  } catch (e) {
    return 'Server error'
  }
}

export const requestSubmitOrder = (
  order: IOrder
): ThunkAction<Promise<void | string>> => async dispatch => {
  try {
    const {
      orderSubmit: { result: orderAttrs, errors },
    } = await query(SUBMIT_ORDER_QUERY, { id: order.id })
    if (errors) return extractErrors(errors)

    dispatch(updatePlanOrder(assign(order, orderAttrs)))
  } catch (e) {
    return 'Server error'
  }
}

export const requestApproveOrder = (
  order: IOrder
): ThunkAction<Promise<void | string>> => async dispatch => {
  try {
    const {
      orderApprove: { result: orderAttrs, errors },
    } = await query(APPROVE_ORDER_QUERY, { id: order.id })
    if (errors) return extractErrors(errors)

    dispatch(updatePlanOrder(assign(order, orderAttrs)))
  } catch (e) {
    return 'Server error'
  }
}

export const requestUpdateOrder = ({
  id,
  ...attributes
}: IIdentifiableOrderAttrs): ThunkAction<
  Promise<void | string>
> => async dispatch => {
  try {
    const {
      orderUpdate: { result: orderAttrs, errors },
    } = await query(UPDATE_ORDER_QUERY, { id, attributes })
    if (errors) return extractErrors(errors)

    dispatch(updatePlanOrder(orderAttrs))
  } catch (e) {
    return 'Server error'
  }
}

export const requestAddPlanProduct = (
  branchId: number,
  product: IProduct
): ThunkAction<Promise<void | string>> => async (dispatch, getState) => {
  try {
    const {
      addPlanProduct: { result: orderItems, errors },
    } = await query(ADD_PLAN_PRODUCT, { branchId, productId: product.id })
    if (errors) return extractErrors(errors)

    const state = getState()
    let branch = planRequestedBranchSelector(state)
    let orders = planOrdersDictSelector(state)

    if (!branch) return

    forEach(orderItem => {
      const order = orders[orderItem.orderId]
      if (!order) return

      const index = findIndex({ id: orderItem.id }, order.orderItems)
      if (index > 0)
        orders = set(
          `[${orderItem.orderId}].orderItems[${index}]`,
          orderItem,
          orders
        )
      else
        orders = update(
          `[${orderItem.orderId}].orderItems`,
          concat(__, [orderItem]),
          orders
        )
    }, orderItems)

    branch = update('products', concat([product]), branch)

    dispatch(setPlanOrders(branch!, values(orders)))
  } catch (e) {
    return 'Server error'
  }
}

export const requestPopulatePlan = (
  branchId: number
): ThunkAction<Promise<void | string>> => async () => {
  try {
    const {
      populatePlan: { errors },
    } = await query(POPULATE_PLAN, { branchId })
    if (errors) return extractErrors(errors)
  } catch (e) {
    return 'Server error'
  }
}

export const requestRemovePlanProduct = (
  branchId: number,
  product: IProduct
): ThunkAction<Promise<void | string>> => async (dispatch, getState) => {
  try {
    const {
      removePlanProduct: { result: orderItems, errors },
    } = await query(REMOVE_PLAN_PRODUCT, { branchId, productId: product.id })
    if (errors) return extractErrors(errors)

    const state = getState()
    let branch = planRequestedBranchSelector(state)
    let orders = planOrdersDictSelector(state)

    if (!branch) return

    forEach(orderItem => {
      const order = orders[orderItem.orderId]
      if (!order) return

      orders = update(
        `[${orderItem.orderId}].orderItems`,
        reject({ id: orderItem.id }),
        orders
      )
    }, orderItems)

    branch = update('products', reject({ id: product.id }), branch)

    dispatch(setPlanOrders(branch!, values(orders)))
  } catch (e) {
    return 'Server error'
  }
}
