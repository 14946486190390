import * as React from 'react'
import cn from 'classnames'
import { IUser } from '../../types/user'

const abbr = (name: string) =>
  name
    .split(' ')
    .map((part) => part[0])
    .slice(0, 2)
    .join('')

const Avatar: React.FunctionComponent<{ user: IUser }> = ({ user }) => (
  <div className={cn('avatar', !user.avatar && `-bg${user.id % 4}`)} title={user.name}>
    {user.avatar ? <img src={user.avatar} className="avatar_image" alt="" /> : abbr(user.name)}
  </div>
)

export default Avatar
