import { divide } from 'lodash'
import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import GridLayout from '../../components/GridLayout'

import ManagerDivisions from '../managerDivisions'
import ManagerTeams from '../managerTeams'

interface IRouteParams {
  accountId?: string
  branchId?: string
}

const ManagerGroups: FC<RouteComponentProps<IRouteParams>> = props => {
  return (
    <div className="groups">
      <GridLayout className="-two-col">
        <div className="divisions">
          <ManagerDivisions {...props} />
        </div>
        <div className="teams">
          <ManagerTeams {...props} />
        </div>
      </GridLayout>
    </div>
  )
}

export default ManagerGroups
