import {
  default as React,
  FunctionComponent,
  useCallback,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClickOutside from 'react-click-outside'
import BasePopover from '../../components/BasePopover'
import Avatar from '../../components/Avatar'
import { currentUserSelector } from '../../auth/authSelectors'
import { requestLogOut } from '../../auth/authActions'

const HeaderAvatar: FunctionComponent = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector)

  const [open, setOpen] = useState(false)

  const onLogOut = useCallback(() => {
    dispatch(requestLogOut())
  }, [])

  return (
    <div className="header_user">
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <BasePopover
          className="header_user-menu"
          isOpen={open}
          body={
            <ul>
              <li>
                <a className="link" onClick={onLogOut}>
                  Log Out
                </a>
              </li>
            </ul>
          }
        >
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="clear-button"
          >
            <Avatar user={currentUser} />
          </button>
        </BasePopover>
      </ClickOutside>
    </div>
  )
}

export default HeaderAvatar
