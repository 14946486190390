import { get, indexBy } from 'lodash/fp'

import { IHash, Selector } from '../types/common'
import { createSelector } from 'reselect'
import { IUser } from '../types/user'
import { SimpleIndex } from '../common/simpleSearch'

export const usersSelector: Selector<IUser[]> = get('users.users')

export const usersDictSelector: Selector<IHash<IUser>> = createSelector(
  usersSelector,
  users => indexBy('id', users)
)

export const usersSearchIndexSelector: Selector<
  SimpleIndex<IUser>
> = createSelector(
  usersSelector,
  users => new SimpleIndex<IUser>(users, { keys: ['name', 'email'] })
)
