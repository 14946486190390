const DIVISION_FIELDS_FRAGMENT = `
fragment divisionFields on Division {
  id
  name
}
`

const INVENTORY_EVENTS_FIELDS_FRAGMENT = `
fragment inventoryEvents on InventoryEvent {
  id
  eventType
  amount
  stockValue
  eventCreatedAt
  unit
  createdByUser {
    id
    name
  }
  additional
}
`

const INVENTORY_FIELDS_FRAGMENT = `
id
distributor
inventory
name
notifyThreshold
price
quantity
threshold
unit
`

export const PRODUCTS_QUERY = `
${DIVISION_FIELDS_FRAGMENT}

query FindProducts($branchId: Int!) {
  manager {
    account {
      id
      divisions {
        ...divisionFields
      }
      distributorsList
    }

    currentBranch: branches(id: $branchId) {
      inventoryProducts {
        code
        createdAt
        updatedAt
        description
        divisions {
          ...divisionFields
        }
        id
        name
        photo
        price
        quantity
        inventory
        threshold
        notifyThreshold
        unit
        updatedAt
        custom
        distributor
        originalProductData {
          unit
          price
        }
      }
    }
  }
}
`

export const INTERNAL_PRODUCTS_QUERY = `
${DIVISION_FIELDS_FRAGMENT}

query FindProducts($branchId: Int!, $accountId: Int!) {
  internal {
    accounts(id: $accountId) {
      nodes {
        id
        divisions {
          ...divisionFields
        }
        distributorsList

        currentBranch: branches(id: $branchId) {
          inventoryProducts {
            code
            createdAt
            updatedAt
            description
            divisions {
              ...divisionFields
            }
            id
            name
            photo
            price
            quantity
            inventory
            threshold
            notifyThreshold
            unit
            updatedAt
            custom
            distributor
            originalProductData {
              unit
              price
            }
          }
        }
      }
    }
  }
}
`

export const SALABLE_PRODUCTS_LIST_QUERY = `
  query FindProducts($branchId: Int!, $textCode: String, $textName: String) {
    manager {
      account {
        salableCatalog(branchId: $branchId, textCode: $textCode, textName: $textName) {
          id
          code
          description
          distributor
          name
          photo
          unit
          price
        }
      }
    }
  }
`

export const INTERNAL_SALABLE_PRODUCTS_LIST_QUERY = `
  query FindInternalProducts($accountId: Int!, $branchId: Int!, $textCode: String, $textName: String) {
    internal {
      accounts(id: $accountId) {
        nodes {
          salableCatalog(branchId: $branchId, textCode: $textCode, textName: $textName) {
            id
            code
            description
            distributor
            name
            photo
            unit
            price
          }
        }
      }
    }
  }
`

export const BRANCHES_PRODUCTS_LIST_QUERY = `
  query FindProducts($branchId: Int!, $textCode: String, $textName: String) {
    manager {
      account {
        branchesCatalog(branchId: $branchId, textCode: $textCode, textName: $textName) {
          id
          code
          description
          distributor
          name
          photo
          unit
          price
        }
      }
    }
  }
`

export const INTERNAL_BRANCHES_PRODUCTS_LIST_QUERY = `
  query FindInternalProducts($accountId: Int!, $branchId: Int!, $textCode: String, $textName: String) {
    internal {
      accounts(id: $accountId) {
        nodes {
          branchesCatalog(branchId: $branchId, textCode: $textCode, textName: $textName) {
            id
            code
            description
            distributor
            name
            photo
            unit
            price
          }
        }
      }
    }
  }
`

export const INVENTORY_EVENTS_INTERNAL_QUERY = `
  ${INVENTORY_EVENTS_FIELDS_FRAGMENT}

  query FindInternalProduct($accountId: Int, $branchId: Int!, $productId: Int!) {
    internal {
      accounts(id: $accountId) {
        nodes {
          currentBranch: branches(id: $branchId) {
            inventoryProducts(productId: $productId) {
              id
              code
              updatedAt
              inventoryEvents {
                ...inventoryEvents
              }
            }
          }
        }
      }
    }
  }
`

export const INVENTORY_EVENTS_MANAGER_QUERY = `
  ${INVENTORY_EVENTS_FIELDS_FRAGMENT}

  query FindProducts($branchId: Int!, $productId: Int!) {
    manager {
      currentBranch: branches(id: $branchId) {
        inventoryProducts(productId: $productId) {
          id
          code
          updatedAt
          inventoryEvents {
            ...inventoryEvents
          }
        }
      }
    }
  }
`

export const PRODUCT_UPDATE_QUERY = `
mutation InventoryProductUpdate(
  $branchId: Int!,
  $attributes: InventoryProductUpdateAttributes!,
  $productCode: String!
) {
  inventoryProductUpdate(branchId: $branchId, attributes: $attributes, productCode: $productCode) {
    errors
    result {
      ${INVENTORY_FIELDS_FRAGMENT}
    }
  }
}
`

export const PRODUCT_TRANSFER_QUERY = `
mutation InventoryProductTransfer(
  $sourceBranchId: Int!,
  $destinationBranchId: Int!,
  $attributes: InventoryProductTransferAttributes!,
  $productCode: String!
) {
  inventoryProductTransfer(
    sourceBranchId: $sourceBranchId,
    destinationBranchId: $destinationBranchId,
    attributes: $attributes,
    productCode: $productCode
  ) {
    errors
    result {
      ${INVENTORY_FIELDS_FRAGMENT}
    }
  }
}
`

export const PRODUCT_CREATE_QUERY = `
mutation InventoryProductCreate($attributes: InventoryProductCreateAttributes!) {
  inventoryProductCreate(attributes: $attributes) {
    errors
    result {
      ${INVENTORY_FIELDS_FRAGMENT}
    }
  }
}
`

export const PRODUCT_REMOVE_QUERY = `
mutation InventoryProductRemove($branchId: Int!, $productCode: String!) {
  inventoryProductRemove(branchId: $branchId, productCode: $productCode) {
    errors
  }
}
`
