import { default as React, FunctionComponent, memo, useState } from 'react'
import cn from 'classnames'
import { getOr, sumBy } from 'lodash/fp'
import {
  IBranchForecast,
  IBranchShipment,
  IFillinOrder,
  IOrderItem,
  IPlannedOrder,
  IPlannedOrderItem,
  isApprovedOrder,
  isSubmittedOrder,
  isRequestedOrder,
  isRejectedOrder,
} from '../../types/orders'
import ArrowUpIcon from '../../icons/arrow_up.svg'
import ArrowDownIcon from '../../icons/arrow_down.svg'
import { IProduct } from '../../types/products'
import PlanTableCellForm from './PlanTableCellForm'
import PlanTableCellPopup from './PlanTableCellPopup'
import useMonthInfo from '../../common/useMonthInfo'
import usePlanProductAmount from '../../common/usePlanProductAmount'

interface IOwnProps {
  inPlan: boolean
  product: IProduct
  month: IPlannedOrder
  editable: boolean
  forecast?: IBranchForecast
  shipments?: IBranchShipment[]
  replenish?: IPlannedOrderItem & { order: IPlannedOrder }
  fillins?: Array<IOrderItem & { order: IFillinOrder }>
  isSelected?: boolean
}

const PlanTableCell: FunctionComponent<IOwnProps> = ({
  inPlan,
  product,
  month,
  editable,
  forecast,
  shipments,
  replenish,
  fillins,
  isSelected,
}) => {
  const [edit, setEdit] = useState(false)

  const shippedAmount = sumBy('amount', shipments)
  const replenishRequested = getOr(null, 'amountRequested', replenish)
  const forecasted = getOr(0, 'amountSuggested', forecast)

  const { isPastMonth, isCurrentMonth, isFutureMonth } = useMonthInfo(month)
  const submitted = isSubmittedOrder(month)
  const approved = isApprovedOrder(month)
  const requested = isRequestedOrder(month)
  const rejected = isRejectedOrder(month)

  // colors
  // gray for past months
  const isGray = isPastMonth
  // white for future and current month if replenishment order is not submitted
  const isWhite = isFutureMonth || (isCurrentMonth && !submitted)
  // while-grey current month when replenihsment order is submitted
  const isWhiteGray =
    (isCurrentMonth && (submitted || approved || requested || rejected)) ||
    (isFutureMonth && (submitted || approved || requested || rejected))

  // shipped
  // + X shipped - shows total amount_requested from fill in orders submitted in this month.
  // Presented for current month if replenishment order is not submitted
  const shouldShowFilledIn = !!(
    isCurrentMonth &&
    !submitted &&
    shippedAmount > 0
  )

  const shouldShowPlanned =
    (isCurrentMonth || isPastMonth) && submitted && !!replenishRequested

  // number
  const actualNumber = usePlanProductAmount({
    month,
    shipments,
    replenish,
    fillins,
  })

  // arrow indicator
  const hasArrows =
    forecast &&
    replenish &&
    inPlan &&
    (isFutureMonth || (isCurrentMonth && !submitted))
  const hasUpArrow = actualNumber > forecasted
  const hasDownArrow = actualNumber < forecasted

  const cellStyles = cn('tile', {
    'clear-button': editable,
    '-done': isGray,
    '-available': isWhite,
    '-protected': isWhiteGray,
    '-selected': isSelected,
  })

  return (
    <PlanTableCellPopup
      inPlan={inPlan}
      month={month}
      fillins={fillins}
      forecast={forecast}
      editable={editable}
      shipments={shipments}
      replenish={replenish}
      hidden={edit}
      onClick={editable ? () => setEdit(true) : undefined}
      className={cellStyles}
    >
      <div className="tile_statuses">
        {hasArrows && (
          <>
            {hasUpArrow && <ArrowUpIcon className="tile_correlation -up" />}
            {hasDownArrow && (
              <ArrowDownIcon className="tile_correlation -down" />
            )}
          </>
        )}
      </div>

      <div className="tile_count-toggle">{actualNumber}</div>

      {shouldShowFilledIn && (
        <span className="tile_filled-in">{`+${shippedAmount}`} shipped</span>
      )}

      {shouldShowPlanned && (
        <span className="tile_filled-in">{replenishRequested} planned</span>
      )}

      {edit && (
        <PlanTableCellForm
          product={product}
          month={month}
          replenish={replenish}
          forecast={forecast}
          onClose={() => setEdit(false)}
        />
      )}
    </PlanTableCellPopup>
  )
}

export default memo(PlanTableCell)
