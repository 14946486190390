import React, { FC, memo, useState, useCallback } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import useThunkDispatch from '../../common/useThunkDispatch'
import { IProduct } from '../../types/products'
import { IAccount, IBranch } from '../../types/user'
import ModalWrapper from '../../components/Modal/ModalWrapper'
import Button from '../../components/Button'
import Input from '../../components/Input'
import CrossIcon from '../../icons/cross.svg'
import LoadingIcon from '../../icons/loading.svg'
import ProductCard from './ProductCard'
import { requestSalableProductsList } from './inventoryActions'

interface IProductCreateInput {
  code?: string
  name?: string
}

interface IOwnProps {
  account?: IAccount
  branch: IBranch
  opened: boolean
  existsProducts: IProduct[]
  onCreate: (props: IProductCreateInput) => void
  onUse: (product: IProduct) => void
  onClose: () => void
}

const ProductAdd: FC<IOwnProps> = ({
  account,
  branch,
  opened,
  existsProducts,
  onCreate,
  onUse,
  onClose,
}) => {
  const dispatch = useThunkDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<IProduct[]>([])
  const [sku, setSku] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)

  // on search
  useDebounce(
    () => {
      const perform = async () => {
        setLoading(true)
        const list = await dispatch(
          requestSalableProductsList(
            branch.id,
            sku || undefined,
            name || undefined,
            account && account.id
          )
        )
        setProducts(list)
        setLoading(false)
      }

      if (!!sku || !!name) {
        perform()
      }
    },
    300,
    [sku, name]
  )

  // on close
  const handleClose = useCallback(() => {
    setSku(undefined)
    setName(undefined)
    onClose()
  }, [])

  // on new
  const handleCreateNew = useCallback(() => {
    handleClose()
    onCreate({ code: sku, name })
  }, [sku, name])

  // on use
  const handleUse = useCallback((product: IProduct) => {
    handleClose()
    onUse(product)
  }, [])

  if (!opened) return null

  const term = sku || name

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={handleClose} />
      <div className="import_dialog product-modal -sm">
        <div className="product-modal_title">Add product to inventory</div>
        <CrossIcon className="product-modal_close" onClick={handleClose} />
        <div className="product-modal_hint">
          Search for existing product
          {loading && <LoadingIcon className="product-modal_indicator" />}
        </div>
        <div className="product-modal_form">
          <div className="product-modal_filter">
            <Input
              label="Product Id (Sku)"
              placeholder="Start typing to search product"
              value={sku}
              onChange={e => setSku(e.target.value)}
            />

            <Input
              label="Product Name"
              placeholder="Start typing to search product"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
        {term && !!products.length && (
          <div className="product-modal_list">
            <div className="product-modal_list-head">
              <div className="product-modal_list-title">Best Matches</div>
              <a
                href="#"
                className="product-modal_list-link"
                onClick={handleCreateNew}
              >
                Create a new product
              </a>
            </div>
            {products.map(p => {
              const productExist = existsProducts.find(ep => ep.code === p.code)
              return (
                <ProductCard
                  key={p.id}
                  product={p}
                  productExist={!!productExist}
                  tiny
                  onClick={() => handleUse(p)}
                  className="product-modal_list-product"
                />
              )
            })}
          </div>
        )}
        {term && !products.length && (
          <div className="product-modal_empty">
            <div className="product-modal_empty-title">
              We can’t find any product like this
            </div>
            <div className="product-modal_empty-hint">
              You can create a new product
            </div>
            <Button color="success" onClick={handleCreateNew}>
              Create New Product
            </Button>
          </div>
        )}
        {!term && (
          <div className="product-modal_new">
            <div className="product-modal_new-title">
              or create a new product
            </div>
            <Button color="success" onClick={handleCreateNew}>
              Create New
            </Button>
          </div>
        )}
      </div>
    </ModalWrapper>
  )
}

export default memo(ProductAdd)

export const useProductAddModal = ({
  branch,
  account,
  existsProducts,
  onCreate,
  onUse,
}: {
  branch: IBranch
  account?: IAccount
  existsProducts: IProduct[]
  onCreate: (props: IProductCreateInput) => void
  onUse: (product: IProduct) => void
}): [{ open: () => void; close: () => void }, any] => {
  const [opened, setOpened] = useState<boolean>(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const modal = (
    <ProductAdd
      branch={branch}
      account={account}
      existsProducts={existsProducts}
      opened={opened}
      onCreate={onCreate}
      onUse={onUse}
      onClose={close}
    />
  )
  return [{ open, close }, modal]
}
