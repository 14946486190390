import { handleActions } from 'redux-actions'
import { __, always, assign, flow, indexBy, set, update } from 'lodash/fp'
import { IOrder } from '../types/orders'
import { IHash } from '../types/common'
import {
  CLEAR_PRODUCT,
  SET_COMPARE,
  SET_PRODUCT,
  SET_PRODUCT_COMPARE_ORDERS,
  SET_PRODUCT_ORDERS,
  SET_PRODUCT_YEAR,
  SET_REQUESTED_BRANCH,
  SET_REQUESTED_COMPARE_BRANCH,
  UPDATE_PRODUCT_ORDER,
} from './productActions'
import { SET_PLAN_YEAR } from '../plan/planActions'
import { IProduct } from '../types/products'
import { IPlannedBranch } from '../types/user'

const DEFAULT_STATE = {
  product: undefined as IProduct | undefined,
  year: new Date().getFullYear(),
  orders: {} as IHash<IOrder>,
  requestedBranch: undefined as IPlannedBranch | undefined,
  requestedCompareBranch: undefined as IPlannedBranch | undefined,
  compare: false,
  compareOrders: {} as IHash<IOrder>,
}

export default handleActions<typeof DEFAULT_STATE, any>(
  {
    [CLEAR_PRODUCT]: always(DEFAULT_STATE),
    [SET_PLAN_YEAR]: (state, { payload }: SET_PLAN_YEAR) => set('year', payload, state),

    [SET_PRODUCT_YEAR]: (state, { payload }: SET_PRODUCT_YEAR) =>
      flow<any, any, any, any>(
        set('year', payload),
        payload > state.year ? set('compareOrders', state.orders) : set('orders', state.compareOrders),
        payload < state.year ? set('compareOrders', DEFAULT_STATE.compareOrders) : set('orders', DEFAULT_STATE.orders)
      )(state),

    [SET_PRODUCT_ORDERS]: (state, { payload }: SET_PRODUCT_ORDERS) => set('orders', indexBy('id', payload), state),

    [SET_COMPARE]: (state, { payload }: SET_COMPARE) => set('compare', payload, state),
    [SET_PRODUCT_COMPARE_ORDERS]: (state, { payload }: SET_PRODUCT_COMPARE_ORDERS) =>
      set('compareOrders', indexBy('id', payload), state),

    [SET_PRODUCT]: (state, { payload }: SET_PRODUCT) => set('product', payload, state),
    [SET_REQUESTED_BRANCH]: (state, { payload }: SET_REQUESTED_BRANCH) => set('requestedBranch', payload, state),
    [SET_REQUESTED_COMPARE_BRANCH]: (state, { payload }: SET_REQUESTED_COMPARE_BRANCH) =>
      set('requestedCompareBranch', payload, state),
    [UPDATE_PRODUCT_ORDER]: (state, { payload }: UPDATE_PRODUCT_ORDER) =>
      update(`orders[${payload.id}]`, assign(__, payload), state),
  },
  DEFAULT_STATE
)
