import { default as React, FunctionComponent, useEffect, useState } from 'react'
import { IAccountSync } from '../types/user'
import { get } from 'lodash/fp'
import { distanceInWordsToNow } from 'date-fns'
import cn from 'classnames'
import SyncIcon from '../icons/sync_circle.svg'
import InfoCircleIcon from '../icons/info_circle.svg'

interface IOwnProps {
  sync?: IAccountSync
  onSync: () => Promise<void | string>
}

const AccountSync: FunctionComponent<IOwnProps> = ({ sync, onSync }) => {
  const [scheduling, setSheduling] = useState(false)

  const syncErrorMessage = (errors: { [key: string]: string }): string => {
    const errorLines: string[] = []
    const successLines: string[] = []

    const keys = Object.keys(errors)

    keys.forEach(key => {
      if (key === 'base') {
        errorLines.push(errors[key])
      } else if (!errors[key]) {
        successLines.push(`${key}: Success`)
      } else {
        errorLines.push(`${key}: ${errors[key]}`)
      }
    })

    return errorLines.concat(successLines).join('\n')
  }

  let message
  switch (get('status', sync)) {
    case 'scheduled':
      message = (
        <div className="sync_message">{`Scheduled ${distanceInWordsToNow(
          sync!.createdAt
        )} ago`}</div>
      )
      break
    case 'running':
      message = (
        <div className="sync_message">{`Started ${distanceInWordsToNow(
          sync!.startedAt
        )} ago`}</div>
      )
      break
    case 'finished':
      message = (
        <div className="sync_message -success">{`Synced ${distanceInWordsToNow(
          sync!.finishedAt
        )} ago`}</div>
      )
      break
    case 'failed':
      const errorMsg = syncErrorMessage(sync!.syncErrors || {})
      message = (
        <div className="sync_message -failed">
          <button
            type="button"
            className={cn('clear-button', 'error_button')}
            onClick={async e => {
              e.preventDefault()
              alert(errorMsg)
            }}
          >
            <InfoCircleIcon />
            &nbsp;
            {`Sync failed ${distanceInWordsToNow(
              sync!.finishedAt || sync!.startedAt
            )} ago`}
          </button>
        </div>
      )
      break
    default:
      message = ''
  }

  const syncing =
    sync && (sync.status === 'running' || sync.status === 'scheduled')

  useEffect(() => {
    if (sync && sync.status) setSheduling(false)
  }, [sync])

  return (
    <div className="sync">
      <button
        type="button"
        className={cn('sync_button clear-button', { '-syncing': syncing })}
        disabled={scheduling || syncing}
        onClick={async e => {
          e.preventDefault()
          setSheduling(true)
          const error = await onSync()
          if (error) {
            alert(error)
            setSheduling(false)
          }
        }}
      >
        <SyncIcon className="sync_icon" />
        {syncing ? 'Syncing...' : 'Sync'}
      </button>
      {message}
    </div>
  )
}

export default AccountSync
