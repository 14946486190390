import React, { memo, useState, useEffect } from 'react'
import cn from 'classnames'

import { IUser } from '../../types/user'
import UserCheckouts from './UserCheckouts'
import UserForm, { IOwnProps } from './UserForm'
import GroupsForm from './GroupsForm'
import DateUpdated from '../../components/DateUpdated'

type UserInfoTab = 'info' | 'history' | 'groups'

const filters: Array<{ name: string; type: UserInfoTab }> = [
  { name: 'User Info', type: 'info' },
  { name: 'History', type: 'history' },
  { name: 'Divisions & Teams', type: 'groups' },
]

const UserInfo = ({
  user,
  account,
  branch,
  onSave,
  loading,
  ...rest
}: IOwnProps) => {
  const [tab, setTab] = useState<UserInfoTab>('info')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setTab('info')
  }, [user.id])

  const isNew = !user.id

  const onFinish = async (user: IUser | { password?: string }) => {
    setSaving(true)
    await onSave(user)
    setSaving(false)
  }

  return (
    <div className="user-info">
      <div className="user-info_info">
        <div className="user-info_title">
          {isNew ? (
            <>
              Add new technician to&nbsp;
              <span className="user-info_branch">{branch.name}</span>
            </>
          ) : (
            <>
              {filters.map(f => (
                <div
                  key={f.type}
                  className={cn('user-info_toggler', {
                    '-active': f.type === tab,
                  })}
                  onClick={() => setTab(f.type)}
                >
                  {f.name}
                </div>
              ))}
              <DateUpdated
                loading={loading}
                updatedAt={user.updatedAt}
                saving={saving}
              />
            </>
          )}
        </div>

        {tab === 'history' && (
          <UserCheckouts
            user={user}
            branchId={branch.id}
            accountId={account && account.id}
          />
        )}
        {tab === 'groups' && (
          <GroupsForm
            user={user}
            onSave={onFinish}
            loading={loading}
            {...rest}
          />
        )}
        {tab === 'info' && (
          <UserForm
            user={user}
            branch={branch}
            onSave={onFinish}
            loading={loading}
            {...rest}
          />
        )}
      </div>
    </div>
  )
}

export default memo(UserInfo)
