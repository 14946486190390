import { addDays } from 'date-fns'
import { IPlannedOrder } from '../types/orders'
import useMonthInfo from './useMonthInfo'

export default (order: IPlannedOrder) => {
  const { isPastMonth, isCurrentMonth, isFutureMonth } = useMonthInfo(order)
  const approveBy = order.approveDueDateAt
  const plannedShippment = order.shipmentDateAt
  const urgentRequestAt = order.submittedAt || ''

  return {
    // past
    past: isPastMonth
      ? {
          isSubmitted:
            ['submitted'].includes(order.status) && order.autoSubmitted,
          isSkipped: ['skipped', 'delayed'].includes(order.status),
          isUrgent:
            ['submitted'].includes(order.status) && !order.autoSubmitted,
          urgentRequestAt,
        }
      : null,

    // current
    current: isCurrentMonth
      ? {
          isNotApproved: ['open', 'delayed'].includes(order.status),
          isRequested: ['requested'].includes(order.status),
          isRejected: ['rejected'].includes(order.status),
          isApproved: ['approved'].includes(order.status),
          isSubmitted:
            ['submitted'].includes(order.status) && order.autoSubmitted,
          isUrgent:
            ['submitted'].includes(order.status) && !order.autoSubmitted,
          rejectedReason: order.rejectReason,
          approveBy,
          plannedShippment,
          urgentRequestAt,
        }
      : null,

    // future
    future: isFutureMonth
      ? {
          isUpcoming:
            !['approved'].includes(order.status) &&
            !['rejected'].includes(order.status),
          isApproved: ['approved'].includes(order.status),
          isRejected: ['rejected'].includes(order.status),
          rejectedReason: order.rejectReason,
          approveBy,
          plannedShippment,
        }
      : null,
  }
}
