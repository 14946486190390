const CURR_YEAR: number = new Date().getFullYear()
const CURR_MONTH: number = new Date().getMonth() + 1

interface IMonth {
  year: number
  month: number
}

interface IMonthInfo {
  isPastMonth: boolean
  isCurrentMonth: boolean
  isFutureMonth: boolean
}

export default (month: IMonth): IMonthInfo => {
  const isPastMonth =
    (month.year === CURR_YEAR && month.month < CURR_MONTH) ||
    month.year < CURR_YEAR

  const isCurrentMonth = month.year === CURR_YEAR && month.month === CURR_MONTH

  const isFutureMonth =
    (month.year === CURR_YEAR && month.month > CURR_MONTH) ||
    month.year > CURR_YEAR

  return {
    isPastMonth,
    isCurrentMonth,
    isFutureMonth,
  }
}
