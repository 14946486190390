import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { sortBy, reject } from 'lodash/fp'
import qs from 'qs'
import useDebounce from 'react-use/lib/useDebounce'
import { RouteComponentProps } from 'react-router'
import { format } from 'date-fns'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import useThunkDispatch from '../../../common/useThunkDispatch'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import Splash from '../../../components/Splash'
import Search from '../../../components/Search'
import {
  fetchFlowAccess,
  IDetailsOrder,
  IOrderItem,
  isSubmittedOrder,
} from '../../../types/orders'
import name from '../../../common/name'
import { orderTotal } from '../../../common/orders'
import withAccountAndBranch from '../../../common/withAccountAndBranch'
import { searchProducts } from '../../../accounts/accountsActions'
import { IAccount, IBranch, IUser } from '../../../types/user'
import { IProduct } from '../../../types/products'
import OrderRow from '../OrderRow'
import OrderProductRow from '../OrderProductRow'
import {
  requestDetailsOrder,
  requestOrderApprove,
  requestOrderReject,
  requestUpdateOrderItem,
  requestCreateOrderItem,
  requestOrderUpdate,
  requestOrderSubmit,
  requestOrderReopen,
  requestOrderRequest,
} from '../ordersActions'
import OrderHeader from './OrderHeader'
import { useOrderApproveModal } from './OrderApprove'
import { useOrderRejectModal } from './OrderReject'
import { useOrderPONumberModal } from './OrderPONumber'
import { useOrderReopenModal } from './OrderReopen'
import { useOrderRequestModal } from './OrderRequest'
import ShippingInfo from '../SubmittedOrdersRow/shippingInfo'
import { find } from 'lodash'

interface IOrderRouteParams
  extends RouteComponentProps<{
    orderId: string
    accountId?: string
    branchId?: string
    returnUrl?: string
  }> {
  user: IUser
  account?: IAccount
  branch?: IBranch
}

const Order: FC<IOrderRouteParams> = ({
  user,
  account: currentAccount,
  branch,
  match,
  location,
  history,
}) => {
  const dispatch = useThunkDispatch()
  const id = Number(match.params.orderId)
  const [term, setTerm] = useState<string>('')
  const [order, setOrder] = useState<IDetailsOrder | undefined>(undefined)
  const [foundProducts, setFoundProducts] = useState<IProduct[] | null>(null)
  const [searching, setSearching] = useState<boolean>(false)
  const [rejecting, setRejecting] = useState<boolean>(false)
  const [approving, setApproving] = useState<boolean>(false)
  const [reopening, setReopening] = useState<boolean>(false)
  const [requesting, setRequesting] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const listRef = useRef<FixedSizeList>(null)
  const account = currentAccount || user.account
  const flowAccess =
    order && account ? fetchFlowAccess(account, user, order) : {}
  const isEditable = flowAccess.canEdit
  const nothingFound = foundProducts && !foundProducts.length && !searching
  const orderItems =
    (order && reject({ amountRequested: 0 }, order.orderItems)) || []

  const loading =
    searching || rejecting || approving || reopening || requesting || submitting

  // load
  useEffect(() => {
    const perform = async () => {
      setOrder(await dispatch(requestDetailsOrder(id)))
    }

    perform()
  }, [id])

  // search
  useDebounce(
    async () => {
      if (!term || !order || !order.branch) {
        if (listRef.current) listRef.current.scrollTo(0)
        setFoundProducts(null)
        return
      }

      setSearching(true)

      const results = await dispatch(
        searchProducts(
          account ? account.id : user.account!.id,
          order.branch.id,
          term,
          'orderable'
          // order.kind === 'fill_in'
        )
      )
      setFoundProducts(sortBy('name', results))

      if (listRef.current) listRef.current.scrollTo(0)
      setSearching(false)
    },
    300,
    [term, id]
  )

  // on back
  const handleBack = useCallback(() => {
    const { returnUrl } = qs.parse(location.search.replace('?', ''))
    if (returnUrl) {
      history.push(returnUrl)
      return
    }

    const link = match.params.accountId
      ? `/clients/${match.params.accountId}/branches/${match.params.branchId}/orders`
      : '/orders'
    history.push(link)
  }, [account, branch])

  // on reject
  const handleReject = useCallback(
    (reason: string) => {
      const perform = async () => {
        setRejecting(true)
        const { result, errors } = await dispatch(
          requestOrderReject(id, reason)
        )
        setRejecting(false)

        if (result) {
          setOrder({ ...order, ...result })
          handleBack()
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [id, order, handleBack]
  )

  // on update
  const handleUpdate = useCallback(
    (attrs: Partial<IDetailsOrder>) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(requestOrderUpdate(id, attrs))
        setSubmitting(false)

        if (result) {
          setOrder({ ...order, ...result })
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [id, order]
  )

  // on order item create
  const handleOrderItemCreate = useCallback(
    (product: IProduct) => {
      const perform = async () => {
        const existingOrderItem = order!.orderItems.find(
          oi => oi.product.id === product.id
        )

        if (existingOrderItem) {
          handleOrderItemUpdate({ ...existingOrderItem, amountRequested: 1 })
          return
        }

        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestCreateOrderItem(order!, { amountRequested: 1, product })
        )
        setSubmitting(false)

        if (result) {
          setOrder(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [order]
  )

  // on order item update
  const handleOrderItemUpdate = useCallback(
    (item: IOrderItem) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestUpdateOrderItem(order!, item)
        )
        setSubmitting(false)

        if (result) {
          setOrder(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [order]
  )

  // on order item remove
  const handleOrderItemDelete = useCallback(
    (item: IOrderItem) => {
      const perform = async () => {
        setSubmitting(true)
        const { result, errors } = await dispatch(
          requestUpdateOrderItem(order!, { ...item, amountRequested: 0 })
        )
        setSubmitting(false)

        if (result) {
          setOrder(result)
        } else if (errors) {
          alert(errors)
        }
      }

      perform()
    },
    [order]
  )

  // on order item remove
  const handleApprove = useCallback(() => {
    const perform = async () => {
      setApproving(true)
      const { result, errors } = await dispatch(requestOrderApprove(id))
      setApproving(false)

      if (result) {
        setOrder({ ...order, ...result })
        handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [order, id, handleBack])

  // on order item remove
  const handleReopen = useCallback(() => {
    const perform = async () => {
      setReopening(true)
      const { result, errors } = await dispatch(requestOrderReopen(id))
      setReopening(false)

      if (result) {
        setOrder({ ...order, ...result })
        // handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [order, id, handleBack])

  // on order item remove
  const handleRequest = useCallback(() => {
    const perform = async () => {
      setRequesting(true)
      const { result, errors } = await dispatch(requestOrderRequest(id))
      setRequesting(false)

      if (result) {
        setOrder({ ...order, ...result })
        handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [order, id, handleBack])

  // on order submit
  const handleSubmit = useCallback(() => {
    const perform = async () => {
      setSubmitting(true)
      const { result, errors } = await dispatch(requestOrderSubmit(id))
      setSubmitting(false)

      if (result) {
        setOrder({ ...order, ...result })
        handleBack()
      } else if (errors) {
        alert(errors)
      }
    }

    perform()
  }, [order, id, handleBack])

  // approve
  const [
    { open: openApproveModal, close: closeApproveModal },
    approveModal,
  ] = useOrderApproveModal({
    onApprove: () => {
      handleApprove()
      closeApproveModal()
    },
  })

  // reopen
  const [
    { open: openReopenModal, close: closeReopenModal },
    reopenModal,
  ] = useOrderReopenModal({
    onReopen: () => {
      handleReopen()
      closeReopenModal()
    },
  })

  // request
  const [
    { open: openRequestModal, close: closeRequestModal },
    requestModal,
  ] = useOrderRequestModal({
    onRequest: () => {
      handleRequest()
      closeRequestModal()
    },
  })

  // reject
  const [
    { open: openRejectModal, close: closeRejectModal },
    rejectModal,
  ] = useOrderRejectModal({
    onReject: (reason: string) => {
      handleReject(reason)
      closeRejectModal()
    },
  })

  // po number
  const [
    { open: openPONumberModal, close: closePONumberModal },
    poNumberModal,
  ] = useOrderPONumberModal({
    poNumber: order && order.navReferenceId,
    onSave: (poNumber: string) => {
      handleUpdate({ navReferenceId: poNumber })
      closePONumberModal()
    },
  })

  if (!order) return <Splash isLoading />

  const gmFlow = account && account.orderApproveFlow
  const multiCatalogEnabled = (account?.erpCatalogs || []).length > 1
  const showLowStock =
    isEditable &&
    !!orderItems.length &&
    !!find(
      orderItems,
      oi =>
        oi.product.stockInventory !== undefined &&
        oi.amountRequested &&
        oi.amountRequested > oi.product.stockInventory
    )

  return (
    <Modal closeable={false} containerClassName="">
      <div className="order-modal">
        <OrderHeader
          order={order}
          onNumberEdit={openPONumberModal}
          onClose={handleBack}
          gmFlow={gmFlow}
        />
        <div className="form">
          <div className="order-modal_info">
            {isSubmittedOrder(order) ? (
              <>
                <div>
                  <div className="order-modal_label">Branch</div>
                  <div className="order-modal_value -full">
                    {order.branch.name}
                  </div>
                </div>
                <div>
                  <div className="order-modal_label">Submitted At</div>
                  <div className="order-modal_value">
                    {order.submittedAt
                      ? format(order.submittedAt, 'MM/DD/YYYY')
                      : '-'}
                  </div>
                </div>
                {gmFlow ? (
                  <>
                    {gmFlow && (
                      <div>
                        <div className="order-modal_label">Requested by</div>
                        <div className="order-modal_value">
                          {name(order.requestedBy)}
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="order-modal_label">Approved by</div>
                      <div className="order-modal_value">
                        {name(order.approvedBy)}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="order-modal_label">Submitted by</div>
                      <div className="order-modal_value">
                        {name(
                          order.kind === 'fill_in'
                            ? order.submittedBy
                            : order.approvedBy
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div>
                  <div className="order-modal_label">Branch</div>
                  <div className="order-modal_value -full">
                    {order.branch.name}
                  </div>
                </div>
                <div>
                  <div className="order-modal_label">Est. Shipment</div>
                  <div className="order-modal_value">
                    {order.shipmentDateAt
                      ? format(order.shipmentDateAt, 'MM/DD/YYYY')
                      : '-'}
                  </div>
                </div>
                <div>
                  <div className="order-modal_label">Updated at</div>
                  <div className="order-modal_value">
                    {order.updatedAt
                      ? format(order.updatedAt, 'MM/DD/YYYY')
                      : 'n/a'}
                  </div>
                </div>
                <div>
                  <div className="order-modal_label">Updated by</div>
                  <div className="order-modal_value">
                    {name(order.updatedBy)}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="order-modal_search">
            <div className="order-modal_field">
              <Search
                searching={searching}
                placeholder="Search by name or ID"
                value={term}
                onChange={e =>
                  setTerm(e.target.value ? e.target.value.trim() : '')
                }
              />
            </div>
            <div className="order-modal_total">
              Total
              <br />
              <strong>{orderTotal(order)}</strong>
            </div>
          </div>
          {showLowStock && (
            <div className="order-modal_alert">
              Some items could be delayed due to a lack of availability -
              Forshaw CSR will be in touch to discuss
            </div>
          )}
          <div className="order-modal_list">
            {nothingFound && (
              <div className="table">
                <div className="table_head-tr">
                  <div className="table_head-tr table_td table_title">
                    No products found
                  </div>
                </div>
              </div>
            )}
            <AutoSizer
              className="table"
              style={{ height: 'calc(100vh - 375px)' }}
            >
              {size => (
                <FixedSizeList
                  ref={listRef}
                  className="wonder-scroll"
                  {...size}
                  itemData={{
                    editable: isEditable,
                    products: foundProducts,
                    onAdd: handleOrderItemCreate,
                    onUpdate: handleOrderItemUpdate,
                    onDelete: handleOrderItemDelete,
                    orderItems,
                    multiCatalogEnabled,
                    loading,
                  }}
                  itemCount={
                    foundProducts ? foundProducts.length : orderItems.length
                  }
                  itemSize={100 + 2}
                  itemKey={index =>
                    foundProducts
                      ? foundProducts[index].id
                      : orderItems[index].id
                  }
                >
                  {foundProducts ? OrderProductRow : OrderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="form_controls">
          <div className="order-modal_footer form_controls-container">
            {order.status === 'submitted' ? (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <ShippingInfo order={order} />
              </div>
            ) : (
              <Button
                onClick={handleBack}
                disabled={rejecting}
                color="muted"
                className="form_control -size -left"
              >
                Cancel
              </Button>
            )}

            {flowAccess.canReject && (
              <Button
                type="submit"
                color="danger"
                disabled={rejecting}
                loading={rejecting}
                className="form_control -size"
                onClick={openRejectModal}
              >
                Reject
              </Button>
            )}
            {flowAccess.canApprove && (
              <Button
                type="submit"
                color="success"
                disabled={approving}
                className="form_control"
                onClick={openApproveModal}
              >
                {flowAccess.approveText || 'Approve'}
              </Button>
            )}
            {flowAccess.canRequest && (
              <Button
                type="submit"
                color="success"
                disabled={requesting}
                className="form_control"
                onClick={openRequestModal}
              >
                Request approval
              </Button>
            )}
            {flowAccess.canReopen && (
              <Button
                type="submit"
                color="warning"
                disabled={reopening}
                className="form_control -size"
                onClick={openReopenModal}
              >
                Reopen
              </Button>
            )}
            {flowAccess.canUrgent && (
              <Button
                type="submit"
                color="warning"
                disabled={submitting}
                className="form_control"
                onClick={handleSubmit}
              >
                Request Urgently
              </Button>
            )}
            {flowAccess.canSubmit && (
              <Button
                type="submit"
                color="success"
                disabled={submitting}
                className="form_control"
                onClick={handleSubmit}
              >
                Place order
              </Button>
            )}
          </div>
        </div>
      </div>
      {approveModal}
      {rejectModal}
      {poNumberModal}
      {reopenModal}
      {requestModal}
    </Modal>
  )
}

export default withAccountAndBranch(Order)
